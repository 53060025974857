import styled from "styled-components";

export const DepositHelpContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 117px;
    @media screen and (max-width: 820px){
        margin-top: 55px;
    }
`

export const DepositHelpWrapper = styled.div`
    max-width: 1200px;
    background-color: #fff;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 20px;
    min-height: calc(100vh - 117px);
    margin-bottom: 100px;
`

export const DepositHelpTitle = styled.h1`
    font-size: 2.5rem;
    color: #b3a25d;
    margin: 0;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 40px;
`

export const DepsoitHelpContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 15px;
    span {
        text-align: start;
    }

    h2 {
        color: #b3a25d;
        margin: 0;
        margin-top: 30px;
    }

    img {
        margin: 20px auto;
        max-width: 700px;
        width: 100%;
    }

    b {
        cursor: pointer;
    }
    b:hover {
        text-decoration: underline;
    }
`