import React, { useEffect } from "react";
import Tabs from "../../components/Tabs";
import { PromotionsListContent } from "./styled";
import {
	LeaderBoardTitle,
	LeaderBoardContainer,
	LeaderBoardWrapper,
	LeaderBoardContent,
	LeaderBoardTermsTitle,
} from "../LeaderBoard/styled";
import FirstDepositImg from "../../assets/image/promotion/Monthly_Bonus.jpg";

const DepositMonth = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Tabs tag={2} />
			<LeaderBoardContainer>
				<LeaderBoardWrapper>
					<img src={FirstDepositImg} alt="" draggable={false} width="100%" />
					<LeaderBoardTitle>
						Wager Bonus
					</LeaderBoardTitle>
					<LeaderBoardContent>
						<span>
							Whenever you deposit an amount, you will get 100% of your deposit added to your balance.
						</span>
						<span>
							The credits are added automatically at the time of the deposit. For example if you deposit 10.00 you will get 20.00 added to your balance.
						</span>
						<span>
							During wager bonus you cannot top up before your balance becomes 7 times the initial amount. For example if you deposit 10.00 you can cash out after 70. You can deposit again if balance goes bellow 1.00.
						</span>
						<span> You can cancel wager bonus if you didn't start playing.</span>
						{/* <PromotionsListContent>
							<li>
								Log in and enter Promo Code <strong>XM5YTB3</strong>
							</li>
							<li>Make your first deposit of the month</li>
							<li>Receive your monthly bonus INSTANTLY!</li>
						</PromotionsListContent>
						<LeaderBoardTermsTitle>Terms and Conditions</LeaderBoardTermsTitle>
						<span>
							The following words and terms, when used with this General Terms,
							shall have the following meanings, unless the context clearly
							indicates otherwise: - A Bonus can also refer to Free Spins, Extra
							Spins or Golden Chips.
						</span>
						<span>
							1. The Bonuses will be given only once per household and/or per
							account, as per our terms of use. If a second account is opened,
							it will be closed and all bonuses and winnings may be canceled.
						</span>
						<span>
							2. Players with one or more pending withdrawals currently in their
							account are not eligible to receive any bonuses, promotions, or
							related prizes. Abuse of this policy, as defined by the casino,
							may result in cancellation of the player's winnings.
						</span>
						<span>
							3. The casino reserves the right to review transaction records and
							logs at anytime, for any reason whatsoever. If, upon such review,
							it appears that player/s are participating in strategies that the
							casino in its sole discretion deems to be abusive, the casino
							reserves the right to revoke the entitlement of such player to the
							promotion.
						</span>
						<span>
							4. In the event of a dispute, all decisions made by the casino
							will be final.
						</span>
						<span>
							5. The casino reserves the right to change the terms and
							conditions of this offer at any time and it is your responsibility
							to periodically check here for changes and updates.
						</span>
						<span>
							6. All bonus credits are for wagering purposes only. (No
							withdrawal will be honored for an amount less than the amount of
							the bonus. Upon your first withdrawal (regardless of the number of
							deposits), any amount up to the equivalent value of the bonus will
							be deducted from your play balance or winnings prior to
							settlement. The first credits wagered will be deducted from your
							deposit(s) rather than from the bonus).
						</span>
						<span>
							7. Not all bets will count towards the Wagering Requirements.
							Except if expressly stated otherwise in the relevant additional
							terms and conditions; all variations of Baccarat, Roulette* (see
							exceptions below), Sic Bo, Darts, Heads or Tails, Video Poker
							games, Blackjack Switch, Blackjack Surrender, Pontoon, Craps, Wild
							Viking and Spin a Win will not contribute at all to Wagering
							Requirements. We reserve the right to deduct any amount in excess
							of the player's starting deposit from a player's withdrawal
							request if the bonus is played through on the above-mentioned
							games. Bets placed on all Blackjack games (except Blackjack Switch
							and Blackjack Surrender), Pai Gow Poker, Casino Hold'em, Tequila
							Poker, Live Casino Hold'em and Stravaganza are worth one-third of
							their starting value towards playing prerequisites. Phrased
							differently, the total of the bets made on this game is split by
							three when computing playing prerequisites. Bets placed on Red Dog
							are worth twenty percent of their starting value towards playing
							prerequisites.
						</span>
						<span>
							*The following roulette games are valid towards Wagering
							Requirements under the below conditions:
						</span>
						<span>
							Live French Roulette, Premium European Roulette, Roulette Live
						</span>
						<span>
							- High coverage bets will contribute 0% towards your Wagering
							Requirements. High coverage roulette bets are bet spread
							combinations covering 60% or more of the table.
						</span>
						<span>
							- Medium coverage bets will contribute 20% towards your Wagering
							Requirements. Medium coverage roulette bets are bet spread
							combinations covering 10% or more of the table but less than 60%.
						</span>
						<span>
							- Low coverage bets will contribute 50% towards your Wagering
							Requirements. Low coverage roulette bets are bet spread
							combinations covering less than 10% of the table.
						</span>
						<span>
							Bets placed on any games that misuse the betting system will not
							be calculated towards the playing prerequisites. We reserve the
							right to withhold any amount in excess of the player's original
							deposit from a player's withdrawal if the player placed single
							bets equal to or greater to 10 <b>ZAR</b> before the wagering requirements
							for the bonus have been met.
						</span>
						<span>
							8. Players who select Great British Pounds (GBP sterling) as their
							currency will NOT be eligible to receive any bonuses.
						</span>
						<span>
							9. We reserve the right to enforce the following: players from
							Belarus, Ukraine, Moldova, Kazakhstan, Lithuania, Peru, Ecuador,
							Argentina, Hungary, Thailand, Malaysia, Slovak Republic, Colombia,
							Dominican Republic, Uruguay, and Costa Rica must wager their
							deposit and bonus thirty (30) times, or thirty (30) times for the
							High Roller Bonus before reaching the minimum wagering
							requirements for withdrawal. Players from Bolivia are not eligible
							to receive any bonuses. Players from Malaysia are not eligible to
							receive free bonuses.
						</span>
						<span>
							10.The double-up feature on any game does not count towards
							wagering requirements.
						</span>
						<span>
							11. After you have made your first deposit at Viva Slots, each
							time you place a real money wager, you'll earn Complimentary
							Points or "Comps". As your Comps accumulate, you can redeem them
							for instant cash in your real money player account. If you have
							not placed a real money wager for a continuous period of ninety
							(90) days, we will be entitled to remove the entire Comps balance
							from your account. Bets made using bonus funds do not earn comp
							points.
						</span>
						<span>
							All bonuses will be removed when your Account has been deemed
							inactive.
						</span>
						<span>
							12. Free Spins Wins: Winnings resulting from Free Spins are
							considered as Free Bonuses and as such bound to the same wagering
							requirements and any other applicable terms. These winnings are
							credited only after all free spins were completed.
						</span>
						<span>
							13. Free Bonuses, Free Golden Chips and Free Spin wins: Players
							must deposit at least 20 <b>ZAR</b> in order to withdraw any winnings.
						</span>
						<span>
							15. In the interest of fair gaming, in order to cash out any
							amount of money you must wager at least fifty (50) times your Free
							Bonus, Free Spin wins or Free Golden chips no deposit required. If
							you withdraw before having reached the minimum wagering
							requirements, your bonus and winnings will be void.
						</span>
						<span>
							16. Free Bonuses, Free Golden Chips and Free Spin wins: High
							coverage roulette bets are not valid for wagering requirements
							(except where otherwise stated). We reserve the right to withhold
							winnings from members, who wager these bonuses on roulette games.
						</span>
						<span>
							17. In order to receive a free bonus, Free Golden Chips or free
							spins upon registration, we reserve the right to request phone
							number verification.
						</span>
						<span>
							18. Free Bonuses, Free Golden Chips and Free Spins Wins: will
							expire after 1 hour of issuing the bonus (Casino time). Unless
							wagering is complete by that time, all winnings + bonus will
							expire.
						</span>
						<span>
							19. Deposits made through Neteller and/or Moneybookers payment
							services will not be eligible to receive bonuses.
						</span> */}
					</LeaderBoardContent>
				</LeaderBoardWrapper>
			</LeaderBoardContainer>
		</React.Fragment>
	);
};

export default DepositMonth;
