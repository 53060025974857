import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { BarLoader } from "react-spinners";
import { routes } from '../../constants/routes';
import { GamePlayContainer, GamePlayTitleWrapper, GamePlayIframeWrapper } from './styled';

const override = {
    display: "block",
    margin: "0 auto",
    position: 'fixed',
    top: '50vh'
};


const GamePlay = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isloaded, setIsloaded] = useState(true)

    // const { game } = useTypedSelector(state => state.game);
    const param = location.state;
    const onClose = () => {
        navigate(routes.HOME);
    }

    const onhideProgress = () => {
        setIsloaded(false)
    }

    console.log(param);

    return (
        <React.Fragment>
            <GamePlayContainer>
                <GamePlayTitleWrapper>
                    <span>
                        {param['displayName']}
                    </span>
                    <CloseIcon onClick={onClose} size={'large'} />
                </GamePlayTitleWrapper>
                {
                    <GamePlayIframeWrapper
                        allowFullScreen
                        id={'gameIFrame'}
                        src={param['gameURL']}
                        width="100%"
                        height="100%"
                        scrolling="no"
                        frameBorder="no"
                        align="middle"
                        onLoad={onhideProgress}
                    />
                }
                <BarLoader
                    color={'#00FF00'}
                    loading={isloaded}
                    cssOverride={override}
                    width={300}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </GamePlayContainer>
        </React.Fragment>
    )
}

export default GamePlay;