import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';


export const routes = {
  start: '/games/launch',
  list: '/games/list',
};


export const launchGame = createAsyncThunk(
  'games/launch',
  async (gameName, { rejectWithValue }) => {
    try {
      const response = await api.get(`${routes.start}`, { params: { gameName }});
      const gameURL = response.data["url"];
      // let exit_close_url = process.env.REDIRECT_PROD_URL;
      let exit_close_url = process.env.REACT_APP_REDIRECT_DEV_URL;
      let gameNew_url = '';     
      if(gameURL != undefined){
        const gameurl_array  = gameURL.split('&');
        if(gameURL.includes("closeUrl")){
          gameurl_array.map((url_arr_str)=>{
            if(url_arr_str.includes("closeUrl")){
              gameNew_url = gameNew_url + '&closeUrl=' +  exit_close_url;
            }else{
              gameNew_url = gameNew_url =='' ? url_arr_str : (gameNew_url + '&'  + url_arr_str);
            }
          })
        }else if(gameURL.includes("exit_url")){
          gameurl_array.map((url_arr_str)=>{
            if(url_arr_str.includes("exit_url")){
              gameNew_url = gameNew_url + '&exit_url=' +  exit_close_url;
            }else{
              gameNew_url = gameNew_url =='' ? url_arr_str : (gameNew_url + '&'  + url_arr_str);
            }
          })
        }else if(gameURL.includes("returnUrl")){
          gameurl_array.map((url_arr_str)=>{
            if(url_arr_str.includes("returnUrl")){
              gameNew_url = gameNew_url + '&returnUrl=' +  exit_close_url;
            }else{
              gameNew_url = gameNew_url =='' ? url_arr_str : (gameNew_url + '&'  + url_arr_str);
            }
          })
        }
        else{
          gameNew_url = gameURL;
        }
      }
      const responsedata = response.data;
      responsedata['url'] = gameNew_url;
      return responsedata
    } catch (e) {
      return rejectWithValue({
        message: e.message,
      });
    }
  },
);
export const getGames = createAsyncThunk(
  'games/getGames',
  async (_,{ rejectWithValue }) => {
    try {
      const response = await api.get(`${routes.list}`);
      // const game_list = response.data;
      // let sortGames_arr = game_list.slice().sort((a: any, b: any) => parseInt(b["sortOrder"]) - parseInt(a["sortOrder"]));
      // const games = sortGames_arr.slice().reverse();
      // console.log("games == ", response);
      return response.data
    } catch (e) {
      return rejectWithValue({
        message: e.message,
      });
    }
  },
);
