import { createAsyncThunk } from '@reduxjs/toolkit';
import { encryptedStorage } from '../utils/encryptStorage';
import api from '../api';


export const routes = {
  login: '/auth',
  register: '/users/create',
  reset: '/users/reset',
  refresh: '/auth/refresh',
  forgot: '/external/resetUser',
};

export const registerUser = createAsyncThunk('users/createUser', async (registerData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.register}`, registerData);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.data.message,
    });
  }
});

export const forgotPassword = createAsyncThunk('users/resetUser', async (forgotdata, { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.reset}`, forgotdata);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const loginUser = createAsyncThunk('auth/loginUser', async (loginData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.login}`, loginData);
    await encryptedStorage.setItem('token', response.data.access_token);
    await encryptedStorage.setItem('refresh', response.data.refresh_token);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const resetPassword = createAsyncThunk('users/reset-password', async (resetdata, { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.forgot}/${resetdata.token}`, { password: resetdata.password });
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const getRefreshToken = createAsyncThunk('auth/refresh', async (refresh, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.refresh}`, refresh);
    // await localStorage.setItem('token', response.data.access_token);
    // await localStorage.setItem('refresh', response.data.refresh_token);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});
