import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// hooks
import ScrollToTop from './hooks/scrolltop';

// layout
import Header from './layout/Header';
import Footer from './layout/Footer';
import MobileSideBar from "./components/MobileSideBar";

// pages
import Landing from './pages/Landing';
import Promotions from './pages/Promotions';
import LeaderBoard from './pages/LeaderBoard';
import AboutUs from './pages/Footers/aboutus';
import PrivacyPolicy from './pages/Footers/privacy';
import ContactUs from './pages/Footers/contactus';
import Gambling from './pages/Footers/gambling';
import TermsConditions from './pages/Footers/terms';
import DepositFirst from './pages/Promotions/depositfirst';
import DepositSecond from './pages/Promotions/depositsecond';
import DepositMonth from './pages/Promotions/depositmonth';
import GamePlay from './pages/GamePlay';
import MobileFooter from "./components/MobileFooter";
import ScrollTop from "./components/ScrollTop";
import Account from './pages/Account';
import Help from './pages/Help';
import CreateAccountHelp from './pages/Help/create-account';
import DepositHelp from './pages/Help/deposit';
import { NotFound } from './pages/NotFound';
import { routes } from './constants/routes';

function Routings() {
  const [isSide, setSide] = useState(false);

  return (
    <Router>
      <ScrollToTop>
        <Header setSide={() => setSide(true)} />
        <Routes>
          <Route path={routes.HOME} element={<Landing />}></Route>
          <Route path={routes.LOGIN} element={<Landing />}></Route>
          <Route path={routes.REGISTER} element={<Landing />}></Route>
          <Route path={routes.FORGOT} element={<Landing />}></Route>
          <Route path={routes.RESET} element={<Landing />}></Route>
          <Route path={routes.PROMOTIONS} element={<Promotions />}></Route>
          <Route path={routes.LEADERBOARD} element={<LeaderBoard />}></Route>
          <Route path={routes.ABOUTUS} element={<AboutUs />}></Route>
          <Route path={routes.PRIVACY} element={<PrivacyPolicy />}></Route>
          <Route path={routes.TERMS} element={<TermsConditions />}></Route>
          <Route path={routes.CONTACTUS} element={<ContactUs />}></Route>
          <Route path={routes.RESPONSIBLE} element={<Gambling />}></Route>
          <Route path={routes.PROMOTIONSFirst} element={<DepositFirst />}></Route>
          <Route path={routes.PROMOTIONSSecond} element={<DepositSecond />}></Route>
          <Route path={routes.PROMOTIONSMonth} element={<DepositMonth />}></Route>
          <Route path={`${routes.HOME}/gameId/:id`} element={<GamePlay />} />
          <Route path={routes.ACCOUNT} element={<Account />} />
          <Route path={routes.HELP} element={<Help />} />
          <Route path={`${routes.HELP}/how-to-create-an-account`} element={<CreateAccountHelp />} />
          <Route path={`${routes.HELP}/deposit`} element={<DepositHelp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <MobileFooter />
        <ScrollTop />
        <MobileSideBar isSide={isSide} setSide={() => setSide(false)} />
      </ScrollToTop>
    </Router>
  );
}

export default Routings;
