import { createSlice } from "@reduxjs/toolkit";
import { getGames, launchGame } from "../../api/game";

const initialState = {
	gamesList: [],
	game: null,
	categories: null,
	activeCategory: "All",
	activeProvider: "new",
	providers: null,
	gamesLoading: false,
	error: "",
	loading: false,
};

const game = createSlice({
	name: "game",
	initialState,
	reducers: {
		setActiveCategory: (state, action) => {
			state.activeCategory = action.payload;
		},
		setActiveProvider: (state, action) => {
			state.activeProvider = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(launchGame.fulfilled, (state, action) => {
			state.game = action.payload;
			state.loading = false;
		});
		builder.addCase(launchGame.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(launchGame.rejected, (state, action) => {
			state.error = action.payload.message;
			state.loading = false;
		});

		builder.addCase(getGames.fulfilled, (state, action) => {
			state.gamesList = action.payload.sort(
				(a, b) => a.sortOrder - b.sortOrder
			);

			if (action.payload) {
				state.categories = [
					...new Set(action.payload.map((game) => game.kind)),
				];
				state.providers = [
					...new Set(action.payload.map((game) => game.provider)),
				];
			}
			state.gamesLoading = false;
		});
		builder.addCase(getGames.pending, (state) => {
			state.gamesLoading = true;
		});
		builder.addCase(getGames.rejected, (state, action) => {
			state.error = action.payload.message;
			state.gamesLoading = false;
		});
	},
});

// Action creators are generated fore each case reducer function
export const { setActiveCategory, setActiveProvider } = game.actions;

export default game.reducer;
