import React, {useEffect} from "react";
import Tabs from '../../components/Tabs';
// styled
import { AboutUsContainer, AboutUsWrapper, AboutUsTitle, AboutUsContent } from './styled'

const PrivacyPolicy = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
              <Tabs tag={0} />
            <AboutUsContainer>
                <AboutUsWrapper>
                    <AboutUsTitle>Privacy Policy</AboutUsTitle>
                    <AboutUsContent>
                        <span>PRIVACY POLICY for www.vivaslots.co.za and related mobile applications ("<strong>Website</strong>")</span>
                        <span>This Privacy Policy is effective from 25 May 2018. We may change this Privacy Policy at any time, and if we do so will post the changes to this page as well as the date that the changes take effect.</span>
                        <span>This policy is made compliant with the General Data Protection Regulation (the “<strong>GDPR</strong>”), (Regulation (EU) 2016/679).</span>
                        <span>Our Privacy Policy below sets out in detail how we process your personal data, and we encourage and recommend that you read it in full. We understand that people have busy lives though, and so wanted to help you by providing a brief overview of how and why we process your personal data.</span>
                        <span>In general, we will only collect the personal data that we need from you to offer you our services. For example, if you want to gamble with us then we'll collect your name, username, contact details, date of birth and address in order to set you up with an account. We’ll process data on any games that you play so that we can identify if you have won and, of course, pay any winnings to you.</span>
                        <span>The other main reason we will process your personal data is to comply with our legal and regulatory obligations. As you may know, in order to provide you with gambling services, we are required to have a licence. As such, we will need to comply with our licence, and this may involve us collecting additional information about you.</span>
                        <span>Ultimately, we aim to only process your personal data where it is necessary. We will always keep your personal data secure. Your enjoyment of our services and your safety are our primary concerns.</span>
                        <strong>1. Introduction</strong>
                        <span>1.1. This Privacy Policy sets out the way in which Universe Entertainment Services Malta Limited ("<strong>we</strong>", " <strong>us</strong>" or "<strong>UEM</strong>"), a company incorporated and registered in Malta (company number C 60452), collects and processes Personal Information (as defined in paragraph 2.1 below). If you have any questions about this Privacy Policy or the processing of your Personal Information, please contact us at .</span>
                        <span>1.2. By using our Services, you acknowledge that you have read, and agreed to, the terms of this Privacy Policy and that you consent that UEM and any company in our Group, as well as any partners and subcontractors, will process your Personal Information in accordance with this Privacy Policy, including for the purposes set out in paragraph 5 below. If you do not wish to provide your Personal Information on the basis set out in this Privacy Policy, you should not enter the relevant information on the Website, or otherwise provide us with your Personal Information. However, if you do not provide your Personal Information, you may not be able to use all of the Services.</span>
                        <strong>2. The information we collect</strong>
                        <span>2.1. As part of operating the Website, we collect your Personal Information. "<strong>Personal Information</strong>" means any information from which you can be personally identified, including your name, email address, home address, telephone number, debit/credit card data, and date of birth.</span>
                        <span>2.2. We collect Personal Information which you provide to us, including when you register for an Account and information you provide in communications with us. We also collect information about the transactions you undertake, including details of payment cards used. We may also collect Personal Information through surveys which we, or companies engaged by us for such purpose, undertake, as well as information about anyone who visits our Website. In addition, we collect information about your use of the Website and our Services. We will also collect other information necessary for us to process your Personal Information for the purposes set out in this Privacy Policy.</span>
                        <strong>3. Information we collect through Cookies and similar technologies</strong>
                        <span>3.1. We collect information through "Cookies" and other similar technologies (e.g. pixel tags or links). This helps us to remember you when you visit the Website, and to improve your experience.</span>
                        <span>3.2. We also use information collected through "Cookies" and other similar technologies to detect any fraudulent behavior, improper transactions and/or abuse of our Website and Services, and to help verify your identity by registering and remembering your device information.</span>
                        <span>3.3. To find out more about "Cookies" and similar technologies please see paragraph 11 of this Privacy Policy. More information about the purposes of the "Cookies" we use can be found in paragraph 5.</span>
                        <strong>4. Information collected from third parties and how we use it</strong>
                        <span>We use information from third party service providers, such as fraud prevention companies. This information may be used in various ways, such as to help us: (i) decide whether to accept transactions from personal computers, mobile phones or other devices by checking whether these devices have been identified with fraudulent or abusive transactions in the past; (ii) detect the inappropriate use of promotions we may run; (iii) detect whether you have (or may have) cheated or colluded with others; and (iv) investigate, mitigate and/or prevent identity theft, account takeovers or malware attacks. Third party service providers also help us verify your identity by registering and remembering your device information (such as the model, operating system, browser version and IP address), which is used to confirm device identification.</span>
                        <strong>5. How we use your Personal Information</strong>
                        <span>In accordance with data protection laws, we will only process your Personal Information where we have a lawful basis for doing so. In respect of your Personal Information, these bases are: (i) where it is necessary to provide services to you under the performance of the contract we have with you; (ii) where we are required to do so in accordance with legal or regulatory obligations; (iii) where you have given your consent; and, (iv) where it is in our legitimate interests to process your Personal Information, provided that none of these prejudice your own rights, freedoms and interests.</span>
                        <strong>6. Disclosure of your Personal Information</strong>
                        <span>6.1. Except as described in this Privacy Policy, we will not disclose your Personal Information to third parties without your consent. We may disclose your Personal Information to third parties if you consent to us doing so, and for the Purposes, we may also disclose your Personal Information to any of the following recipients:</span>
                        <span>6.1.1. any company within our Group (including to its employees and sub-contractors) which assists us in providing our Services, including (but not limited to) hosting services, customer services, marketing services, advisory services or which otherwise has a need to know such information;</span>
                        <span>6.1.2. any third party which assists us in providing our Services, including (but not limited to) payment processors, customer services representatives and chat moderators;</span>
                        <span>6.1.3. any third party which can assist us in verifying the accuracy of your Personal Information, including financial institutions and credit reference agencies (a record of the search may be retained by such third party);</span>
                        <span>6.1.4. any third party which assists us in monitoring use of our Services, including the detection and prevention of fraud and collusion,</span>
                        <span>6.1.5. any contractors or other advisers auditing any of our business processes or who have the need to access such information for the purpose of advising us;</span>
                        <span>6.1.6. any law enforcement body which may have any reasonable requirement to access your Personal Information;</span>
                        <span>6.2. We may also use and disclose information in aggregate (so that no individuals are identified) for marketing and strategic development purposes.</span>
                        <span>6.3. If at any time you wish us to stop processing your Personal Information for the above Purposes, you should contact us, and we will take the appropriate steps to stop doing so. Please note that this may mean that your Account will be closed.</span>
                        <strong>7. Marketing Preferences</strong>
                        <span>7.1. The Service may contain features or links to web sites and services provided by third parties. UEM and any company in our Group will not send you unsolicited information regarding any third party's products or services. Any information you provide on third-party sites or services is provided directly to the operators of such services and is subject to those operators’ policies, if any, governing privacy and security, even if accessed through the Service. We are not responsible for the content or privacy and security practices and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to learn about third parties’ privacy and security policies before providing them with information. If our practices change, we will do so in accordance with applicable laws and will notify you in advance.</span>
                        <span>7.2. As part of the Account registration process, you will have the opportunity to choose whether or not to receive information on offers and promotions from us and any company in our Group.</span>
                        <strong>8. Security</strong>
                        <span>8.1. We are committed to ensuring that your Personal Information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the Personal Information that we collect.</span>
                        <span>8.2. All the Personal Information that you provide us is stored in a secure computing environment protected by secure firewalls to prevent unauthorized access. We control access so that only people who need to access the Personal Information can. All staff are provided security training and are required to adhere to a comprehensive set of security policies, procedures, and standards related to their jobs.</span>
                        <span>8.3. When you use secure areas of our Website, we use SSL with 256 -bit encryption. This means that all the information sent between your computer and our secure computer environment is encrypted or scrambled so that no one can read it in transit. Secure areas of the site have a time-out feature. If you leave your secure session inactive for some time, it times-out to prevent unauthorized access.</span>
                        <span>8.4. Where your Personal Information needs to be disclosed to our service providers, we require them to process and protect your Personal Information in a manner consistent with this Privacy Policy and applicable laws.</span>
                    </AboutUsContent>                                   
                </AboutUsWrapper>
            </AboutUsContainer>
        </React.Fragment>
    )
}

export default PrivacyPolicy;