import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RiLoginBoxLine, RiStarSFill } from "react-icons/ri";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { AiFillTag } from "react-icons/ai";
import { MdOutput } from 'react-icons/md';
import RegisterImg from "../../assets/image/statics/bottommenu_register.png";
import { routes } from "../../constants/routes";
import { useTypedDispatch, useTypedSelector } from "../../store/typedHooks";
import { launchGame } from "../../api/game";
import { setLogout } from "../../store/slices/auth";
import { setActiveProvider } from "../../store/slices/game";
import { onUpdateGameData } from "../../pages/Landing";
import {
	FooterListWrapperItem,
	FooterListWrapperItemHover,
} from "../../layout/Footer/styled";
import "./footer.css";

const MobileFooter = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useTypedDispatch();

	const [selectedtabindex, setSelectedTabIndex] = useState(0);
	const [isshownew, setIsShowNew] = useState(false);
	const [isshowfavorite, setIsShowFavorite] = useState(false);
	const [newgameList, setNewGameList] = useState([]);
	const { gamesList, activeProvider } = useTypedSelector((state) => state.game);
	const { isLogin } = useTypedSelector((state) => state.auth);

	useEffect(() => {
		if (activeProvider.toUpperCase() == "NEW") {
			setSelectedTabIndex(1);
		} else if (activeProvider.toUpperCase() == "ALL") {
			setSelectedTabIndex(4);
		} else if (activeProvider.toUpperCase() == "FAVORITE") {
			setSelectedTabIndex(2);
		} else {
			setSelectedTabIndex(3);
		}
	}, [activeProvider]);

	useEffect(() => {
		const newGames_arr = gamesList.filter((item) => item.release != "OLD");
		const new_arr_list = onUpdateGameData(newGames_arr);
		setNewGameList(new_arr_list);
	}, [gamesList]);

	useEffect(() => {
		if (location.pathname == routes.HOME)
			if (activeProvider.toUpperCase() == "NEW") {
				setSelectedTabIndex(1);
			} else if (activeProvider.toUpperCase() == "ALL") {
				setSelectedTabIndex(4);
			} else if (activeProvider.toUpperCase() == "FAVORITE") {
				setSelectedTabIndex(2);
			} else {
				setSelectedTabIndex(3);
			}
		else setSelectedTabIndex(0);
	}, [location]);

	const handleSignOut = () => {
		dispatch(setLogout());
	}

	const onTabTapped = (index) => {
		switch (index) {
			case 1:
				setIsShowNew(!isshownew);
				if (!isshownew) setIsShowFavorite(false);
				break;
			case 2:
				setIsShowFavorite(!isshowfavorite);
				if (!isshowfavorite) setIsShowNew(false);
				break;
			case 3:
				navigate(routes.REGISTER);
				break;
			case 4:
				dispatch(setActiveProvider("ALL"));
				if (location.pathname != routes.HOME) navigate(routes.HOME);
				break;
			case 5:
				navigate(routes.LOGIN);
				break;
		}
	};

	const tappedPlay = async (gameItem) => {
		if (isLogin) {
			const newGame = await dispatch(launchGame(gameItem["gameName"])).unwrap();
			if (newGame) {
				navigate(`/gameId/${gameItem["gameName"]}`, {
					state: {
						displayName: gameItem["displayName"],
						gameURL: newGame["url"],
					},
				});
			}
		} else navigate(routes.LOGIN);
	};

	const tappedDemo = async (gameItem) => {
		if (isLogin) {
			navigate(`/gameId/${gameItem["gameName"]}`, {
				state: {
					displayName: gameItem["displayName"],
					gameURL: gameItem["demo_url"],
				},
			});
		} else navigate(routes.LOGIN);
	};

	const is_new_list_show = isshowfavorite || isshownew;
	return (
		<div className="footer_bottom_mobile_container">
			{
				!isLogin && <div className="footer_mobile_center_button">
					<a style={{ zIndex: 1 }}>
						<div>
							<div className="footer_mobile_img">
								<img
									src={RegisterImg}
									style={{ width: "27px", height: "auto" }}
									alt="footer-register"
								/>
							</div>
							<div className="footer-mobile-text" onClick={() => onTabTapped(3)}>
								Register
							</div>
						</div>
					</a>
				</div>
			}
			<div className="footer_bottom_mobile_main">
				{
					isLogin ? <div className="footer_mobile_btngroup">
						<a>
							<div className="footer_mobile_img">
								<AiFillTag color={isshownew ? "#448635" : "#fff"} size={28} />
							</div>
							<div
								className="footer-mobile-text"
								onClick={() => onTabTapped(1)}
							>
								New
							</div>
						</a>
						<a>
							<div className="footer_mobile_img">
								<RiStarSFill
									color={isshowfavorite ? "#448635" : "#fff"}
									size={28}
								/>
							</div>
							<div
								className="footer-mobile-text"
								onClick={() => onTabTapped(2)}
							>
								Favorites
							</div>
						</a>
						<a>
							<div className="footer_mobile_img">
								<BsFillGrid3X3GapFill
									color={selectedtabindex == 4 ? "#448635" : "#fff"}
									size={28}
								/>
							</div>
							<div
								className="footer-mobile-text"
								onClick={() => onTabTapped(4)}
							>
								All Games
							</div>
						</a>
						{isLogin ?
							<a>
								<div className="footer_mobile_img">
									<MdOutput color="#fff" size={28} />
								</div>
								<div
									className="footer-mobile-text"
									onClick={() => handleSignOut()}
								>
									Sign Out
								</div>
							</a> : <a>
								<div className="footer_mobile_img">
									<RiLoginBoxLine color="#fff" size={28} />
								</div>
								<div
									className="footer-mobile-text"
									onClick={() => onTabTapped(5)}
								>
									Login
								</div>
							</a>}
					</div> : <div className="footer_mobile_btngroup">
						<div>
							<a>
								<div className="footer_mobile_img">
									<AiFillTag color={isshownew ? "#448635" : "#fff"} size={28} />
								</div>
								<div
									className="footer-mobile-text"
									onClick={() => onTabTapped(1)}
								>
									New
								</div>
							</a>
							<a>
								<div className="footer_mobile_img">
									<RiStarSFill
										color={isshowfavorite ? "#448635" : "#fff"}
										size={28}
									/>
								</div>
								<div
									className="footer-mobile-text"
									onClick={() => onTabTapped(2)}
								>
									Favorites
								</div>
							</a>
						</div>
						<div>
							<a>
								<div className="footer_mobile_img">
									<BsFillGrid3X3GapFill
										color={selectedtabindex == 4 ? "#448635" : "#fff"}
										size={28}
									/>
								</div>
								<div
									className="footer-mobile-text"
									onClick={() => onTabTapped(4)}
								>
									All Games
								</div>
							</a>
							{isLogin ?
								<a>
									<div className="footer_mobile_img">
										<MdOutput color="#fff" size={28} />
									</div>
									<div
										className="footer-mobile-text"
										onClick={() => handleSignOut()}
									>
										Sign Out
									</div>
								</a> : <a>
									<div className="footer_mobile_img">
										<RiLoginBoxLine color="#fff" size={28} />
									</div>
									<div
										className="footer-mobile-text"
										onClick={() => onTabTapped(5)}
									>
										Login
									</div>
								</a>}
						</div>
					</div>
				}
			</div>
			<div
				className="footer_game_list_container"
				style={is_new_list_show ? { display: "flex" } : { display: "none" }}
			>
				{newgameList?.map((newitem, index) => {
					return (
						<FooterListWrapperItem imgurl={newitem.url} key={index}>
							<FooterListWrapperItemHover>
								<span id="firstspan" onClick={() => tappedPlay(newitem)}>
									Play Now
								</span>
								{newitem.demo_url != "" && (
									<span id="lastspan" onClick={() => tappedDemo(newitem)}>
										Demo
									</span>
								)}
							</FooterListWrapperItemHover>
						</FooterListWrapperItem>
					);
				})}
			</div>
		</div>
	);
};

export default MobileFooter;
