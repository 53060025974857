import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "@mui/material";
import { Store } from "react-notifications-component";
import { MdArrowBackIosNew, MdClose } from "react-icons/md";
import { useTypedDispatch, useTypedSelector } from "../../store/typedHooks";
import { loginUser, registerUser } from "../../api/auth";
import { routes } from "../../constants/routes";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import {
	ModalContainer,
	ModalHeader,
	ModalHeaderImg,
	ModalInfo,
	ModalWrapper,
	ModalContent,
	ModalMobileHeader,
	ModalMobileBackBtn,
	ModalMobileLoginBtn,
	ModalFooterImg,
	ModalCloeBtn,
} from "./styled";
import Logo from "../../assets/image/viva_slot.png";
import LoginImg from "../../assets/image/EN-zar.png";
import RegisterImg from "../../assets/image/reg_ENeur.png";
import { forgotPassword, resetPassword } from "../../api/auth";

var validator = require("validator");

export const ForgotpwdModal = ({ handleClose, isOpen }) => {
	const [emailaddress, setEmailAddress] = useState("");
	const dispatch = useTypedDispatch();

	const { isLogin } = useTypedSelector((state) => state.auth);

	const tappedContinue = () => {
		Store.addNotification({
			title: "Success!",
			message: "We sent a confim code to your email",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
			},
		});
		const forgotdata = {
			email: emailaddress,
			template: 'vivaslots'
		}
		dispatch(forgotPassword(forgotdata));
		handleClose(false, false, false, false);
	};

	useEffect(() => {
		if (isLogin) handleClose(false, false, false, false);
	}, [isLogin]);

	return (
		<Modal
			keepMounted
			open={isOpen}
			onClose={() => handleClose(false, false, false, false)}
			disablePortal
			disableEnforceFocus
			disableAutoFocus
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
		>
			<ModalContainer>
				<ModalCloeBtn onClick={() => handleClose(false, false, false, false)}>
					<MdClose color="white" />
				</ModalCloeBtn>
				<ModalHeader>
					<img src={Logo} alt="logo" height="55px" />
					<div>Forgot User / Password Assistance</div>
				</ModalHeader>

				<ModalWrapper>
					<ModalContent>
						<div>
							<input
								placeholder="Enter user or email address"
								onChange={(e) => setEmailAddress(e.target.value)}
								value={emailaddress}
							/>
						</div>
						<button onClick={() => tappedContinue()}>Continue</button>
					</ModalContent>
				</ModalWrapper>
			</ModalContainer>
		</Modal>
	);
};

export const ResetpwdModal = ({ handleClose, isOpen }) => {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();
	const data = useLocation();

	const tappedContinue = () => {
		const resetdata = {
			password: newPassword,
			token: data.search.substr(data.search.indexOf('=') + 1, data.search.indexOf('nonce') - 8),
		};
		dispatch(resetPassword(resetdata))
			.then((res) => {
				if (res.payload.message == 'OK') {
					Store.addNotification({
						title: 'Success',
						message: 'Reset Password Success',
						type: 'success',
						insert: 'top',
						container: 'top-right',
						animationIn: ['animate__animated', 'animate__fadeIn'],
						animationOut: ['animate__animated', 'animate__fadeOut'],
					});
					navigate(routes.HOME);
				}
			})
			.catch((err) => console.log(err));
		handleClose(false, false, false, false);
	};

	return (
		<Modal
			keepMounted
			open={isOpen}
			onClose={() => handleClose(false, false, false, false)}
			disablePortal
			disableEnforceFocus
			disableAutoFocus
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
		>
			<ModalContainer>
				<ModalCloeBtn onClick={() => handleClose(false, false, false, false)}>
					<MdClose color="white" />
				</ModalCloeBtn>
				<ModalHeader>
					<img src={Logo} alt="logo" height="55px" />
					<div>Reset Password</div>
				</ModalHeader>

				<ModalWrapper>
					<ModalContent>
						<div>
							<input
								placeholder="Enter new Password"
								onChange={(e) => setNewPassword(e.target.value)}
								value={newPassword}
							/>
						</div>
						<div>
							<input
								placeholder="Enter confrim Password"
								onChange={(e) => setConfirmPassword(e.target.value)}
								value={confirmPassword}
							/>
						</div>
						<button onClick={() => tappedContinue()}>Continue</button>
					</ModalContent>
				</ModalWrapper>
			</ModalContainer>
		</Modal>
	);
};

export const LoginModal = ({ handleClose, isOpen }) => {
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const [user_name, setUsername] = useState("");
	const [pass_word, setPassword] = useState("");
	const [isShow, setShow] = useState(false);
	// const [user_name, setUsername] = useState('')
	// const [pass_word, setPassword] = useState('')
	const { isLogin } = useTypedSelector((state) => state.auth);

	const tappedLogin = () => {
		const logindata = {
			username: user_name,
			password: pass_word,
		};
		dispatch(loginUser(logindata));
	};

	useEffect(() => {
		if (isLogin) handleClose(false, false, false, false);
	}, [isLogin]);
	return (
		<Modal
			keepMounted
			open={isOpen}
			onClose={() => handleClose(false, false, false, false)}
			disablePortal
			disableEnforceFocus
			disableAutoFocus
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
		>
			<ModalContainer>
				<ModalCloeBtn onClick={() => navigate(routes.HOME)}>
					<MdClose />
				</ModalCloeBtn>
				<ModalMobileHeader>
					<ModalMobileBackBtn onClick={() => handleClose(false, false, false, false)}>
						<MdArrowBackIosNew />
						Back
					</ModalMobileBackBtn>
					<img src={Logo} alt="logo" height="55px" />
				</ModalMobileHeader>
				<ModalWrapper>
					<ModalContent>
						<div>
							<input
								type="text"
								placeholder="Username / Email"
								onChange={(e) => setUsername(e.target.value)}
								value={user_name}
							/>
						</div>
						<div>
							<input
								type={isShow ? "text" : "password"}
								placeholder="Password"
								onChange={(e) => setPassword(e.target.value)}
								value={pass_word}
							/>
							{isShow ? <BsEye color="#000" onClick={() => setShow(false)} /> : <BsEyeSlash color="#000" onClick={() => setShow(true)} />}
						</div>
						<button onClick={() => tappedLogin()}>LOGIN</button>
					</ModalContent>
					<div style={{ borderBottom: "2px solid #000" }}></div>
					<ModalFooterImg>
						<span>You don't have an account?</span>
						<img src={LoginImg} alt="login_img" width="80%" />
						<button onClick={() => handleClose(false, true, false, false)}>
							REGISTER
						</button>
					</ModalFooterImg>
				</ModalWrapper>
			</ModalContainer>
		</Modal>
	);
};

export const RegisterModal = ({ handleClose, isOpen, referCode }) => {
	const [first_name, setFirstname] = useState("");
	const [last_name, setLastname] = useState("");
	const [user_name, setUsername] = useState("");
	const [pass_word, setPassword] = useState("");
	const [confirm_pwd, setConfirmpwd] = useState("");
	const [user_email, setUserEmail] = useState("");
	const [error_msg, setShowErrorMessage] = useState("");
	const [phone, setPhone] = useState("");
	const [refer_code, setReferCode] = useState("vivaCashier");
	const [isShow, setShow] = useState(false);
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const { isLogin } = useTypedSelector((state) => state.auth);
	const usernameIsValid = (userStr) => {
		return /^[0-9a-zA-Z_.-]+$/.test(userStr);
	};

	const clearAll = () => {
		setFirstname("");
		setLastname("");
		setUsername("");
		setPassword("");
		setConfirmpwd("");
		setUserEmail("");
		setPhone("");
		setReferCode("");
	}

	const tappedRegister = () => {
		if (first_name === "") {
			setShowErrorMessage("Invalid First name.");
			return;
		}
		if (last_name === "") {
			setShowErrorMessage("Invalid Last name.");
			return;
		}
		if (!validator.isEmail(user_email)) {
			setShowErrorMessage("Invalid Email address.");
			return;
		}
		if (!usernameIsValid(user_name)) {
			setShowErrorMessage("Invalid Username.");
			return;
		}
		if (phone === "") {
			setShowErrorMessage("Invalid Phone number");
			return;
		}
		if (pass_word.length < 8) {
			setShowErrorMessage("Enter 8 characters over for password.");
			return;
		}

		let postdata = {
			username: user_name,
			password: pass_word,
			email: user_email,
			phone: phone,
			operatorName: "vivaCashier",
			template: "vivaslots",
		};

		dispatch(registerUser(postdata))
			.then((res) => {
				if (res.payload.message == 'OK') {
					setShowErrorMessage(null);
					clearAll();
					handleClose(false, false, false, false)
					navigate(routes.HOME);
				}
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (isLogin) {
			console.log("login", isLogin);
			handleClose(false, false, false, false);
		}
	}, [isLogin]);

	return (
		<Modal
			keepMounted
			open={isOpen}
			onClose={() => handleClose(false, false, false, false)}
			disablePortal
			disableEnforceFocus
			disableAutoFocus
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
		>
			<ModalContainer>
				<ModalCloeBtn onClick={() => navigate("/")}>
					<MdClose color="white" />
				</ModalCloeBtn>
				<ModalMobileHeader>
					<ModalMobileBackBtn onClick={() => { clearAll(); handleClose(false, false, false, false) }}>
						<MdArrowBackIosNew />
						Back
					</ModalMobileBackBtn>
					<img src={Logo} alt="logo" height="55px" />
					<ModalMobileLoginBtn onClick={() => { clearAll(); handleClose(true, false, false, false) }}>
						Login
					</ModalMobileLoginBtn>
				</ModalMobileHeader>
				<ModalHeaderImg>
					<img src={RegisterImg} alt="register_img" width="100%" />
				</ModalHeaderImg>
				<ModalWrapper>
					<ModalInfo>Personal information</ModalInfo>
					<ModalContent>
						<div>
							<input
								placeholder="First Name"
								onChange={(e) => setFirstname(e.target.value)}
								value={first_name}
							/>
						</div>
						<div>
							<input
								placeholder="Last Name"
								onChange={(e) => setLastname(e.target.value)}
								value={last_name}
							/>
						</div>
						<div>
							<input
								placeholder="Email"
								onChange={(e) => setUserEmail(e.target.value)}
								value={user_email}
							/>
						</div>
						<div>
							<input
								placeholder="Username"
								onChange={(e) => setUsername(e.target.value)}
								value={user_name}
							/>
						</div>
						<div>
							<input
								placeholder="Phone"
								onChange={(e) => setPhone(e.target.value)}
								value={phone}
							/>
						</div>
						<div>
							<input
								placeholder="Password"
								type={isShow ? "text" : "password"}
								onChange={(e) => setPassword(e.target.value)}
								value={pass_word}
							/>
							{isShow ? <BsEye color="#000" onClick={() => setShow(false)} /> : <BsEyeSlash color="#000" onClick={() => setShow(true)} />}
						</div>
						<div>
							<input
								placeholder="Confirm Password"
								type={isShow ? "text" : "password"}
								onChange={(e) => setConfirmpwd(e.target.value)}
								value={confirm_pwd}
							/>
							{isShow ? <BsEye color="#000" onClick={() => setShow(false)} /> : <BsEyeSlash color="#000" onClick={() => setShow(true)} />}
						</div>
						<div>
							<input
								placeholder="Refer Code"
								onChange={(e) => setReferCode(e.target.value)}
								value={referCode}
							// disabled
							/>
						</div>
						<span>{error_msg}</span>
						<button onClick={() => tappedRegister()}>REGISTER</button>
					</ModalContent>
				</ModalWrapper>
			</ModalContainer>
		</Modal>
	);
};
