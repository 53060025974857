import styled from "styled-components";

export const FooterOutContainer = styled.div`
	display: ${(props) => (props.isHidden ? "none" : "block")};
	position: relative;
`;

export const FooterContainer = styled.div`
	background-color: #0c3058;
	padding: 20px 0;
	position: absolute;
	width: 100% !important;
	top: 33px;
	@media screen and (max-width: 820px) {
		padding-bottom: 70px;
	}
`;

export const FooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1200px;
	align-items: center;
	justify-content: center;
	margin: auto;
	row-gap: 10px;
	color: #fff;
	font-size: 14px;
`;

export const LegalPages = styled.ul`
	list-style: none;
	padding: 0;
	li:not(:last-child) {
		border-right: 1px solid #fff;
	}
	li {
		display: inline;
		padding: 0 1.4rem;
		cursor: pointer;
	}
	@media screen and (max-width: 820px) {
		display: none;
	}
`;

export const BottomLogos = styled.ul`
	list-style: none;
	padding: 0;
	li {
		display: inline;
		padding: 0 1rem;
		cursor: pointer;
	}
	@media screen and (max-width: 820px) {
		order: 2;
		li {
			img {
				padding: 0 1px;
				height: 20px;
			}
		}
	}
`;
export const FooterStickyContainer = styled.div`
	background-color: #143b64;
	height: ${(props) => (props.isHidden ? "33px" : "183px")};
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 0;
	display: flex;
	z-index: 11;
	flex-direction: column;
	@media screen and (max-width: 820px) {
		display: none;
	}
`;

export const FooterStickyTabContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

export const FooterSticklyTabItemWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	color: ${(props) => (props.isHidden ? "#448635" : "#fff")};
	span {
		margin-left: 5px;
	}
`;

export const FooterNumberItem = styled.span`
	background: red;
	border-radius: 15px;
	font-size: 9px;
	padding: 1px 7px;
	position: absolute;
	left: -20px;
	top: 0px;
`;

export const FooterListWrapperItemHover = styled.div`
	display: none;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	font-family: "RobotoRegular";
	font-size: 12px;
	cursor: pointer;
	@media screen and (max-width: 820px) {
		font-size: 10px;
	}

	#firstspan {
		padding: 5px;
		background: rgb(0, 126, 69);
		color: white;
		min-width: calc(50% - 0.5em);
		margin-bottom: 10px;
		@media screen and (max-width: 820px) {
			margin-bottom: 5px;
		}
	}
	#lastspan {
		padding: 5px;
		background: #1b6796;
		color: white;
		min-width: calc(50% - 0.5em);
	}
`;

export const FooterListWrapperItem = styled.div`
	height: 120px;
	min-width: 120px;
	margin: 0 5px;
	background-image: url(${(props) => props.imgurl});
	background-position: top;
	background-size: 100% 100%;
	position: relative;
	&:hover ${FooterListWrapperItemHover} {
		display: flex;
		flex-direction: column;
	}
	@media screen and (max-width: 820px) {
		height: 75px;
		min-width: 75px;
		margin: 0 2px;
	}
`;

export const FooterStickyNewListContainer = styled.div`
	height: ${(props) => (props.isHidden ? "0px" : "150px")};
	width: 100%;
	background: white;
	overflow: scroll;
	align-items: center;
	display: flex;
`;

export const FooterNew = styled.div`
	font-size: 17.78px;
	color: #fff;
	font-weight: 700;
	display: flex;
	align-items: center;
	gap: 20px;
	margin-left: 20px;
	span {
		cursor: pointer;
	}
`;
export const FooterAllGames = styled.div`
	font-size: 17.78px;
	color: #fff;
	font-weight: 700;
	margin-right: 30px;
	span {
		cursor: pointer;
	}
`;
export const PaymentLogosContainer = styled.ul`
	list-style: none;
	padding: 0;
	margin-top: 0 !important;
	li {
		display: inline;
		padding: 0 0.5rem;
		cursor: pointer;
		img {
			width: 80px;
			height: 50px;
		}
	}
	@media screen and (max-width: 820px) {
		order: 2;
		li {
			img {
				padding: 0 1px;
				width: 40px;
				height: max-content;
			}
		}
	}
`;
export const TextContainer = styled.div``;
export const MobileRegalPages = styled.div`
	list-style: none;
	display: none;
	padding: 0;
	li:not(:last-child) {
		border-right: 1px solid #fff;
	}
	li {
		display: inline;
		padding: 0 10px;
		cursor: pointer;
	}
	@media screen and (max-width: 820px) {
		display: flex;
	}
	@media screen and (max-width: 430px) {
		font-size: 12px;
	}
`;
export const ScrollContainer = styled.div`
	background-color: transparent;
	width: 70%;
	margin: auto;
	height: 29px;
	border-radius: 3px;
	border: 1px solid #555555;
	cursor: pointer;
	font-size: 14px;
	display: none;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	span {
		color: #fff;
		margin-right: 10px;
	}
	@media screen and (max-width: 820px) {
		display: flex;
	}
`;
