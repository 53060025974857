import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../constants/routes';
import NotFoundImg from '../../assets/image/notfound.png';
import { NotFoundContainer } from './styled';

export const NotFound = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(location);
    // if (location.pathname.includes('/admin')) {
    //   window.open(`${process.env.REACT_APP_REDIRECT_DEV_URL}${location.pathname}`, '_self');
    // }
  }, [location.pathname])

  return (
    <NotFoundContainer>
      <img src={NotFoundImg} style={{ "width": "80px" }} alt='404' />
      <span data-text={'Page not found'}>Oops! Page not found</span>
      <span
        data-text={'The page you are looking for doesn\'t exist or has been moved'}>
        The page you are looking for doesn't exist or has been moved
      </span>
      <div>
        <p>
          <Link to={routes.HOME}>
            <span data-text="Go to the homepage">
              Go to the homepage
            </span>
          </Link>
        </p>
      </div>
    </NotFoundContainer>
  );
};
