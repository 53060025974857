import styled from "styled-components";

export const HeaderOutContainer = styled.div`
	display: ${(props) => (props.isHidden ? "none" : "block")};
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: fixed;
	z-index: 1000;
	top: 0;
`;
export const HeaderTapWrapper = styled.div`
	position: relative;
	display: flex;
	height: 100%;
	align-items: center;
	z-index: 1;
`;

export const Tap = styled.div`
	background-color: ${(props) => props.active && "#133966"};
	font-weight: ${(props) => (props.active ? 700 : 500)};
	font-size: 14px;
	height: 100%;
	display: flex;
	align-items: center;
	color: ${(props) => props.active && "#fff"};
	cursor: pointer;
	padding: 0 7px;
`;

export const HeaderTopWrapper = styled.div`
	position: relative;
	background-color: #d7d7d7;
	height: 32px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	@media screen and (max-width: 820px) {
		display: none;
	}
`;

export const HeaderTimezoneWrapper = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	left: 14px;
	span {
		font-size: 11px;
		color: #000;
		font-weight: 700;
		margin-left: 5px;
	}
`;
export const HeaderLangContainer = styled.div`
	position: absolute;
	max-width: 1200px;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	@media screen and (max-width: 1200px) {
		margin-right: 20px;
	}
`;
export const HeaderLangWrapper = styled.div`
	display: flex;
	align-items: center;
`;
export const LangSelect = styled.select`
	height: 1.63em;
	border: 1px solid #7c868e;
	background: #e8f0fe;
	margin-right: 10px;
	outline: none;
`;

export const HeaderLogoWrapper = styled.div`
	max-width: 1200px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 5px;
	.res-bar-icon {
		display: none;
		@media screen and (max-width: 820px) {
			display: flex;
			margin-left: 8.6px;
		}
	}
`;
export const HeaderLogo = styled.img`
	height: 100px;
	cursor: pointer;
	@media screen and (max-width: 1200px) {
		margin-left: 10px;
	}
	@media screen and (max-width: 820px) {
		height: 60px;
	}
`;

export const HeaderLogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #133966;
	height: 85px;
	@media screen and (max-width: 820px) {
		height: 55px;
	}
`;

export const LoginWrapper = styled.div`
	display: flex;
	@media screen and (max-width: 1200px) {
		margin-right: 10px;
	}
	@media screen and (max-width: 820px) {
		margin-right: 0;
	}
`;

export const LoginUserContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 3px;
`;

export const DepositBtn = styled.button`
	display: flex;
	align-items: center;
	border-radius: 20px;
	padding: 5px 10px;
	background-color: #333399;
	cursor: pointer;
	color: #fff;
	font-weight: 600;
	border-color: #333399;
	:disabled {
		background-color: #8c8cd9;
		cursor: not-allowed;
	}
`

export const LoginUserWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	position: relative;
	justify-content: flex-start;
	/* margin: 0 5px; */
	& > svg {
		fill: white;
		cursor: pointer;
	}
	strong {
		color: #fff;
		margin-left: 3px;
	}
	& > span {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
		margin-left: 5px;
		margin-top: 2px;
		display: flex;
		align-items: center;
		@media screen and (max-width: 650px) {
			display: none;
		}
	}
`;

export const LoginInputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	span {
		font-size: 13px;
		color: #fff;
		font-weight: 700;
		cursor: pointer;
		text-decoration: underline;
		margin-top: 10px;
	}
	@media screen and (max-width: 820px) {
		display: none;
	}
`;
export const Inputs = styled.div`
	display: flex;
	gap: 10px;
	margin-right: 10px;
	div {
		background-color: #fff;
		display: flex;
		height: 22px;
		padding: 0 5px;
		align-items: center;
		width: 120px;
		position: relative;
	}
	input {
		border: none;
		outline: none;
		width: 85%;
	}
`;
export const LoginBtnsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	@media screen and (max-width: 820px) {
		flex-direction: row;
		margin-right: 8.6px;
	}
`;
export const LoginBtn = styled.div`
	background-color: #aea269;
	width: 80px;
	font-weight: 700;
	border-radius: 3px;
	cursor: pointer;
	height: 22px;
	display: flex;
	transition: all 0.3s ease-in-out;
	justify-content: center;
	font-size: 11px;
	align-items: center;
	color: #fff;
	:hover {
		background-color: #007e45;
	}
	@media screen and (max-width: 820px) {
		display: none;
	}
`;
export const MobileLoginBtn = styled.div`
	display: none;
	background-color: #aea269;
	width: 80px;
	height: 29.25px;
	border-radius: 0;
	order: 2;
	font-weight: 400;
	font-size: 13px;
	color: #fff;
	@media screen and (max-width: 820px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const JoinBtn = styled.div`
	background-color: #007e45;
	width: 80px;
	transition: all 0.3s ease-in-out;
	border-radius: 3px;
	font-weight: 700;
	cursor: pointer;
	height: 22px;
	display: flex;
	justify-content: center;
	font-size: 11px;
	align-items: center;
	color: #fff;
	@media screen and (max-width: 820px) {
		height: 29.25px;
		border-radius: 0;
		font-size: 13px;
		font-weight: 400;
	}
`;

export const InfoContainer = styled.div`
	position: absolute;
	background-color: #fff;
	border: 1px solid #666666;
	width: 250px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	right: 0;
	justify-content: center;
	top: 35px;
	z-index: 11;
	hr {
		width: 100%;
	}
`;

export const InfoWarpper = styled.div`
	display: flex;
	gap: 15px;
	padding: 10px 20px;
`

export const UserIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #333399;
`

export const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	span {
		color: #666666;
		font-weight: 500;
		font-size: 20px;
	}
	p {
		margin: 0;
		font-weight: 700;
		font-size: 20px;
	}

`

export const CustomBtn = styled.div`
	display: flex;
	gap: 10px;
	padding: 10px 20px;
	align-items: center;
	color: #666666;
	font-weight: 500;
	cursor: pointer;
`

export const DownloadContainer = styled.div`
	display: none;
	height: 60px;
	background-color: #fff;
	position: fixed;
	top: 0;
	z-index: 1001;
	width: 100%;
	box-sizing: border-box;
	/* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
	align-items: center;
	justify-content: space-between;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	padding: 0 20px;
	@media screen and (max-width:600px) {
		display: ${props => props.flag ? 'none' : 'flex'};	
	}
`

export const DownloadButton = styled.div`
	display: flex;
	border-radius: 10px;
	border: 1px solid #000;
	padding: 10px;
	background-color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	a {
		text-decoration: none;
		color: #000;
	}
`