import React from "react";
import { useNavigate } from 'react-router-dom'
import { CreateAccountHelpContainer, CreateAccountHelpWrapper, CreateAccountHelpTitle, CreateAccountContent } from './styled';
import JoinImg from '../../../assets/image/help/join.png';
import { routes } from "../../../constants/routes";

const CreateAccountHelp = () => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            {/* <Tabs tag={5} /> */}
            <CreateAccountHelpContainer>
                <CreateAccountHelpWrapper>
                    <CreateAccountHelpTitle>
                        How to create an account
                    </CreateAccountHelpTitle>
                    <CreateAccountContent>
                        <span>Creating an account takes just a few moments and it’s free to join.</span>
                        <span>All you have to do is to click on <b onClick={() => navigate(routes.REGISTER)}>"JOIN NOW"</b> button located on the right upper corner of the site.</span>
                        <img src={JoinImg} alt="join-help" />
                        <span>Fill in all the required fields to create your free casino account and get started.</span>
                        <span>Make sure all details provided are valid.</span>
                    </CreateAccountContent>
                </CreateAccountHelpWrapper>
            </CreateAccountHelpContainer>
        </React.Fragment>
    )
}

export default CreateAccountHelp;