import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/Tabs";

import BGCard from "../../assets/image/promotion/BG-Card.jpg";
import MonthlyBonus from "../../assets/image/promotion/Monthly-Bonus.jpg";
import FirstBonus from "../../assets/image/promotion/First-Deposit.jpg";
import SecondBonus from "../../assets/image/promotion/Second-Deposit.jpg";
import { routes } from "../../constants/routes";

import {
	PromitionsContainer,
	PromitionsWrapper,
	PromotionTitle,
	PromotionContent,
	PromotionList,
	PromotionListItem,
	PromotionMoreBtn,
	PromotionListContainer,
} from "./styled";
// import { Grid } from '@mui/material';

const Promotions = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const promotionList = [
		{
			img: FirstBonus,
			title: "first deposit bonus",
			content:
				"At Viva Slots, you can collect up to 2,400 ZAR in Welcome Bonuses! As a new player, we'll match your first deposit 100% up to 100 ZAR, doubling your bankroll straight away. You'll continue to receive this bonus for a full year, whenever you make your first deposit of each new month. On top of that, you can also collect a minimum 25 ZAR Weekly Loyalty Bonus. That’s up to 2,400 ZAR available in bonuses for joining us at Viva Slots!",
		},
		{
			img: SecondBonus,
			title: "second deposit bonus",
			content:
				"After you’ve made your first deposit at Viva Slots, you can collect a 60% bonus on your 2nd deposit, up to 500 ZAR. Make your second single deposit of 20 ZAR or more, and your 2nd Deposit Bonus will be instantly credited to your account.",
		},
		{
			img: MonthlyBonus,
			title: "wager bonus",
			content:
				"Whenever you deposit an amount, you will get 100% of your deposit added to your balance. The credits are added automatically at the time of the deposit. For example if you deposit 10.00 you will get 20.00 added to your balance. During wager bonus you cannot top up before your balance becomes 7 times the initial amount. For example if you deposit 10.00 you can cash out after 70. You can deposit again if balance goes bellow 1.00. You can cancel wager bonus if you didn't start playing.",
		},
	];

	const tappedMore = (index) => {
		const new_route =
			index === 0
				? routes.PROMOTIONSFirst
				: index === 1
					? routes.PROMOTIONSSecond
					: routes.PROMOTIONSMonth;
		navigate(new_route);
	};

	return (
		<React.Fragment>
			<Tabs tag={2} />
			<PromitionsContainer>
				<PromitionsWrapper>
					<PromotionTitle>VIVA SLOTS PROMOTIONS</PromotionTitle>
					<PromotionContent>
						Viva Slots is known for offering some of the best casino promotions
						online. Whether it's weekly and monthly loyalty bonuses, special
						Inbox offers, or other exclusive deposit bonus opportunities, Viva
						Slots rewards players every step of the way. Browse through our
						promotional offers below to find out how you can earn extra playing
						cash right now!
					</PromotionContent>
					<PromotionListContainer>
						{promotionList.map(
							(item, key) => (
								// <Grid item md={4} key={key}>
								<PromotionList background={BGCard} key={key}>
									<img src={item.img} alt="" draggable={false} width="100%" />
									<PromotionListItem>
										<h2>{item.title}</h2>
										<span>{item.content}</span>
									</PromotionListItem>
									<PromotionMoreBtn onClick={() => tappedMore(key)}>
										MORE INFO
									</PromotionMoreBtn>
								</PromotionList>
							)
							// </Grid>
						)}
					</PromotionListContainer>
				</PromitionsWrapper>
			</PromitionsContainer>
		</React.Fragment>
	);
};

export default Promotions;
