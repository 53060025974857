import styled from "styled-components";

export const CreateAccountHelpContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 117px;
    @media screen and (max-width: 820px){
        margin-top: 55px;
    }
`

export const CreateAccountHelpWrapper = styled.div`
    max-width: 1200px;
    background-color: #fff;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 20px;
    min-height: calc(100vh - 117px);
`

export const CreateAccountHelpTitle = styled.h1`
    font-size: 2.5rem;
    color: #b3a25d;
    margin: 0;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 40px;
`

export const CreateAccountContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    text-align: start;
    span {
        font-size: 18px;
    }
    b {
        cursor: pointer;
    }
    b:hover {
        text-decoration: underline;
    }
    img {
        max-width: 400px;
        height: 100px;
    }
`