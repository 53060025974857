import styled from "styled-components"

export const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    align-items: center;
    min-height: 70vh;
    justify-content: center;
    gap: 10px;
`