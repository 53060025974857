import React, {useEffect} from "react";
import Tabs from '../../components/Tabs';
// styled
import { AboutUsContainer, AboutUsWrapper, AboutUsTitle, AboutUsContent } from './styled'

const TermsConditions = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <Tabs tag={0} />
            <AboutUsContainer>
                <AboutUsWrapper>
                    <AboutUsTitle>Terms and Conditions</AboutUsTitle>
                    <AboutUsContent>
                        <span>
                            <strong>INTRODUCTION</strong></span>
                        <span>
                            This website, europacasino.com, and any mobile applications through which our Services are offered (together, the "<strong>Website</strong>") are operated by Universe Entertainment Services Malta Limited ( <strong>"we" </strong>or <strong>"us"</strong>), a company incorporated and registered in Malta (company number C-60452) whose registered office is at Level 3, Valletta Buildings, South Street, Valletta, VLT1103, Malta. We hold several remote operating licences from the Malta Gaming Authority (licence numbers: MGA/B2C/249/2013 (issued: August 1<sup>st</sup>, 2018)).</span>
                        <span>
                            <strong>THESE TERMS AND CONDITIONS</strong></span>
                        <span>
                            These terms and conditions (the "<strong>Terms &amp; Conditions</strong>") govern your access to and use of the Website and our Services. By accessing and using the Website and any of our Services (including by opening an Account with us), you acknowledge that you have read these Terms &amp; Conditions and you agree to be bound by these Terms &amp; Conditions. You also agree to the use of electronic communications in order to enter into contracts, and you waive any rights or requirements under applicable laws or regulations in any jurisdiction which require a signature by hand, to the extent permitted by law.</span>
                        <span>
                            Please read these Terms &amp; Conditions carefully, as they form a legally binding contract between you and us. Please make sure you fully understand the contents of this contract. If you have any doubts about any of your rights and obligations resulting from entering into this contract, please consult legal counsel in your jurisdiction.</span>
                        <span>
                            If you do not agree to be bound by these Terms &amp; Conditions, please do not use the Website or our Services.</span>
                        <span>
                            <strong>CONTACTING US</strong></span>
                        <span>
                            You can contact our Customer Services team at <a href="mailto:support@vivaslots.co.za">support@vivaslots.co.za</a>.</span>
                        <span>
                            <strong>RULES AND ADDITIONAL TERMS</strong></span>
                        <span>
                            These Terms &amp; Conditions include and incorporate the following additional terms:</span>
                        <span>
                            · Product specific rules of any game you play ( <strong>"Rules"</strong>), as set out in the game itself or in the "Help" section, which govern particular games, events, markets and/or products and provide product-specific detail.</span>
                        <span>
                            · Promotional Terms, which apply in respect of promotions, bonuses and special offers (including any wagering requirements) related to the Services from time to time. Please note, not all players will be entitled to receive all promotions, bonuses and special offers available via the Website from time to time;</span>
                        <span>
                            · Download Terms, which you will be required to agree to in order to download certain Software from the Website from time to time; and</span>
                        <span>
                            · Our <a href="/privacy-and-security"> Privacy Policy</a>, which sets out the terms on which we process any personal data we collect from you or that you provide to us.</span>
                        <span>
                            If there is a conflict between these Terms &amp; Conditions and any of the Rules, any Promotional Terms, the Download Terms and/or the Privacy Policy, the provisions of, as applicable, the Rules, any Promotional Terms, the Download Terms and/or the Privacy Policy shall to that extent prevail over these Terms &amp; Conditions.</span>
                        <span>
                            It is your responsibility to ensure that you understand the Rules of the games in the Online Casino and your use of online gaming in general before your participation in such.</span>
                        <span>
                            <strong>DEFINITIONS</strong></span>
                        <span>
                            The following words and terms, when used in these Terms &amp; Conditions, shall have the following meanings:</span>
                        <span>
                            <strong>"Account"</strong> : a personal single account pertaining to one domestic household address, opened by you and maintained with us to enable you to enable you to deposit and wager on the Website or otherwise use the Services;</span>
                        <span>
                            <strong>"Applicable Laws"</strong> : any laws, rules and regulations which apply to you in the country in which you reside, or, if different, from which you are conducting activities with us, including (but not limited to) those relating to online gambling, your access to or use of the Services and/or the Website, and your making deposits and receipt of any withdrawal;</span>
                        <span>
                            <strong>"Download Terms"</strong> : the additional terms and conditions which you will be required to agree to in order to download certain Software from time to time;</span>
                        <span>
                            <strong>"Deposits"</strong> : any transfer of funds from you to your Account either directly or through a Payment Provider;</span>
                        <span>
                            <strong>"Gambling Transaction"</strong> : a bet, wager, stake or similar transaction placed on the Website or by otherwise using our Services;</span>
                        <span>
                            <strong>"Online Casino"</strong> : our Internet gaming system on the Website and related services and gaming activities as offered and listed at <a href="http://europacasino.com/">http://europacasino.com</a>, including but not limited to, online casino and/or any other games, where applicable;</span>
                        <span>
                            <strong>"Payment Provider"</strong> : a third-party payment processing company;</span>
                        <span>
                            <strong>"Prohibited Jurisdictions"</strong> : as stated in Section ‎9 herein, and amended from time to time;</span>
                        <span>
                            "<strong>Promotional Terms</strong>": separate terms and conditions which may apply in respect of promotions, bonuses and special offers (including any wagering requirements) related to the Services from time to time;</span>
                        <span>
                            <strong>"Services"</strong> : the services of an Online Casino offered by us from time to time;</span>
                        <span>
                            <strong>"Software"</strong> : the software that is required to be downloaded, accessed or otherwise utilised by you from the Website for the purpose of enabling you to participate in the Services, including any related program or data file and any related documentation, and including any enhancements, modifications, additions, translations or updates to such software;</span>
                        <span>
                            <strong>"Stake"</strong> : any monies wagered by a holder of an Account in play-for-real on the Online Casino;</span>
                        <span>
                            <strong>"Winnings"</strong> : any winnings payable to you following a successful Gambling Transaction;</span>
                        <span>
                            "<strong>Withdrawable Funds</strong>": the total funds in your Account (including all unspent deposits and winnings from wagers made with your deposits) excluding any funds that are subject to a restriction (including wagering requirements or other promotional play restrictions); and</span>
                        <span>
                            <strong>"Withdrawal"</strong> : any transfer of funds from your Player Account to yourself either directly or through a Payment Provider.</span>
                        <span>
                            <strong>YOUR REPRESENTATIONS</strong></span>
                        <span>
                            1. By opening an Account with us, you warrant and represent to us that:</span>
                        <span>
                            a) you have understood and accepted these Terms &amp; Conditions (including any additional Rules and/or other terms that may apply to you, as described in the "RULES AND ADDITIONAL<strong> </strong>TERMS" section above;</span>
                        <span>
                            b) you are bound by the provisions of these Terms &amp; Conditions (including any additional Rules and/or terms that may apply to you, as described in the "RULES AND ADDITIONAL<strong> </strong>TERMS" section above;</span>
                        <span>
                            c) you are 18 years or the age at which gambling activities are legal under Applicable Laws that applies to you, whichever is greater;</span>
                        <span>
                            d) you are not restricted by limited legal capacity;</span>
                        <span>
                            e) you are acting on your own behalf and not on behalf of anyone else. You are personally registering; no proxy or appointee can register for you;</span>
                        <span>
                            f) all information which you give, or have given, to us in the registration process is accurate as at the date of registration and you will not provide any information or make any statement to us which is untrue, false, incorrect, incomplete or misleading. It is your responsibility to inform us of any changes to your registration details;</span>
                        <span>
                            g) you are not depositing funds originating from criminal and/or unlawful and/or unauthorized activities, including but not limited to fraud or money laundering, and you are not otherwise conducting criminal and/or unlawful and/or unauthorized activities and/or intending to use the Account in connection with such activities nor will you allow any other person to use your Account for such activities;</span>
                        <span>
                            h) you are not colluding, or attempting to collude, or intending to participate, directly or indirectly, in any collusion scheme with any other player in the course of any game you play, or will play, on the Website;</span>
                        <span>
                            i) You acknowledge and agree that your Account is not a bank account and is therefore not insured, guaranteed, sponsored or otherwise protected by any deposit or banking insurance system or by any other similar insurance system of any other jurisdiction, including but not limited to your local jurisdiction. Furthermore, the Account does not bear interest on any of the funds held in it.</span>
                        <span>
                            j) You will not commit any acts or engage in any conduct that is or that could be reasonably expected to be damaging to our reputation or to the reputation of the Software Provider (as defined below) or any other related service providers;</span>
                        <span>
                            k) the debit card or credit card details supplied by you in the registration process are yours as the registered account holder and the relevant card has not been reported as lost or stolen;</span>
                        <span>
                            l) you will not sell, transfer and/or acquire Accounts to/from other players nor transfer funds amongst Accounts.</span>
                        <span>
                            m) you are not classified as a compulsive gambler, and have not previously self-excluded from this Website and/or any other gambling website;</span>
                        <span>
                            n) you have not previously held an Account with us or with any other online gaming operator which was suspended or terminated either by us or by such other online gaming operator, and you have not in the past charged back any monies via any Account;</span>
                        <span>
                            o) you are not an officer, director, employee, consultant or agent of one of our companies, or of our suppliers or vendors or their group companies, and you are not a relative of anyone who falls into these categories (for the purpose of this paragraph, the term "relative" means spouse, partner, parent, child or sibling). In the event that you breach this restriction, one of the actions that will be taken against you is that you will not be entitled to any of your Winnings;</span>
                        <span>
                            p) you understand that we shall be entitled to inform relevant authorities, other online operators, other online service providers and banks, credit card companies, electronic payment providers or other financial institutions of your identity and of any suspected unlawful, fraudulent or improper activity, and you will cooperate fully with us to investigate any such activity.</span>
                        <span>
                            q) you are solely responsible for complying with Applicable Laws and will not access the Website or our Services from a Prohibited Jurisdiction. We do not intend for anyone to use the Services and/or the Website where such use is illegal. The availability of the Services and/or the Website does not constitute an offer or invitation by us to use the Services and/or Website in any place in which such use is illegal. Use of the Services is forbidden wherever prohibited by any Applicable Laws and any Winnings derived from such forbidden use will be void. You accept sole responsibility for determining whether your use of our Services is legal under any Applicable Laws. Please consult legal counsel in the applicable jurisdiction if you have any doubts about the legality of your access to and use of the Services and/or the Website, or the making of Deposits and receipt of any Winnings, under any Applicable Laws. We make no representation or warranty with respect to the legality or otherwise of (a) the access to and use of the Services and/or the Website, and (b) the making of Deposits and receipt of any Winnings from it, under any Applicable Laws.</span>
                        <span>
                            r) you will not access any other account to use any of our Services or products from which you have self-excluded, during the period of any such self-exclusion and self-protection mechanisms stipulated by the applicable regulation.</span>
                        <span>
                            s) you are fully aware of the fact that there is a risk of losing money by wagering on our Website and using our Services and you accept full responsibility for such losses.</span>
                        <span>
                            <strong>RESPONSIBLE GAMBLING</strong></span>
                        <span>
                            2. As a responsible operator, we recognise that gambling can become a problem for some users. Consequently, we provide users with the facility to exclude themselves from using the Website and our Services or to use other responsible gambling tools that we offer, such as time-outs or setting financial limits in relation to their gambling.</span>
                        <span>
                            <strong>Self-Exclusion</strong></span>
                        <span>
                            3. You can instruct us to exclude you from placing Gambling Transactions and using our Services for a specified or unspecified period of time: (i) by contacting Customer Services ( <a href="/help/contact-us"> please click here for our contact details</a>); or (ii) online via the "My Account" tab. In asking us to exclude you from the Website and our Services, you acknowledge and agree that we will block your Account from placing Gambling Transactions for the period requested, starting as soon as possible after receipt of your request (and, in any event, by no later than the next time you try and log in). The self-exclusion will be applied to all websites operated by us.</span>
                        <span>
                            4. During a period of self-exclusion, you will not be able to place Gambling Transactions or otherwise access your Account. When you self-exclude, we will attempt to return your Withdrawable Funds to you. If you wish to reactivate your Account, you may only do so by contacting our Customer Services team (<a href="/help/contact-us"> please click here for our contact details</a>) and requesting in writing that the self-exclusion be lifted.</span>
                        <span>
                            5. A notice with a request to reduce the maximum limits set by you will take effect immediately and a notice with a request to increase the maximum limits, will take effect after a "cooling off" period of seven (7) days.</span>
                        <span>
                            <strong>Setting Financial Limits</strong></span>
                        <span>
                            6. As part of our commitment to responsible gambling and in line with our regulatory obligations, you have the option to set a limit on your Account, in connection with the amount of you wager within a specific period of time, the amount of losses you may incur within a specified period of time and the amount of time you may play in one session. To set such a limit, please either contact Customer Services (<a href="/help/contact-us"> please click here for our contact details</a>) or select the option on the Website through the "My Account" link, setting out your specific limits.</span>
                        <span>
                            7. If you wish to change or revoke the limit set by you, you will need contact our Customer Services team (<a href="/help/contact-us"> please click here for our contact details</a>) and provide us a notice in writing. A notice with a request to reduce the maximum limits set by you will take effect immediately and a notice with a request to increase the maximum limits, will take effect after a "cooling off" period of seven (7) days.</span>
                        <span>
                            8. Any deposit limit that you set will only be applied to this Website. It will not apply to other websites operated by us.</span>
                        <span>
                            <strong>PROHIBITED JURISDICTIONS</strong></span>
                        <span>
                            9. You may not use the Website and/or the Services if you are located in a Prohibited Jurisdiction. The fact that the Website is accessible in a Prohibited Jurisdiction, or that the Website is provided in the official language of a Prohibited Jurisdiction, shall not be construed as condoning the use of the Website in such Prohibited Jurisdiction. Any attempt to circumvent this restriction (for example, by using a VPN, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your location, citizenship, or place of residence, or by making bets or wagers using the Website through a third party or on behalf of a third party located in a Prohibited Jurisdiction) is a breach of these Terms &amp; Conditions and may constitute a criminal offence under Applicable Laws. If we discover, or we have reasonable grounds to suspect, that you are accessing the Website or the Services from a location in any of the Prohibited Jurisdictions, this will result in us closing your Account (without entitlement to any Winning), without any obligation to provide you with advance notice, and providing the applicable details to any relevant authorities, and you shall compensate us for any damage or loss resulting therefrom.</span>
                        <span>
                            THE PROHIBITED TERRITORIES ARE AS FOLLOWS: AFGHANISTAN, ALAND ISLANDS, ANTIGUA AND BARBUDA, AUSTRALIA, BAHAMAS, BARBADOS, BELGIUM, BELIZE, BULGARIA, CHINA, COLUMBIA, CUBA, CYPRUS, CZECH REPUBLIC, DENMARK, ESTONIA, DOMINICA, FAROE ISLANDS, FINLAND, FRANCE AND ITS TERRITORIES, GREECE, GREENLAND, GRENADA, GUYANA, HAITI, HUNGARY, HONG KONG, IRAN, IRAQ, ISRAEL, ITALY, JAMAICA, LATVIA, LIBYA, LITHUANIA, MACAU, MONTSERRAT, NETHERLANDS ANTILLES, POLAND, PORTUGAL, REPUBLIC OF SERBIA, ROMANIA, SINGAPORE, SLOVAKIA, SLOVENIA, SPAIN, ST. KITTS AND NEVIS, ST. LUCIA, ST.VINCENT, SUDAN, SURINAME, SWEDEN, SWITZERLAND, SYRIA, THE PHILIPPINES, THE NETHERLANDS, TRINIDAD AND TOBAGO, TURKEY,UKRAINE, UNITED KINGDOM, USA AND ITS TERRITORIES.</span>
                        <span>
                            <strong>YOUR ACCOUNT</strong></span>
                        <span>
                            <strong>Opening an Account</strong></span>
                        <span>
                            10. In order to enter into a Gambling Transaction and/or use the Services, you will need to open an Account with us on the Website. You may only open one (1) Account on the Website, for which you will register using your own personal information, as detailed below; duplicate accounts (as well as any account which are suspected to be related) are prohibited and will be closed (and Withdrawable Funds, if any, returned). We do not operate a credit facility, and you may only enter into Gambling Transactions with funds held in your Account.</span>
                        <span>
                            11. When registering an Account, you will be required to provide certain personal information, including your name, date of birth, address, telephone number and email address. All information supplied by you must be accurate and complete in all respects. We will comply with all applicable data protection laws in respect of the personal information you supply to us and will only use your personal information in accordance with our <a href="/privacy-and-security"> Privacy Policy</a>.</span>
                        <span>
                            <strong>Payment Methods</strong></span>
                        <span>
                            12. You can have up to ten (10) active cards (debit or credit) on your Account. If a card registered to your Account is lost or stolen, then you should notify Customer Services immediately (<a href="/help/contact-us"> our contact details can be found here</a>).</span>
                        <span>
                            <strong>Age </strong></span>
                        <span>
                            13. You may only use the Website and/or the Services if you are 18 years of age or older, or the age at which gambling activities are legal under Applicable Laws that applies to you, whichever is greater. It is illegal for anyone under the age of 18 to open an Account and/or to place Gambling Transactions on our sites (including the Website). We will forfeit all Gambling Transactions and return all deposits to anyone who we discover is under the required legal age.</span>
                        <span>
                            <strong>"Know your Customer"</strong></span>
                        <span>
                            14. By accepting these Terms &amp; Conditions, you agree provide us with all information requested by us and necessary for the operation of your Account and to comply with our regulatory obligations, including, but not limited to, proof of identity, notarized documentation, proof of address, utility bills, bank details, bank statements and bank references. You shall provide us with any such requested documents immediately upon request and not later than within a reasonable time of response.</span>
                        <span>
                            15. By accepting these Terms &amp; Conditions, you agree that we are entitled to conduct any necessary identification, credit and other verification checks that we may reasonably require and/or are required of us under Applicable Laws and regulation or by regulatory authorities.</span>
                        <span>
                            16. Until such time the requested information has been provided, we are entitled to restrict your Account in any manner that we may reasonably deem appropriate, including by preventing you from using your Account until the verification checks are completed satisfactorily.</span>
                        <span>
                            17. If we make an ID verification request in line with our regulatory obligations, until such time as the verification has been adequately concluded, any Withdrawal request you make will be considered pending. Once we have verified your identity, the Withdrawal will then be actioned. The ID verification required by us is as follows:</span>
                        <span>
                            a) photographic ID (a copy of your passport or driver's licence showing your name and date of birth);</span>
                        <span>
                            b) a copy of the front and the back of the credit or debit card you have registered and used on your Account (you need only show the name, first six digits and last four digits and expiry date; all other details may be obscured); and</span>
                        <span>
                            c) proof of address (for example, a copy of a bank statement or utility bill that shows the name and address registered on your Account and is dated during the previous three months) together with such other information as we may reasonably require.</span>
                        <span>
                            <strong>Use of your Account</strong></span>
                        <span>
                            18. When you open an Account, you will be required to choose a unique username and password. We only allow access to our Services through secured networks using encryption of the username and password. You cannot use our Services without passing our customer security login.</span>
                        <span>
                            19. You must keep your username and password secure and confidential and not share it with anyone else. You must not allow any other person to use your Account. Every person whom identifies him/herself by entering your correct username and password is assumed by us to be you and you accept that any actions taken using your Account will be considered to have been taken by you and any Gambling Transactions entered into using your Account will be considered valid and you will be solely responsible for them. We will not be liable if you disclose your username and password to someone else and we will not be responsible for any third-party access to your Account and under no circumstances shall we be liable for any losses incurred by you as a result of misuse of your username and password by any person or for any unauthorized access to your Account.</span>
                        <span>
                            20. You may not use anyone else's username or password, and you will not use any other person's Account or means of payment to access or use the Services or the Website or the Software.</span>
                        <span>
                            21. You must keep your registration and Account details up-to-date. If any of your Account details change you should notify us immediately, as failure to do so may result in transactions being declined by your financial institution. This can be done by contacting our Customer Services team (<a href="/help/contact-us"> please click here for our contact details</a>). We are not responsible for transactions which are declined as a result of incorrect details where you have not notified us of any changes.</span>
                        <span>
                            22. If you forget your username and/or password or have reason to believe that someone else is aware of your password, please contact our Customer Services team immediately (<a href="/help/contact-us"> please click here for our contact details</a>).</span>
                        <span>
                            23. We are using advanced methods for the encryption of the username and password information, and any other sensitive information transferred to and from the client application and our servers, thus securing you and us against manipulation attempts by a third party. You will not break in, access or attempt to break in or access or otherwise by-pass our security. If we suspect that you have attempted to or may be attempting to break in, access or otherwise bypass our security or the Software, we will be entitled to terminate with immediate effect your access to our Services and/or have your Account blocked, and we reserve the right to inform the applicable authorities.</span>
                        <span>
                            24. You can monitor all Gambling Transactions made using your Account by clicking on the "History" button when logged in. You are responsible for all Gambling Transactions made using your Account.</span>
                        <span>
                            <strong>Deposits and withdrawals</strong></span>
                        <span>
                            25. You agree that any financial transactions (e.g. deposits, withdrawals) on your Account (from you to us and vice versa) will be handled by us directly or through a Payment Provider chosen by you (subject, however, to Section ‎42 below) or any third party.</span>
                        <span>
                            26. Once your Account has been opened, you will be able to deposit funds to it by requesting an electronic transfer of funds using any of the means set out in the Cashier section on the Website. Otherwise, you may make Deposits solely by any of the following payment methods:</span>
                        <span>
                            a) by a credit card in good standing, which is registered in your name, acceptable by us at our sole discretion;</span>
                        <span>
                            b) by a debit card in good standing, which is registered in your name, acceptable by us at our sole discretion;</span>
                        <span>
                            c) by a payment pre-authorized by us;</span>
                        <span>
                            d) through a Payment Provider acceptable to us;</span>
                        <span>
                            e) by providing us with a valid, money order or wire transfer; or</span>
                        <span>
                            f) by such other method deemed acceptable to us from time to time, at our sole discretion.</span>
                        <span>
                            27. For Deposits and Withdrawals utilizing approved Payment Methods the time frames detailed in our <a href="/casino/payment">Payments page</a> apply.</span>
                        <span>
                            28. Each request made by you to deposit funds in your Account shall constitute a valid authorisation for us, or the Payment Provider as applicable, to transfer the amount specified in your request to your Account.</span>
                        <span>
                            29. It is clarified that any use by You of the services of a Payment Provider will be subject to the terms and conditions of use prescribed by such Payment Provider. This, however, will not derogate from your obligations to us under these Terms &amp; Conditions.</span>
                        <span>
                            30. Communication errors do occasionally happen. Deposits are confirmed only when received on our servers. Should there be a break in communication after You make a Deposit and it has been received on our servers it will still be considered valid. You will be informed that it has been accepted once communication is re-established at which time the Deposit may have been already utilized. You will receive information about the outcome of the Deposit placed. If the Deposit offer is not received at our servers then it will not stand, and the Deposit amount will not have been taken from your Account.</span>
                        <span>
                            31. We will be entitled to determine minimum and maximum amounts to be deposited in an Account, in our discretion.</span>
                        <span>
                            32. Any Deposit that is requested by you is non-reversible and non-refundable and does not generate interest.</span>
                        <span>
                            33. We do not guarantee that there will not be any delay in the processing or receipt of Deposits or Withdrawals.</span>
                        <span>
                            34. You agree to fully pay any and all payments and charges due to us or to Payment Providers in connection with your use of the Services. You further agree not to make any charge-backs or renounce or cancel or otherwise reverse any of your Deposits, and in any such event you will refund and compensate us for such unpaid Deposits including any expenses incurred by us in the process of collecting your Deposit.</span>
                        <span>
                            35. We are entitled to determine whether the funds that you have requested to be credited to your Account are available upon receiving confirmation from the relevant Payment Provider in accordance with such Payment Provider's approval procedures for such transactions to be reconciled, final, complete and cleared. Only when your Deposits are reconciled and cleared by the relevant Payment Provider will your Account be credited with the funds, unless stated differently.</span>
                        <span>
                            36. You must notify us if any sum is incorrectly credited to your Account. We are entitled to reverse such credits and/or recover such sums from you if you have withdrawn them. Any Gambling Transactions entered into using improperly credited sums may be cancelled by us and any winnings made from these Gambling Transactions will be reversed.</span>
                        <span>
                            37. You acknowledge that you may, from time to time, upon our request, be required to provide additional details in respect of the transaction made by you. Please note that for all cumulative Withdrawals of €2,000, your identity and address will be verified as stated in Sections ‎14-‎17.</span>
                        <span>
                            38. You accept that all Gambling Transactions may be checked to prevent money laundering and that any Gambling Transactions made by you which we deem suspicious, may be reported to the appropriate authorities.</span>
                        <span>
                            39. Any Stake made by you will be treated by us as an authorization to debit your Account. Provided that the balance of your Account at the time that the Stake is made is greater than or equal to the amount of the Stake, we will debit from your Account in the amount of the Stake.</span>
                        <span>
                            40. Subject to Sections ‎41 and ‎43 herein, you may withdraw any Withdrawable Funds in your Account at any time by submitting a withdrawal request (either online or by contacting <a href="/help/contact-us"> Customer Support</a>). On receipt of any such withdrawal request, when such withdrawal request is approved by us, we will process the relevant funds to you (less a processing fee, charges or other required amount (if applicable)) using the payment method with which those funds were originally deposited (unless we agree otherwise).</span>
                        <span>
                            41. You agree that we will have the right to withhold any Withdrawals, in the event the we believe or suspect that you may be engaging in or have engaged in fraudulent, collusive, unlawful or improper activity, or if we are concerned about the operation of your Account or the request for Withdrawal. In such cases, we may commence and/or be involved in and/or assist any investigation into such circumstances, and you agree to assist and cooperate with any such investigation.</span>
                        <span>
                            42. We will attempt to accommodate your request regarding the payment method and currency of payment of your Withdrawal. This, however, cannot be guaranteed. Therefore, we may process and pay Withdrawals in a different payment method than the one requested by you, such as through different Payment Providers or wire transfer. Similarly, in certain cases, the currency of your Withdrawal may not be the currency in which your deposit was made or that was otherwise requested by you.</span>
                        <span>
                            43. You are aware of and agree that players are allowed to withdraw not more than €20,000 per month. If the amount to be withdrawn is greater than €20,000, the remaining amount will be placed back to the player's Account, allowing the player to withdraw additional funds the following month in accordance with this Section. The foregoing applies also to Withdrawals of progressive jackpots. Withdrawals depend on all conditions specified above and the verification process as set forth in Section ‎59 herein.</span>
                        <span>
                            44. We have a number of controls and checks that must be completed before any withdrawal request is processed. These checks are part of our ongoing commitment to maintaining the security of our customers' funds. All withdrawals requests are therefore only processed after the expiry of a set period of time from the withdrawal request being made.</span>
                        <span>
                            45. You are aware of, and agree, that the maximum amount that can be won, in a single casino game action is $500,000 / €500,000 / CHF500,000 / CAD500,000 / SEK5,000,000 / RUB15,000,000 / ZAR5,000,000. The foregoing does not apply to progressive jackpot winnings.</span>
                        <span>
                            46. We will not typically charge you a fee to deposit or withdraw funds. However, if we suspect that you are misusing the deposit and/or withdrawal functionality, we reserve the right to charge you a fee to reflect any costs incurred by us in connection with such misuse.</span>
                        <span>
                            47. Deposits and Withdrawals via bank transfer to international bank accounts may include bank charges, which may vary according to the amount of money transferred from or to your bank account and you will be responsible to bear the cost of such.</span>
                        <span>
                            48. Deposits made through Neteller and/or Moneybookers payment services will not be eligible to receive bonuses.</span>
                        <span>
                            <strong>Fees and Chargebacks</strong></span>
                        <span>
                            49. When you make a Deposit by debit card or credit card, some financial institutions categorise gambling deposits as "cash" and may levy a fixed and/or interest charge from the date of payment. It is your responsibility to settle this charge.</span>
                        <span>
                            50. If you frequently make deposits into and withdrawals from your Account without placing a reasonable level of Gambling Transactions, we reserve the right to charge you a fee (debited from your Account) for depositing. We will provide you with advance notice before processing this debit.</span>
                        <span>
                            51. If relevant in your jurisdiction, it is your responsibility to account to the relevant tax authorities for any Winnings you receive via your Account. We are not liable to account to any tax authority for any of your personal taxes. You are fully responsible for all taxes, fees and other costs incidental to and arising from any Winnings resulting from use of our Services and Website. You will indemnify and reimburse us for any costs, expenses or losses that may be caused to us because of any claim or demand made by any governmental or other authority, with regard to tax withholding obligations or similar obligations to which we may be subject in connection with processing your Withdrawal requests.</span>
                        <span>
                            52. If, for whatever reason, there is a chargeback request to your Account, you accept and acknowledge that we may restrict your Account while we investigate and resolve the matter. During such period, any monies due or requested will be withheld.</span>
                        <span>
                            <strong>Closing your Account</strong></span>
                        <span>
                            53. If you wish to close your Account, please contact Customer Services (<a href="/help/contact-us"> please click here for our contact details</a>). You can do so at any time, provided that your Account is not expected to become in debit/deficit pursuant to any liabilities incurred through your gaming activities. On closing your Account, we will return the balance to you in accordance with the provisions of the "Deposits and withdrawals" section above.</span>
                        <span>
                            54. We shall be entitled to close your Account and void all unsettled Gambling Transactions in the following circumstances:</span>
                        <span>
                            a) in accordance with the "Cheating, Collusion, Fraud and Criminal Activity" section below if an investigation concludes that you have used the Website and/or the Services in a fraudulent manner or for illegal, unlawful and/or improper purposes; and/or</span>
                        <span>
                            b) immediately if we are requested to do so by law enforcement or regulatory authorities or by a court order; and/or</span>
                        <span>
                            c) by giving you reasonable notice if you don't supply any information reasonably requested by us; and/or</span>
                        <span>
                            d) immediately if we discover or have reason to believe that you have breached, or are breaching, these Terms &amp; Conditions.</span>
                        <span>
                            55. Where we close your Account for a breach of these Terms &amp; Conditions, we may withhold and/or retain any and all amounts in such Account until such time as we have investigated the relevant breach. Upon conclusion of our investigation, we will return any amounts in your Account to you other than where we have received a valid request from any law enforcement or regulatory authority, or a court order, which requires us to withhold the funds in your Account.</span>
                        <span>
                            <strong>Gambling Transactions</strong></span>
                        <span>
                            56. We reserve the right to decline part of a Gambling Transaction if your Account is restricted in our reasonable discretion. All Gambling Transactions are entered into at your own discretion. Gambling Transactions will not be confirmed if there are insufficient funds in your Account.</span>
                        <span>
                            57. We will only accept Gambling Transactions made online (including via mobile phone or mobile applications). Once a Gambling Transaction has been accepted by us, you cannot cancel the Gambling Transaction. It is your responsibility to ensure that all details of the Gambling Transaction are correct.</span>
                        <span>
                            58. Winnings from settled Gambling Transactions will be added to the balance of your Account. Any funds credited in error are not available for use and we reserve the right to (i) void any Gambling Transaction placed using such funds and (ii) withdraw the relevant amount and reverse the Gambling Transaction, whether at the time or retrospectively.</span>
                        <span>
                            59. We will be entitled to withhold any pay-out or Winning amount until the identity of the winning person is verified to our satisfaction, in order to ensure that payment of any Winnings is being made to the correct person. For this purpose, we will further be entitled, at our sole discretion, to require that you provide us with a notarized ID or any equivalent certified ID according to the applicable law of your jurisdiction or otherwise.</span>
                        <span>
                            60. If we suspect fraud or fraudulent activity on your part or if any of your payments are charged back, and in the event of suspected card counting in any of the live games, we will have the right to withhold any amount from your account which is exceeding your original deposit, and if deemed necessary by us, to initiate any legal proceedings to collect any payments owed by you.</span>
                        <span>
                            61. We reserve the right, at our sole discretion, to offer and advertise from time to time promotions, bonuses or other special offers and each such offer will be subject to specific terms and conditions which will be valid for a limited period of time. We will have the right to withhold or otherwise decline or reverse any pay-out or Winning amount in the event that we suspect you are abusing or attempting to abuse any of the following: (i) bonuses; (ii) other promotions; or (iii) specific rules determined in respect of an existing game or a new game. It is clarified that we may determine that such an abuse has taken place also if your activity in our Online Casino, by itself, is not abusive, but your activities in other online casinos in addition to our Online Casino, taken, as a whole, constitute such abuse of our bonuses, promotions or rules.</span>
                        <span>
                            <strong>Inactive Accounts</strong></span>
                        <span>
                            62. If you have not placed a Gambling Transaction using your Account for a continuous period of 180 days (the " <strong>Grace Period</strong>"), your Account will be deemed inactive. Once your Account has been deemed inactive, we may,upon giving you 30 day notice, charge you a monthly maintenance fee of the higher of €5 (or currency equivalent) and 5% of the balance of your Account, from your account balance commencing on the last day of the Grace Period and then every thirty (30) days thereafter. We will continue to deduct this fee until the balance of your Account is zero or you place a Gambling Transaction. All fees and charges are subject to change from time to time.</span>
                        <span>
                            63. If we continue to deem your account inactive for a period of twelve (12) consecutive calendar months, in order to safeguard your monies, we may withhold any remaining balance in your Account and close your Account, while continuing to charge the applicable maintenance fee. You may contact us to reclaim any such withheld monies within thirty (30) months from the last date you placed a Gambling Transaction, except for any and all maintenance fees that you have incurred. If we will not be able to contact you, the remaining balance will be remitted to the Malta Gaming Authority.</span>
                        <span>
                            <strong>Errors or Omissions</strong></span>
                        <span>
                            64. A number of circumstances may arise where errors are made in respect of Gambling Transactions placed or payments made by us ( <strong>"Error"</strong>). An Error could be the result of a mistake by an individual or a defect or fault with our systems and/or the Software. We will not be liable for any Error (however caused), including (but not limited to) where:</span>
                        <span>
                            a) we incorrectly calculate or pay out the amount of Winnings that are paid to you;</span>
                        <span>
                            b) an error occurs in a random number generator or pay tables included or used in any game or product; and/or</span>
                        <span>
                            c) caused by the failure of any hardware, Software, or communications systems.</span>
                        <span>
                            65. We reserve the right to correct any Error which has been identified and in the case of a fault, take steps as soon as practicable to remedy the problem and ensure that you are treated fairly according to the circumstances.</span>
                        <span>
                            66. Where it is not practicable for us to correct a Gambling Transaction which has been subject to an Error, we reserve the right to declare the relevant Gambling Transaction void and withhold payment of the relevant Winnings to you. Where we have paid Winnings to you which we can reasonably demonstrate were as a result of an Error, we reserve the right to claim back such Winnings from you and/or deduct them from your Account balance.</span>
                        <span>
                            67. You should inform us of any Error as soon as possible and should not seek to exploit an Error to generate Winnings. Where we can reasonably demonstrate that you have exploited an Error to generate Winnings we shall have no liability to you in respect of such Winnings. Where we have paid Winnings to you which we can reasonably demonstrate were as a result of you exploiting an Error, we reserve the right to claim back such Winnings from you and/or deduct them from your Account balance.</span>
                        <span>
                            68. Neither we, nor our employees, partners or suppliers, will be liable for, or be required to compensate you for, any loss that results from any Error by us.</span>
                        <span>
                            <strong>Cheating, Collusion, Fraud and Criminal Activity</strong></span>
                        <span>
                            69. If we discover, or have reasonable grounds to believe, that you are engaging, or have engaged, in one or more of the following activities, we will commence and/or be involved in and/or assist an investigation into such circumstances:</span>
                        <span>
                            a) cheating (including by counting cards or exploiting a game or game fault);</span>
                        <span>
                            b) colluding with others (including by working together to exploit a game or game fault, using "bots" or similar software, or manipulating the Software); and/or</span>
                        <span>
                            c) fraudulent behaviour or criminal activity (including money laundering, credit card fraud, chargeback risk or stolen alternative payment methods).</span>
                        <span>
                            you agree to assist and cooperate with any such investigation, and also agree that we may provide your personal information to a third party investigating your activity or Account (as set out in our <a href="/privacy-and-security"> Privacy Policy</a> ). While an investigation is ongoing, your Account will be frozen, you will not be able to place any Gambling Transactions and any Withdrawal request will be suspended. If the investigation concludes that you have not engaged in any of the foregoing activities, your Account will be re-activated and we will process any outstanding Withdrawal request. If the investigation concludes that you have engaged in any of the foregoing activities, we will close your Account and retain any funds to compensate the individual(s) affected by such activities.</span>
                        <span>
                            70. You should only deposit money into your Account for the purposes of entering into a Gambling Transaction. In circumstances where we reasonably believe that you are depositing money without any intention to enter into a Gambling Transaction, we may investigate your Account in accordance with the preceding paragraph.</span>
                        <span>
                            <strong>Our Rights</strong></span>
                        <span>
                            71. We reserve, at our sole discretion, the right to:</span>
                        <span>
                            a) refuse to register any applicant for registration on the Website or the Services;</span>
                        <span>
                            b) refuse to accept any Stakes or void any accepted Stakes for any reason, including, but not limited to, if we have reason to believe you are involved in any activity under the "Cheating, Collusion, Fraud and Criminal Activity" section and/or in the event of a breach of Applicable Laws; and</span>
                        <span>
                            c) change, suspend, remove, modify or add any game on the Online Casino.</span>
                        <span>
                            72. In the event of any dispute regarding a Stake or Winnings, our decision will be final and binding.</span>
                        <span>
                            73. Notwithstanding anything stated or implied to the contrary in these Terms &amp; Conditions, WE WILL NOT BE UNDER ANY OBLIGATION IN ANY SUCH CIRCUMSTANCES TO REFUND OR OTHERWISE REIMBURSE YOU FOR ANY OF THE FUNDS IN YOUR ACCOUNT.</span>
                        <span>
                            74. We may, at any time, set off any positive balances on your Player Account against any amount owed by you to us.</span>
                        <span>
                            75. We reserve the right to store all information gathered through player interaction, in accordance with our <a href="/privacy-and-security"> Privacy Policy</a>.</span>
                        <span>
                            <strong>USE OF THE WEBSITE</strong></span>
                        <span>
                            <strong>General</strong></span>
                        <span>
                            76. No warranty or representation, express or implied, is made as to the accuracy, timeliness, completeness or suitability of the information and materials contained on our Website nor as to the results obtained through its use. The information provided on the Website is not intended to amount to advice or recommendations and is provided for information purposes only. No reliance shall be placed on such information when entering into a Gambling Transaction.</span>
                        <span>
                            77. You are solely responsible for the supply and maintenance of all computer equipment, telecommunications networks and internet connection services that you require in order to access and use the Website. We shall not be liable in any way whatsoever for any loss or damage caused to your computer equipment, telecommunications network or internet connection service as a result of accessing or using the Services and/or the Website.</span>
                        <span>
                            78. You may only use the Website and/or the Services for the purpose of entering into Gambling Transactions with us and for your own private use. Any commercial exploitation of information from our Website is strictly prohibited.</span>
                        <span>
                            79. You must not use the Website and/or the Services for any purpose which is illegal, fraudulent, abusive, obscene, discriminatory, dishonest or inappropriate. You will fully compensate us for any and all losses, costs, damages and claims arising from any such activity.</span>
                        <span>
                            <strong>Availability of the Website</strong></span>
                        <span>
                            80. From time to time the Website may be unavailable for use by you due to maintenance of the Website and/or alteration of our products/Services. We may alter or amend the products/Services and/or undertake maintenance at any time or for any reason.</span>
                        <span>
                            <strong>INTELLECTUAL PROPERTY AND SOFTWARE</strong></span>
                        <span>
                            81. We are the owner or the licensee of all intellectual property rights in the Website, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</span>
                        <span>
                            82. You must not use any part of the materials on the Website for any commercial purposes without obtaining a licence to do so from us or our licensors.</span>
                        <span>
                            83. You may print off one copy, and may download extracts, of any page(s) from the Website for your personal reference only.</span>
                        <span>
                            84. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</span>
                        <span>
                            85. Our status (and that of any identified contributors) as the authors of material on the Website must always be acknowledged.</span>
                        <span>
                            <strong>Software</strong></span>
                        <span>
                            86. We do not accept any liability in respect of the Software. You agree that you are only permitted to use the Software for the purpose of using the Services and, save to the extent permitted by Applicable Law, for no other purpose. You will be required to accept the Download Terms in relation to any Software which you download (e.g. poker client, casino client).</span>
                        <span>
                            87. We hereby grant you a personal, non-exclusive, non-transferable right to use the Software for the sole purpose of using the Services and the Website, in accordance with the following provisions. You are not permitted to:</span>
                        <span>
                            a) install or load the Software onto a server or other network device or take other steps to make the Software available to any other person via any form of "bulletin board", online service or remote dial-in, applications service provider, internet services provider, timesharing arrangements, outsourcing services or bureau services;</span>
                        <span>
                            b) sub-license, assign, rent, lease, loan, transfer or copy the Software or your licence to use the Software, or make or distribute copies of the Software;</span>
                        <span>
                            c) interrupt or tamper with, or attempt to interrupt or tamper with, the operation of the Software or any information in any form which is included on the Website;</span>
                        <span>
                            d) use the Software for commercial purposes;</span>
                        <span>
                            e) translate, reverse engineer, decompile, disassemble, adapt, modify, create derivative works based on, or otherwise modify the Software;</span>
                        <span>
                            f) copy or translate any user documentation provided 'online' or in electronic format;</span>
                        <span>
                            g) enter, access or attempt to enter or access or otherwise bypass the applicable security system or interfere in any way (including but not limited to robots and similar devices) with the Website, or attempt to make any changes to the Software and/or any features or components thereof;</span>
                        <span>
                            h) flood the Website and/or the Software with information, multiple submissions or "spam";</span>
                        <span>
                            i) knowingly or negligently use any features which may affect the function of the Website and/or the Software in any way, for example by releasing viruses, worms, trojans, logic bombs or similar material that is malicious or harmful; or</span>
                        <span>
                            j) attack the Website via a distributed denial-of-service attack.</span>
                        <span>
                            88. You do not own the Software. The Software is owned and is the exclusive property of a licensor, a third-party software provider company (the <strong>"Software Provider"</strong>). The Software and accompanying documentation which have been licensed to us are proprietary products of the Software Provider and are protected throughout the world by copyright law and other intellectual property law. Your use of the Software does not give you ownership of any intellectual property rights in the Software or any other rights in or with respect to the Software, except for the right to use the Software as expressly provided in these Terms &amp; Conditions <strong>.</strong></span>
                        <span>
                            89. You acknowledge that a random number generator will determine the outcome of the games played on the Website and you accept the outcomes of all such games. You further agree that, in the unlikely event of a disagreement between the result that appears on the Software and the result that appears on the game server, the result that appears on the game server will prevail. You acknowledge and agree that our records will be the final authority in determining the circumstances of your participation in gaming activity on the Website and the results of this participation.</span>
                        <span>
                            90. The software is provided "as is" without any promises, warranties, conditions, undertakings or representations, express or implied, statutory or otherwise. We and the software provider, and all of our and its affiliates and related parties, hereby exclude all implied terms, conditions and warranties (including any warranty of merchantability, satisfactory quality and fitness for any particular purpose) and do not warrant that the software will meet your requirements, that the software will not infringe any third party's intellectual property rights, that the operation of the software will be error free or uninterrupted, that any defects in the software will be corrected, or that the software is virus-free.</span>
                        <span>
                            91. You hereby acknowledge that we have no control or responsibility over the use that you make of the Software. Loading and using the Software and accessing and using the Services by you is made at your own risk. NEITHER WE NOR THE SOFTWARE PROVIDER, NOR ANY OF OUR OR THEIR AFFILIATES AND RELATED PARTIES, SHALL HAVE ANY LIABILITY WHATSOEVER OF ANY KIND IN CONNECTION WITH SUCH USE.</span>
                        <span>
                            92. Neither we nor the software provider, or any of our or its affiliates and related parties, will be liable to you for any costs, expenses, losses or claims arising or resulting from communications or system errors occurring in connection with the settlement of accounts or other features or components of the software, or for any damage caused to your devices (pcs, mobile devices) from use of the software. In the event of such errors, we will further have the right, but not the obligation, to remove any relevant games from the software and the website and take any other action to correct such errors. Failure to notify us of any error that you are or should have been aware of may be considered to be a breach of these Terms &amp; Conditions.</span>
                        <span>
                            93. The Software may include confidential information which is secret and valuable to the Software Provider and/or to us. You are not entitled to use or disclose that confidential information other than strictly in accordance with these Terms &amp; Conditions.</span>
                        <span>
                            <strong>OUR LIABILITY</strong></span>
                        <span>
                            94. Nothing in these Terms &amp; Conditions affects our liability for death or personal injury arising from our negligence, nor our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which cannot be excluded or limited under applicable law. Your statutory rights remain unaffected and we shall use reasonable care when providing you services pursuant to these Terms &amp; Conditions.</span>
                        <span>
                            95. IN NO EVENT SHALL WE OR THE SOFTWARE PROVIDER, OR ANY OF OUR OR THEIR AFFILIATES AND RELATED PARTIES, BE LIABLE TO YOU FOR ANY DIRECT, CONSEQUENTIAL, INDIRECT, INCIDENTAL OR SPECIAL DAMAGE OR LOSS OF ANY KIND, INCLUDING WITHOUT LIMITATION LOSS OF BUSINESS, PROFITS, REVENUE, CONTRACTS OR ANTICIPATED SAVINGS, OR LOSS OR DAMAGE ARISING FROM LOSS, DAMAGE OR CORRUPTION OF ANY DATA, WHETHER OR NOT THE POSSIBILITY OF SUCH LOSS OR DAMAGE HAS BEEN NOTIFIED TO US AHEAD OF TIME.</span>
                        <span>
                            96. The Website and the Services (including all material and information displayed on or via the Website and the Services) are provided without any guarantees, conditions or warranties as to their accuracy. Save where otherwise set out in these Terms and Conditions, and to the extent permitted by Applicable Law, we, the Software Provider, and any of our affiliates and related parties, hereby expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity; and we exclude all liability for:</span>
                        <span>
                            a) any error made due to the input of incorrect information by you;</span>
                        <span>
                            b) any fraud, deception or misrepresentations by you;</span>
                        <span>
                            c) our decision not to accept a Deposit from you;</span>
                        <span>
                            d) any delay in receiving or accepting a Deposit by us or withholding a Withdrawal by us for the purpose of conducting identity verification procedures;</span>
                        <span>
                            e) use of your Account for purposes that may be considered illegal under applicable laws;</span>
                        <span>
                            f) any transactions on your Account which are conducted after the correct entry of your username and password, including any actions or transactions by an individual that uses your username and password;</span>
                        <span>
                            g) any unauthorized interception or use of data relating to you or your Account;</span>
                        <span>
                            h) any inability to use or access the Website for any reason;</span>
                        <span>
                            i) any cause over which we do not have direct control, including problems attributable to computer hardware or software (including computer viruses and including the Software), data transmission systems, telephone or other communications, or Internet service providers;</span>
                        <span>
                            j) the loss of any transactions caused by the loss or malfunction of any communications device used by yourself or any entity relaying information between you, us, or any other payment solution company;</span>
                        <span>
                            k) any undelivered e-mail communications;</span>
                        <span>
                            l) the quality or availability (or lack thereof) of the Website or the Services;</span>
                        <span>
                            m) any results of any acts of government or authority or any force majeure event;</span>
                        <span>
                            n) any losses that were not foreseeable to both parties when the contract between us was formed;</span>
                        <span>
                            o) any losses arising from your breach of these Terms &amp; Conditions;</span>
                        <span>
                            p) any losses which are not caused by a breach of these Terms &amp; Conditions on our part;</span>
                        <span>
                            q) any losses you incur resulting from failure by us to implement a self-exclusion agreement with you from time to time;</span>
                        <span>
                            r) business losses;</span>
                        <span>
                            s) the defamatory, offensive or illegal conduct of any other customer;</span>
                        <span>
                            t) any loss whatsoever arising from the use, abuse or misuse of Your Player Account or any of Our products and services and the corresponding Website;</span>
                        <span>
                            u) any loss incurred in transmitting information to the Website by the internet or by e-mail; and</span>
                        <span>
                            v) any failure on our part to interact with you where we may have concerns about your activities.</span>
                        <span>
                            97. Subject to the preceding paragraph, and other than in relation to the payment of Winnings lawfully won, our liability to you pursuant to these Terms &amp; Conditions shall not exceed:</span>
                        <span>
                            a) in the case of any liability which relates to a specific Gambling Transaction, the amount of the stake wagered; or</span>
                        <span>
                            b) in the case of any other matters, an aggregate of five thousand Euro (€5,000).</span>
                        <span>
                            98. We supply the Services for domestic and private use. If you use the Services for any commercial, business or re-sale purpose, we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</span>
                        <span>
                            99. Where we provide links to other websites, we do so for information purposes only and you use any such links at your own risk. We accept no responsibility for the content or use of any other websites or for the information contained on them.</span>
                        <span>
                            <strong>YOUR LIABILITY</strong></span>
                        <span>
                            100. You agree to fully compensate, defend and hold us (and our subsidiaries, employees, agents and/or partners) harmless from any claims, liabilities, costs, damages and expenses (including legal fees) that may arise as a result of: (a) your breach of these Terms &amp; Conditions; and (b) access and use of the Website, the Services or the Software by yourself or by anyone else using your username and password.</span>
                        <span>
                            <strong>TERM AND TERMINATION</strong></span>
                        <span>
                            101. This agreement is effective from the moment of acceptance by you and shall remain in force for an unlimited time, unless terminated in accordance with these Terms &amp; Conditions. For the avoidance of doubt, it is agreed that you are bound by this agreement if you use the Services or the Website or the Software in any way, including, but not limited to, initiating or making a Deposit through your Account or submitting your deposit details to us.</span>
                        <span>
                            102. Notwithstanding anything stated or implied to the contrary in these Terms &amp; Conditions, we may at any time, without prior notice to you and without derogating from our other rights under this Agreement, TERMINATE YOUR USE OF THE SERVICES AND BLOCK YOUR ACCOUNT if we suspect or believe that you are in breach of any of these Terms &amp; Conditions; or that you may be engaging in or have engaged in fraudulent, unlawful, or improper activity, including without limitation any violation of any applicable terms and conditions, money laundering or collusion with another Account holder; or that you are otherwise acting unlawfully.</span>
                        <span>
                            <strong>OTHER</strong></span>
                        <span>
                            <strong>Changes to the Terms &amp; Conditions</strong></span>
                        <span>
                            103. These Terms &amp; Conditions as well as our Privacy Policy are subject to change and we reserve the right to change them at any time.</span>
                        <span>
                            104. Following your initial acceptance of these Terms &amp; Conditions, we will notify you of any subsequent significant changes to the Terms &amp; Conditions before they come into effect, as further described in these Terms &amp; Conditions. However, you should check the Terms &amp; Conditions regularly for any changes as your continued use of the Website and the Services will be regarded as acceptance of the latest version of the Terms &amp; Conditions (including the Privacy Policy).</span>
                        <span>
                            105. Where we intend to make significant changes to the Terms &amp; Conditions we will notify you before they come into effect using one of the following methods:</span>
                        <span>
                            a) email (to the email address registered on your Account); and/or</span>
                        <span>
                            b) a notice/pop up on the Website.</span>
                        <span>
                            106. If you do not wish to accept any changes that have been made to these Terms &amp; Conditions, then you should stop using the Website and the Services. Any Stakes received but not settled prior to the introduction of the new Terms &amp; Conditions will be subject to the version of the Terms &amp; Conditions applicable at the time the bet or wager was received.</span>
                        <span>
                            <strong>Complaints</strong></span>
                        <span>
                            107. If you have any complaint with regards to the Website or any of our Services, please first contact our Customer Services team (using the contact details <a href="/help/contact-us"> here</a>) in order for this to be internally reviewed. All complaints will be processed and responded to in a timely and appropriate manner. We maintain records of complaints received and actions taken in response to the complaints.</span>
                        <span>
                            108. If, after our internal process has been fully exhausted, you remain unsatisfied as to the outcome of your complaint, you have a right to escalate this complaint to the Malta Gaming Authority at <a href="mailto:support.mga@mga.org.mt">support.mga@mga.org.mt</a> or the Betting Adjudication Service at <a href="https://www.madre-online.eu/file-a-claim/" target="_blank" rel="noreferrer">https://www.madre-online.eu/file-a-claim/</a>.</span>
                        <span>
                            <strong>Events beyond our control</strong></span>
                        <span>
                            109. We shall not be in breach of these Terms &amp; Conditions nor be liable for delay in performing, or failure to perform, any of our obligations under these Terms &amp; Conditions if such delay or failure results from events, circumstances or causes beyond our reasonable control.</span>
                        <span>
                            <strong>Waiver</strong></span>
                        <span>
                            110. Our failure or delay in enforcing or partially enforcing any term of these Terms &amp; Conditions shall not be interpreted as a waiver of any of our rights or remedies. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or any other right or remedy.</span>
                        <span>
                            <strong>Severance</strong></span>
                        <span>
                            111. If any provision of these Terms &amp; Conditions is held by any competent authority to be invalid, void, voidable, unenforceable or unreasonable in whole or in part, the validity of the other provisions of these Terms &amp; Conditions and the remainder of the provision in question shall continue in full force and effect.</span>
                        <span>
                            <strong>Entire Agreement</strong></span>
                        <span>
                            112. These Terms &amp; Conditions, including any document expressly referred to in them, represent the entire agreement between you and us and replace any prior agreement, understanding or arrangement between you and us. Both parties acknowledge that neither party has relied on any representation, undertaking or promise made by the other except as expressly stated in these Terms &amp; Conditions.</span>
                        <span>
                            <strong>Transfer of agreement</strong></span>
                        <span>
                            113. We may at any time assign or transfer any or all of our rights and obligations under these Terms &amp; Conditions. In particular, we may assign or transfer our rights and obligations to any purchaser of all or part of our business. We may also subcontract or delegate in any manner any or all of our obligations under these Terms &amp; Conditions to any third party or agent.</span>
                        <span>
                            114. These Terms &amp; Conditions are personal to you and you may not assign, sub-license or otherwise transfer in any manner whatsoever any of your rights or obligations under these Terms &amp; Conditions.</span>
                        <span>
                            <strong>Notices</strong></span>
                        <span>
                            115. You agree to receive communications from us in an electronic form. Electronic communications may be posted on the pages within the Website and/or the messages/help files of your client application, and/or delivered to your email address, as decided by us from time to time. All communications in electronic format will be considered to be "in writing" and to have been received no later than five business days after posting or dissemination, whether or not you have actually received or retrieved the communication. We reserve the right, but assume no obligation, to provide communications in paper format. Any correspondence you want to send to us in writing should be addressed to Universe Entertainment Services Malta Limited at Level 3, Valletta Buildings, South Street, Valletta, VLT1103, Malta. Note that any communications under this paragraph shall be governed by our Privacy Policy.</span>
                        <span>
                            116. Any notices required to be given in writing to us or any questions concerning these Terms &amp; Conditions should be addressed to: <a href="mailto:support@vivaslots.co.za">support@vivaslots.co.za</a>.</span>
                        <span>
                            <strong>Language</strong></span>
                        <span>
                            117. The English language version of these Terms and Conditions will prevail over any other language version issued by us.</span>
                        <span>
                            <strong>Governing law and jurisdiction </strong></span>
                        <span>
                            118. These terms and conditions are governed by laws of Malta. The competent courts in Malta will have exclusive jurisdiction in any matter arising from or related to these terms and conditions. However, this shall not prevent us from bringing any action in the court of any other jurisdiction for injunctive or similar relief.</span>
                        <span>
                            &nbsp;</span>
                        <span>
                            &nbsp;</span>
                        <span>
                            &nbsp;</span>
                        <span>
                            <strong>APPENDIX: END-USER LICENSE AGREEMENT</strong></span>
                        <span>
                            THIS WEBSITE IS OPERATING THE GAMING SOFTWARE PLATFORM OF PLAYTECH SOFTWARE LIMITED AND IT'S GROUP COMPANIES (THE "VENDOR") UNDER A LICENSE FROM VENDOR. A CONDITION TO YOUR DOWNLOADING OR OTHERWISE USING THE SOFTWARE (AS DEFINED BELOW) IS THAT YOU ENTER INTO THE FOLLOWING LEGALLY BINDING SUB-LICENSE AGREEMENT WITH US, WHICH GOVERNS YOUR USE OF THE SOFTWARE. PLEASE READ THIS AGREEMENT CAREFULLY TO MAKE SURE YOU FULLY UNDERSTAND ITS CONTENT. IF YOU HAVE ANY DOUBTS ABOUT YOUR RIGHTS AND OBLIGATIONS RESULTING FROM THE ACCEPTANCE OF THIS AGREEMENT, PLEASE CONSULT AN ATTORNEY OR OTHER LEGAL ADVISOR IN YOUR JURISDICTION.</span>
                        <span>
                            IMPORTANT: PERSONS LOCATED IN CERTAIN TERRITORIES, CURRENTLY INCLUDING THE UNITED STATES OF AMERICA AND ITS TERRITORIES, ISRAEL AND THE COUNTRIES SPECIFIED AS PROHIBITED, EXCLUDED OR SIMILAR AS PART OF THE TERMS AND CONDITIONS AND/OR LIST ON THE WEBSITE, IN EACH CASE AS SUPPLEMENTED FROM TIME TO TIME (THE "PROHIBITED JURISDICTIONS") ARE NOT PERMITTED TO USE THE SOFTWARE IN ANY WAY OR MANNER IN CONNECTION WITH ANY REAL MONEY PLAY. TO REMOVE ANY DOUBT, THIS RESTRICTION ALSO APPLIES TO RESIDENTS AND CITIZENS OF OTHER NATIONS WHILE LOCATED IN A PROHIBITED JURISDICTION. THE FACT THAT THE WEBSITE IS ACCESSIBLE IN A PROHIBITED JURISDICTION, OR THAT THE SOFTWARE ALLOWS THE USE OF THE OFFICIAL LANGUAGE OF A PROHIBITED JURISDICTION, SHALL NOT BE CONSTRUED AS A LICENSE TO USE THE SOFTWARE IN SUCH PROHIBITED JURISDICTION. ANY ATTEMPT TO CIRCUMVENT THIS RESTRICTION, FOR EXAMPLE, BY USING A VPN, PROXY OR SIMILAR SERVICE THAT MASKS OR MANIPULATES THE IDENTIFICATION OF YOUR REAL LOCATION, OR BY OTHERWISE PROVIDING FALSE OR MISLEADING INFORMATION REGARDING YOUR CITIZENSHIP, LOCATION OR PLACE OF RESIDENCE, OR BY MAKING BETS OR WAGERS USING THE SOFTWARE THROUGH A THIRD PARTY OR ON BEHALF OF A THIRD PARTY LOCATED IN A PROHIBITED JURISDICTION IS A BREACH OF THIS AGREEMENT AND MAY CONSTITUTE A CRIMINAL OFFENSE. IF IT BECOMES APPARENT, OR WE HAVE REASONABLE GROUNDS TO SUSPECT, THAT YOU ARE LOCATED IN ANY OF THE PROHIBITED JURISDICTIONS, THIS MAY RESULT IN CLOSING YOUR PLAYER ACCOUNT, WITHOUT AN OBLIGATION TO PROVIDE YOU WITH ADVANCE NOTICE, FREEZING THE FUNDS THEREIN AND PROVIDING THE APPLICABLE DETAILS TO VENDOR AND/OR RELEVANT AUTHORITIES, ALL IN ACCORDANCE WITH APPLICABLE LAWS AND AGREEMENTS, AND YOU SHALL BE LIABLE TO US FOR ANY DAMAGE OR LOSS RESULTING THEREFROM.</span>
                        <span>
                            BY ACCEPTING THE TERMS AND CONDITIONS (INCLUDING BY TICKING THE "I AGREE" BOX) ("ACCEPTANCE"), YOU AGREE TO THE USE OF ELECTRONIC COMMUNICATIONS IN ORDER TO ENTER INTO CONTRACTS, AND YOU WAIVE ANY RIGHTS OR REQUIREMENTS UNDER APPLICABLE LAWS OR REGULATIONS IN ANY JURISDICTION WHICH REQUIRE AN ORIGINAL (NON-ELECTRONIC) SIGNATURE, TO THE EXTENT PERMITTED UNDER APPLICABLE LAW. YOU ALSO CONFIRM THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. IF YOU DO NOT AGREE WITH ANY OF THE TERMS OF THIS AGREEMENT, DO NOT ACCEPT THE TERMS AND CONDITIONS AND DO NOT CONTINUE TO DOWNLOAD, INSTALL OR OTHERWISE USE THE SOFTWARE.</span>
                        <span>
                            <strong>1. DEFINITIONS</strong></span>
                        <span>
                            The following words and terms, when used in this agreement, shall have the following meanings, unless the context clearly indicates otherwise:</span>
                        <span>
                            1.1. <strong>"Assistance Programs"</strong> means any artificial intelligence including, without limitation, ‘robots' and/or any other computer generated program used to interact with the Software in aid or in place of a player.</span>
                        <span>
                            1.2. <strong>"IP Rights"</strong> means any and all intellectual property rights, of all types or nature whatsoever, including, without limitation, patent, copyright, design rights, trade marks, data base rights, applications for any of the above, moral rights, know-how, trade secrets, domain names, URL, trade names or any other intellectual or industrial property rights (and any licenses in connection with any of the same), whether or not registered or capable of registration, and whether subsisting in any specific country or countries or any other part of the world.</span>
                        <span>
                            1.3. <strong>"Online Games"</strong> means Our internet gaming system on the Website and related services and gaming activities (including, but not limited to, online casino, online bingo, online poker and any other games) offered on the Website.</span>
                        <span>
                            1.4. <strong>"Player Account"</strong> means a personal account opened by an individual and maintained with Us to enable that person to play the Online Games;</span>
                        <span>
                            1.5. <strong>"Poker Room"</strong> means the poker room and/or any other poker gaming activities offered by Us on our Website and/or on Our downloadable poker client;</span>
                        <span>
                            1.6. <strong>"Software"</strong> means the software required to be downloaded, accessed or otherwise utilized by You from the Website for the purpose of participating in the Online Games, including the related documentation and including any enhancements, modifications, additions, translations or updates to such software.</span>
                        <span>
                            1.7. <strong>"Us"</strong>, <strong>"We"</strong>, <strong>"Our"</strong> and similar terms mean <strong>Universe Entertainment Services Malta Limited</strong>.</span>
                        <span>
                            1.8. <strong>"Website"</strong> means <strong>europacasino.com</strong> and any related sites on which the Online Games are accessible via links or any other access way.</span>
                        <span>
                            1.9. <strong>"You"</strong>, <strong>Your"</strong> and similar terms mean the user of the Software downloaded from the Website.</span>
                        <span>
                            <strong>2. SUBJECT MATTER OF AGREEMENT</strong></span>
                        <span>
                            2.1 The rules for:</span>
                        <span>
                            2.1.1 sports betting can be found here;</span>
                        <span>
                            2.1.2 poker can be found here; and</span>
                        <span>
                            2.1.3 any of the Online Games you play (as set out in the game itself or in the "Help" section) as well as other sections of the Software and the Website, including but not limited to, rules describing how to play the Online Games, tournaments, and any other rules governing particular game, event and tournament. All such rules are incorporated and included under the term and conditions set out herein.</span>
                        <span>
                            <strong>3. LICENSE TO USE THE SOFTWARE; RESTRICTIONS</strong></span>
                        <span>
                            3.1. We hereby grant to You a limited, personal, non-transferable, non-exclusive, worldwide (except as noted below) license to download, access and otherwise utilize the Software on Your device, in order to play the Online Games, in accordance with this agreement.</span>
                        <span>
                            3.2. This license applies only to the object code of the Software (i.e., the compiled, assembled, or machine executable version of the Software) and does not grant you any rights whatsoever with respect to the source code of the Software.</span>
                        <span>
                            3.3. Notwithstanding anything to the contrary herein, persons located in the Prohibited Jurisdictions are not permitted to use the Software in any way or manner in connection with any real-money play. To remove any doubt, this restriction also applies to residents and citizens of other nations while located in a Prohibited Jurisdiction. This license does not apply to, and does not allow You the use of the Software in or from any Prohibited Jurisdictions, and the fact that the Website is accessible in a Prohibited Jurisdiction, or that the Software allows the use of the official language of a Prohibited Jurisdiction, shall not be construed as a license to use the Software in such Prohibited Jurisdiction. Any attempt to circumvent this restriction, for example, by using a VPN, proxy or similar service that masks or manipulates the identification of Your real location, or by otherwise providing false or misleading information regarding your location, citizenship, or place of residence, or by making bets or wagers using the Software through a third party or on behalf of a third party located in a Prohibited Jurisdiction, is a breach of this agreement and may constitute a criminal offense under applicable laws. If it becomes apparent, or We have reasonable grounds to suspect, that You are located in any of the Prohibited Jurisdictions, this may result in closing Your Player Account, without an obligation to provide You with advance notice, freezing the funds therein and providing the applicable details to Vendor and/or relevant authorities, all in accordance with applicable laws and agreements, and You shall be liable to Us for any damage or loss resulting therefrom. IT IS YOUR DUTY TO CONSULT AND CHECK REGULARLY OUR WEBSITE REGARDING THE LIST OF THE PROHIBITED JURISDICTIONS.</span>
                        <span>
                            3.4. We reserve any and all rights not expressly granted in Section 3.1 above. In addition, You are not permitted to, and You agree not to permit or assist others to:</span>
                        <span>
                            3.4.1. use, copy, modify, create derivative works from or distribute the Software, any part of it, or any copy, adaptation, transcription, or merged portion of it;</span>
                        <span>
                            3.4.2. decode, reverse engineer, disassemble, decompile or otherwise translate or convert the Software or any part of it;</span>
                        <span>
                            3.4.3. transfer, loan, lease, assign, rent, or otherwise sublicense the Software;</span>
                        <span>
                            3.4.4. remove any copyright, proprietary or similar notices from the Software (or any copies of it);</span>
                        <span>
                            3.4.5. operate the Software or any part of it for the benefit of or on behalf of any third party, including by way of ‘bulletin board', online service or remote dial-in, application service provider services, internet service provider services, timesharing arrangements, outsourcing services or bureau services;</span>
                        <span>
                            3.4.6. copy or translate any user documentation provided online or in electronic format;</span>
                        <span>
                            3.4.7. enter, access or attempt to enter or access or otherwise bypass Our security system or interfere in any way (including but not limited to, robots and similar devices) with the Poker Room or the Website or attempt to make any changes to the Software and/or any features or components thereof; or</span>
                        <span>
                            3.4.8. use any Assistance Programs in connection with the Software and/or the Online Games. You are prohibited from any interaction within the Online Games that is not the direct result of You personally utilizing the Software for the purpose for which it was intended.</span>
                        <span>
                            3.5. You acknowledge and agree that We may take steps to detect and prevent the use of Assistance Programs. These steps may include, but are not limited to, examination of software programs running concurrently with the Software on Your device. You agree that you will not attempt to bypass, interfere with, or block such steps, including, without limitation, the use of third party software that bypasses, interferes with, or blocks such steps. Any attempt to restrict Us in this matter will entitle Us to immediately suspend the availability of the Software to You and You shall immediately forfeit any winnings.</span>
                        <span>
                            3.6. You acknowledge and agree that all IP Rights, title and interest in and to the Software, including in and to any modification, enhancement, adaptation, translation or other change of or addition to the Software, belong exclusively to the Vendor, even if developed based on ideas, suggestions or proposals by You or any other third party. By accepting this agreement, You irrevocably assign to the Vendor all right, title, and interest You may have or may acquire in and to all such rights, including, without limitation, patent, copyright, trademark, trade secret or know how, and You agree to sign and deliver to the Vendor such documents as Vendor considers desirable to evidence or effect the assignment of all of the aforesaid rights to the Vendor. You agree not to, directly or indirectly, attempt to invalidate for any reason whatsoever, or assert, or assist the assertion by others, that the rights, title or interest in the Software belong to any third party other than the Vendor, or that they infringe the IP Rights of others.</span>
                        <span>
                            <strong>4. YOUR DUTY TO EXAMINE LEGALITY OF USE</strong></span>
                        <span>
                            You confirm that You are older than 18 years, and in any event of legal age as determined by the laws of the country where you are located. You also confirm that You are aware of the legal issues relating to the operation of online gambling sites, and that You understand that We and the Vendor are not warranting in any way or manner that the use of the Software for the purposes of gambling, as such term is commonly understood in the industry, is legal in any jurisdiction.</span>
                        <span>
                            Given the changes in the legal status of online gambling in various jurisdictions, You undertake to examine the legality of Your participation in the Online Games and use of the Software in each jurisdiction that is applicable to You and to do the same only in compliance with all applicable laws and orders of any competent authority.</span>
                        <span>
                            <strong>5. DISCLAIMER OF WARRANTIES</strong></span>
                        <span>
                            THE SOFTWARE IS MADE AVAILABLE TO YOU HEREUNDER ON AN ‘AS IS' BASIS, WITHOUT ANY UNDERTAKINGS, WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE.</span>
                        <span>
                            WE AND THE VENDOR, AND ALL OF THEIR AFFILIATES AND RELATED PARTIES, HEREBY EXCLUDE AND DISCLAIM ANY AND ALL IMPLIED TERMS, CONDITIONS AND WARRANTIES (INCLUDING ANY WARRNATY OF MERCHANTABILITY, SATISFACTORY QUALITY AND FITNESS FOR ANY PARTICULAR PURPOSE), AND, WITHOUT LIMITING THE GENERALITY OF THE AFORESAID, WE AND THE VENDOR DO NOT WARRANT, AMONG OTHER THINGS, THAT (A) THE SOFTWARE WILL BE NON-INFRINGING, (B) THE OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ANY DEFECTS IN THE SOFTWARE WILL BE CORRECTED, OR THAT THE SOFTWARE IS VIRUS-FREE; (C) THE SOFTWARE IS OF SATISFACTORY QUALITY OR FIT FOR ANY PARTICULAR PURPOSE; OR (D) USE BY YOU OF THE SOFTWARE WITH ANY OTHER SOFTWARE, OR WITH INAPPROPRIATE HARDWARE, WILL NOT CAUSE ANY DISTURBANCE TO THE SOFTWARE OR TO SUCH OTHER SOFTWARE.</span>
                        <span>
                            IN THE EVENT OF COMMUNICATIONS OR SYSTEM ERRORS OCCURING IN CONNECTION WITH THE SOFTWARE, NEITHER WE NOR THE VENDOR NOR THEIR AFFILIATES AND RELATED PARTIES WILL BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY COSTS, EXPENSES, LOSSES OR CLAIMS ARISING OR RESULTING FROM SUCH ERRORS.</span>
                        <span>
                            NEITHER WE NOR OUR AFFILIATES AND RELATED PARTIES WILL BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR PAYMENTS MADE TO YOU AS A RESULT OF A DEFECT OR ERROR IN THE SOFTWARE, OR IN CONNECTION WITH ANY CLAIM OR DEMAND MADE BY THE VENDOR OR ANY THIRD PARTY FOR THE RETURN OF SUCH PAYMENTS OR OTHERWISE IN CONNECTION WITH SUCH PAYMENTS.</span>
                        <span>
                            You hereby acknowledge that it is not in Our control how the Software is used by You. You load and use the Software at Your own risk and in no event shall We be liable to you for any direct, consequential, incidental or special damage or loss of any kind (except personal injury or death resulting from Our negligence).</span>
                        <span>
                            <strong>6. CONFIDENTIALITY</strong></span>
                        <span>
                            The Software includes non-public and confidential information, which is secret and valuable to Us or the Vendor. You agree, as long as You use the Software and thereafter, to (a) keep all such confidential information strictly confidential; (b) not to disclose such confidential information to a third party, and not to use such confidential information for any purpose other than participating in the Online Games. You further agree to take all reasonable steps at all times to protect and keep confidential such confidential information.</span>
                        <span>
                            <strong>7. YOUR WARRANTIES AND REPRESENTATIONS</strong></span>
                        <span>
                            You warrant and represent to Us that:</span>
                        <span>
                            7.1. You are not a resident of any of the Prohibited Jurisdictions; and</span>
                        <span>
                            7.2. You have examined the legality of Your participation in the Online Games and use of the Software in each jurisdiction that is applicable to You, and have found the same to be legal in such jurisdictions under all applicable laws and orders of any competent authority.</span>
                        <span>
                            <strong>8. CHANGES TO THE AGREEMENT</strong></span>
                        <span>
                            8.1. We may make changes to this Agreement at any time, at our sole discretion. Such changes will take effect from the date specified by us on the Website, whether or not We have notified You specifically of such changes. It is important, therefore, that You log in to the Website from time to time to check to see whether there is a notification of change.</span>
                        <span>
                            8.2. You agree to be solely responsible for becoming informed of such changes. If You continue to use the Software or the Online Games after the effective date of certain changes (regardless of the way We have notified such changes), You agree to be bound by those changes whether or not You have had actual notice of, or have read, the relevant changes. If You do not agree to be bound by relevant changes, You should not continue to use the Software or the Online Games any further.</span>
                        <span>
                            <strong>9. TERM AND TERMINATION</strong></span>
                        <span>
                            9.1. This agreement is effective, and binding upon You, from the moment of Your Acceptance, and shall remain in force unless terminated in accordance with the provisions hereof.</span>
                        <span>
                            9.2. You may terminate this agreement with immediate effect at any time, subject to the terms of Section 9.4. Termination by You shall be effected by sending Us written notice of the termination of Your participation on the Website and the Online Games and closure of Your account with Us.</span>
                        <span>
                            9.3. We may terminate this agreement with immediate effect at any time, by written notice to You.</span>
                        <span>
                            9.4. Upon any termination of this agreement, whether by Us or by You, You agree and acknowledge that (i) Your rights to use the Software shall immediately terminate, and (ii) You will cease any and all use of the Software, and (iii) You will remove the Software from your computer, hard drives, networks and other storage material.</span>
                        <span>
                            <strong>10. NO CLAIMS AGAINST VENDOR; LIMITATION OF LIABILTY</strong></span>
                        <span>
                            10.1. You understand and agree that We will be solely responsible to You under this agreement, and while Your commitments under this agreement are also for the benefit of the Vendor, its affiliates and related parties (and can therefore be enforced by them too), Vendor, its affiliates and related parties are not parties to this agreement and will not be liable for any damages of any kind whatsoever caused to You or any third party, regardless of the form of action, whether in contract, tort (including negligence), strict liability or otherwise.</span>
                        <span>
                            10.2. You are free to choose whether to download and use the Software. If You do so, You acknowledge that You do it with the full understanding of this agreement, including the provisions of this Section 10, and at Your own risk. IN NO EVENT SHALL WE (AND FOR THE AVOIDANCE OF DOUBT, ALSO VENDOR) OR ANY OF THEIR AFFILIATES AND RELATED PARTIES, IN AGGREGATE:</span>
                        <span>
                            10.2.1. BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL OR SPECIAL DAMAGE OR LOSS OF ANY KIND WHATSOEVER; OR LOSS OF BUSINESS, PROFITS, REVENUE, CONTRACTS OR ANTICIPATED SAVINGS; OR LOSS OR DAMAGE ARISING FROM LOSS, DAMAGE OR CORRUPTION OF ANY DATA; or</span>
                        <span>
                            10.2.2. BE LIABLE TO YOU WITH RESPECT TO ANY AND ALL CLAIMS AT ANY AND ALL TIMES ARISING FROM OR RELATING TO THIS AGREEMENT, HOWSOEVER ARISING UNDER CONTRACT OR ANY THEORY OF LAW, FOR DAMAGES EXCEEDING THE LOWER OF (A) THE AMOUNT DEPOSITED BY YOU WITH US AND USED BY YOU FOR GAMING PURPOSES, OR (B) €1,000 (ONE THOUSAND EUROS).</span>
                        <span>
                            11. SHARED POKER ROOM NETWORK</span>
                        <span>
                            11.1. We may participate in a shared poker room network which enables You to play poker together with other players from other poker room websites all joining the same game or the table or the tournament through a shared poker room platform managed by a third party provider of poker network services.</span>
                        <span>
                            11.2. You therefore agree that once You join a shared poker room You will accept and comply with the rules and the terms and conditions that apply on the shared poker room including any of its games, tables, tournaments.</span>
                        <span>
                            11.3. You acknowledge and agree that We and/or the operator of the shared poker network, at our both sole discretion, reserve the right to terminate Your game or block Your account as well as deny You from accessing the shared poker network, either from the Website or from any other websites thereafter, in the event that You violate any of the game rules or any of the terms and conditions set out hereunder.</span>
                        <span>
                            11.4. You further acknowledge and accept that We and/or the operator of the shared poker network, reserve the right, both at our sole discretion, to collect, process and record both in our databases any information in connection with your game patterns, personal data, depositing of funds and any other related information and inquiries that will help prevent any fraud, collusion or alike improper behaviour.</span>
                        <span>
                            <strong>12. SECURITY</strong></span>
                        <span>
                            12.1. Collusion Prevention</span>
                        <span>
                            12.1.1. Collusion means a situation where two or more players attempt to earn an unfair advantage by sharing knowledge of their cards or other information at a poker table. Any player who attempts to or colludes with any other player while using the Poker Room will be prohibited from ever using the Poker Room or the Website or the Software or any of our other related services and their player account will be terminated effective immediately. We will do our best to investigate complaints received against players suspected of collusion. If We will suspect of a collusive behaviour during a game, We may, in Our sole discretion, terminate the suspected players' access to the Poker Room and/or block their accounts. We will not be liable under any circumstances whatsoever for any loss You or any other player may accrue as a result of the collusive or otherwise unlawful activity and We will not be further obliged to take any other actions in any event of suspected of collusion, fraudulent or fraud.</span>
                        <span>
                            12.1.2. We will only use Your personal information in accordance with our privacy policy, which is set out in full in our PRIVACY POLICY. We reserve the right to amend the Privacy Policy at any time. However, We reserve the right to ask You to provide Us with additional details and any such additional information will be kept confidential. We further reserve the right under certain circumstances to disclose certain details to relevant authorities should it be required to do so by law, an arm of the state or a regulatory body. Subject to the provisions of the Privacy Policy, this right to disclose personal information to bodies or authorities whose purpose is to investigate money laundering, fraud and other criminal activity will be to the extent required by law.</span>
                        <span>
                            <strong>13. YOUR RESPONSIBILITIES</strong></span>
                        <span>
                            You shall not transfer in any way whatsoever Your rights under this agreement without our prior written consent.</span>
                        <span>
                            <strong>14. YOUR WARRANTIES AND REPRESENTATIONS</strong></span>
                        <span>
                            You warrant and represent that:</span>
                        <span>
                            14.1. You are not colluding or attempting to collude or intending to participate, directly or indirectly, in any collusion scheme with any other player in the course of any game You play or will play on the Poker Room.</span>
                        <span>
                            14.2. You are over the age of eighteen.</span>
                        <span>
                            <strong>15. RESERVATION OF RIGHTS</strong></span>
                        <span>
                            15.1. We reserve, at Our sole discretion, the right to:</span>
                        <span>
                            15.2. change, suspend, remove, modify or add any game or tournament on the Online Games.</span>
                        <span>
                            15.3. We may at any time disclose certain personal information of Yours to third parties in accordance with Our Privacy Policy</span>
                        <span>
                            15.4. We may at any time without prior notice to You terminate Your use of the Online Games and block Your Player Account if We considers that You are in breach of any of the terms and provisions of this agreement or that You are otherwise acting illegally. We will not be under any obligation in such circumstances to refund or otherwise reimburse You for any of the funds in Your Player Account.</span>
                        <span>
                            <strong>16. GENERAL PROVISIONS</strong></span>
                        <span>
                            16.1. <strong>Governing Law</strong>. The construction, validity and performance of this agreement will be governed by the laws of England.</span>
                        <span>
                            16.2. <strong>Competent Courts</strong>. Any legal proceedings arising out of or relating to this agreement will be subject to the jurisdiction of the courts of London, England. However, this shall not prevent Us from bringing any action in the court of any other jurisdiction for injunctive or similar relief.</span>
                        <span>
                            16.3. <strong>Severability</strong>. The illegality, invalidity or enforceability of any part of this agreement will not affect the legality, validity or enforceability of the remainder.</span>
                        <span>
                            16.4. <strong>Language</strong>. The English language version of this agreement will prevail over any other language version issued by us.</span>
                        <span>
                            16.5. <strong>No assignment by You</strong>. You are not allowed to assign this agreement or any rights or obligation hereunder to any other party.</span>
                        <span>
                            16.6. <strong>Priority</strong>. This agreement shall prevail in the event of any conflict between the terms and conditions herein and any other agreement or document referred to herein or used in connection with the Software.</span>
                        <span>
                            16.7. <strong>Notices.</strong> You agree to receive communications from Us in an electronic form. Electronic communications may be posted on the pages within the Website or the messages/help files of Your client application, or delivered to Your e-mail address. All communications in either electronic or paper format will be considered to be in ‘writing' and to have been received no later than five business days after posting or dissemination, whether or not You have received or retrieved such communication. We reserve the right, but assume no obligation, to provide communications in paper format. Any notices required to be given in writing to Us or any questions concerning this agreement should be addressed to <a href="mailto:support@vivaslots.co.za">support@vivaslots.co.za</a>.</span>
                        <span>
                            &nbsp;</span>

                    </AboutUsContent>
                </AboutUsWrapper>
            </AboutUsContainer>
        </React.Fragment>
    )
}

export default TermsConditions;