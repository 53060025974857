import styled from "styled-components";

export const EURWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width:820px){
        margin-left: 10px;
    }
    @media screen and (max-width:450px){
        margin-left: 20px;
    }
`
export const RegisterBtn = styled.div`
    background-color: #007e45;
    transition: all .3s ease-in-out;
    font-size: 30px;
    font-weight: 500;
    width: 309px;
    cursor: pointer;
    color: #fff;
    :hover {
        background-color: #1b6796;
    }
    @media screen and (max-width:820px){
        width: 180px;
        font-size: 24px;

    }
    @media screen and (max-width:580px){
        width: 100px;
        font-size: 19px;
    }
    @media screen and (max-width:450px){
        width: 80px;
        font-size: 15px;
    }
`
export const EURItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    img {
        margin-bottom: 20px;
        @media screen and (max-width:820px){
            height: 100px;
            margin-bottom: 10px;
        }
        @media screen and (max-width:580px){
            height: 70px;
        }
        @media screen and (max-width:450px){
            height: 50px;
        }
    }
    span {
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        text-decoration: underline;
        margin-top: 5px;
        @media screen and (max-width:820px){
            display: none;
        }
    }
`
export const GamesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    @media screen and (max-width:820px){
        margin-top: -10px;
    }
`
export const GamesWrapper = styled.div`
    max-width: 1200px;
    background-color: #fff;
    width: 100%;
    margin-bottom: 100px;
`

export const HeaderItemContainer = styled.div`
    display: flex;
    max-width: 100%;
    overflow: auto;
    margin-left: 10px;
`

export const HeaderItem = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;    
    min-width: 105px;

    .res-search-icon {
        color: rgb(19, 57, 102);
        font-size: 29px;
    }
    span {
        color: ${props => props.active ? " #aea269" : " #1d3963"};
        font-family: 'RobotoRegular';
        font-size: 14px;
        @media screen and (max-width:1200px){
            font-size: 12px;
        }
    }
    @media screen and (max-width:1200px){
        min-width: 75px;
    }
`

export const HeaderItemImg = styled.div`
       
        background-image: url(${props => props.imgurl});
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        height: 2.257rem;
        margin: 0 auto;
        width: 100%;
        line-height: 1;
        font-size: 2.5rem;
        @media screen and (max-width:1200px){            
            height: 27.42px;
        }   
      
`

export const GameHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 1184px;
    padding: 16px 8px;
    position: sticky;
    background-color: #fff;
    margin-top: -61px;
    top: 117px;
    z-index: 10;
    @media screen and (max-width:1200px){
        width: calc(100% - 16px);
        padding: 8px 8px 8px;
    }
    @media screen and (max-width:820px){
        top: 55px;
        /*
        padding-bottom: 10px;
        .pos-close {
            position: absolute;
            z-index: 0;
            right: 30px;
        } */
    }
    @media screen and (max-width:700px){
        .tablet-header-item {
            display: none;
        }
    }
    `
export const SearchInput = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #b3b3b3;
    background-color: #f5f5f5;
    height: 25px;
    padding: 0 5px;
    width: 150px;
    z-index: 11;
    input {
        background-color: #f5f5f5;
        border: none;
        width: 90%;
        outline: none;
    }
    @media screen and (max-width:1200px){
        display: none;
    }
`

export const SliderImg = styled.div`
    background: url(${props => props.url}) no-repeat;
    max-height: 360px;
    height: 360px;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    @media screen and (max-width:820px){
        height: 280px;
        background-size: 100% calc(100% - 70px);
    }
    @media screen and (max-width:580px){
        height: 230px;
    }
    @media screen and (max-width:450px){
        height: 200px;
    }
`

export const GameListWrapperItemHover = styled.div`
    display: none;
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: 'RobotoRegular';
    font-size: 12px;
    cursor: pointer;
    
    #firstspan {
        padding: 5px;
        background: rgb(0, 126, 69);
        color: white;
        min-width: calc(50% - 0.5em);
        margin-bottom: 10px;
    }
    #lastspan {
        padding: 5px;
        background: #1b6796;
        color: white;
        min-width: calc(50% - 0.5em);
    }
`


export const GameListWrapperItem = styled.div` 
  height: 100%;
  width: 100%;
  background-image: url(${props => props.imgurl});
  background-position: top;
  background-size: 100% 100%;
  position: relative;
  &:hover ${GameListWrapperItemHover} {
    display: flex;
    flex-direction: column;
  }
`

export const GameListWrapper = styled.div`
    padding: 0 10px;   
    .my-img-list {
        margin-top: 0;
        gap: 0;
        align-items: center;
        li {
            padding: 2px !important;
        }
    }
    /* @media screen and (max-width:800px){
        .my-img-list {
            grid-template-columns: repeat(4, 1fr) !important;
        }
    }
    @media screen and (max-width:550px){
        .my-img-list {
            grid-template-columns: repeat(3, 1fr) !important;
        }
    } */
    /* @media screen and (max-width:425px){
        .my-img-list {
            grid-template-columns: repeat(2, 1fr) !important;
        }
    } */
`

export const SlideContainer = styled.div`
    @media screen and (max-width:820px){
        margin-top: 55px;
    }
`

export const ResSearch = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    .res-search-icon {
        color: rgb(19, 57, 102);
        font-size: 29px;
    }

    img {
        width: 27.42px;
        height: 27.42px;
    }
    span {
        color: #1d3963;
        font-size: 12px;
        display: ${props => props.isSearch ? "none" : "flex"};
        cursor: pointer;
    }
    /* @media screen and (max-width:1200px){
        display: flex;
    } */
`
export const ResCustomSearch = styled.input`
    position: absolute;
    left: 60px;
    outline: none;
    border: 1px solid #000;
    border-radius: 5px;
    /* border-style: none;
    border-bottom: 2px solid rgb(19, 57, 102);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px; */
    height: 50%;
    color: #868686;
    font-size: 16px;
    width: calc(90% - 70px);
    padding-right: 30px;
`
export const ResCarouselContainer = styled.div`
    display: none;
    @media screen and (max-width:700px){
        display: ${props => props.isSearch ? "none" : "flex"};
        width: calc(100% - 50px);
        height: 50px;
    }
`

export const ResContainer = styled.div`
    display: block;
    width: calc(100% - 50px);
    display: ${props => props.isSearch ? "none" : "flex"};
    @media screen and (max-width:1200px){
        width: calc(100% - 50px);
    }
    @media screen and (max-width:700px){
        display: none;    
    }
`