export const routes = {
    HOME: '/',
    LOGIN: '/login',
    RESET: '/reset-password',
    VERIFY: '/verify',
    FORGOT: '/forgot',
    REGISTER: '/register',
    DEPOSIT: '/cashier',
    PROMOTIONS: '/promotions',
    PROMOTIONSFirst: '/promotions/first-deposit-bonus',
    PROMOTIONSSecond: '/promotions/second-deposit-bonus',
    PROMOTIONSMonth: '/promotions/monthly-deposit-bonus',
    LEADERBOARD: '/leaderboard',
    ABOUTUS: '/about-us',
    PRIVACY: '/privacy-policy',
    TERMS: '/terms-of-use',
    RESPONSIBLE: '/fair-gaming',
    CONTACTUS: '/help/contact-us',
    ACCOUNT: '/account',
    HELP: '/help',
  };
  