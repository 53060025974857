import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from "../../components/Tabs";
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { HelpContainer, HelpWrapper, HelpTitle, HelpContent, HelpList, HelpContext } from './styled';

const Help = () => {
    // const [isVisible, setVisible] = useState(false);
    const [isSelect, setSelect] = useState(-1);

    const handleHelp = (item) => {
        if (item === isSelect) {
            setSelect(-1);
        } else {
            setSelect(item);
        }
    }

    return (
        <React.Fragment>
            <Tabs tag={5} />
            <HelpContainer>
                <HelpWrapper>
                    <HelpTitle>Frequently Asked Questions</HelpTitle>
                    <HelpContent>
                        <HelpList onClick={() => handleHelp(0)}>
                            <span>How to create an account</span>
                            {isSelect === 0 ? <FiChevronDown /> : <FiChevronRight />}
                        </HelpList>
                        <hr />
                        <HelpContext state={isSelect === 0 ? true : false}>
                            <p>
                                Welcome to the Viva Slots family! If you’re ready to open an account with us, follow this easy <Link to='/help/how-to-create-an-account'>tutorial</Link>.
                            </p>
                        </HelpContext>
                    </HelpContent>
                    <HelpContent>
                        <HelpList onClick={() => handleHelp(1)}>
                            <span>How do I deposit?</span>
                            {isSelect === 1 ? <FiChevronDown /> : <FiChevronRight />}
                        </HelpList>
                        <hr />
                        <HelpContext state={isSelect === 1 ? true : false}>
                            <p>
                            Here at Viva Slots we offer one of the largest selections of deposit options in the industry. Our most recommended method is Credit Card, and you can learn all about placing a deposit <Link to='/help/deposit'>here</Link>.
                            </p>
                        </HelpContext>
                    </HelpContent>
                    {/* <HelpContent>
                        <HelpList onClick={() => handleHelp(2)}>
                            <span>Is my money safe?</span>
                            {isSelect === 2 ? <FiChevronDown /> : <FiChevronRight />}
                        </HelpList>
                        <hr />
                        <HelpContext state={isSelect === 2 ? true : false}>
                            <p>
                                At Super Slots, all customer deposits are held in an insured escrow account, either in cash or cash equivalents. Your deposit is fully secured and available for withdrawal on a daily basis, with prompt payouts within 24-48 hours. All online transactions are coded and encrypted for your protection.
                            </p>
                        </HelpContext>
                    </HelpContent> */}
                </HelpWrapper>
            </HelpContainer>
        </React.Fragment>
    )
}

export default Help;