import styled from "styled-components";

export const TabsContainer = styled.div`
    margin-top: 117px;
    height: 32.19px;
    width: 100%;
    display: flex;
    background-color: #fff;
    justify-content: center;
    @media screen and (max-width:1200px){
        padding: 0 10px;
        width: calc(100% - 20px);
    }
    @media screen and (max-width:820px){
        display:none
    }
`

export const TabsWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const TabsItems = styled.div`
    display: flex;
    align-items: center;
`
export const TabsItem = styled.div`
    font-size: 14px;
    cursor: pointer;
    display: flex;
    font-weight: ${props => props.active && 700};
    align-items: center;
    white-space: nowrap;
    svg {
        margin: 0 5px;
    }
`
export const VIPImg = styled.img`
    height: 28px;
    cursor: pointer;
`

export const SearchInput = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #b3b3b3;
    background-color: #f5f5f5;
    height: 25px;
    padding: 0 5px;
    width: 150px;
    z-index: 11;
    input {
        background-color: #f5f5f5;
        border: none;
        width: 90%;
        outline: none;
    }
    @media screen and (max-width:1200px){
        display: none;
    }
`