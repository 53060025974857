import React, { useState, useEffect } from 'react';
import { DepositContainer, DepositWrapper, DepositMethod, PaymentModalContainer, PaymentModalContent, PaymentModalAmount, CancelBtn } from './styled';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, InputAdornment, Input, Button, Modal, FormControlLabel, Switch } from '@mui/material';
import { AiOutlineCheckCircle, AiOutlineClose } from 'react-icons/ai';
// import MastercardImg from '../../assets/image/payment/mastercard.png';
// import VisaImg from '../../assets/image/payment/visa.png';
import VoucherImg from '../../assets/image/payment/voucher.png';
import EftImg from '../../assets/image/payment/eft.png';
import { useTypedDispatch, useTypedSelector } from '../../store/typedHooks';
import { chargePayment } from '../../api/account';
import { setPaymentAmount } from '../../store/slices/account';

const Deposit = ({ payment_amount, isPaymentStatus, setPaymentStatus, isPaid, setPaid }) => {
    const dispatch = useTypedDispatch();
    const [amount, setAmount] = useState(0);
    const [paymentType, setPaymentType] = useState('');
    const [open, setOpen] = useState(false);
    const [wagerBonus, setWagerBonus] = useState(false);
    const { url } = useTypedSelector((state) => state.account);
    const { email, phone } = useTypedSelector((state) => state.auth);
    // const [isPaid, setPaid] = useState(false);

    useEffect(() => {
        if (url) {
            handleClose();
            setPaid(true);
        } else {
            setPaid(false);
        }
    }, [url]);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        setAmount(0);
    }

    const makePayment = () => {
        const paymentData = {
            amount: Number(amount),
            type: paymentType,
            successUrl: `${process.env.REACT_APP_REDIRECT_DEV_URL}/account/`,
            wagerBonus: wagerBonus
            // successUrl: 'http://192.168.126.81:3000/account/'
        };
        dispatch(setPaymentAmount({ payment: Number(amount) }));
        dispatch(chargePayment(paymentData));
    }

    const handleOK = () => {
        if (amount) {
            makePayment();
            handleClose();
        }
    };

    // const cardPayment = () => {
    //     setPaymentType('credit_card');
    //     handleOpen();
    // };

    const voucherPayment = () => {
        setPaymentType('bluvoucher ');
        handleOpen();
    }

    const eftPayment = () => {
        setPaymentType('eft');
        handleOpen();
    }

    const handleModalClose = () => {
        setPaymentStatus(false);
    }

    return (
        <React.Fragment>
            {
                isPaid ?
                    (
                        <DepositContainer>
                            <AiOutlineClose style={{ position: 'absolute', right: 0, top: 3 }} size={24} onClick={() => setPaid(false)} />
                            <iframe
                                className='iframe-payment'
                                src={url}
                                width="100%"
                                height="100%"
                                scrolling="yes"
                                frameBorder="no"
                                id="paymentFrame"
                                title='paymentFrame'
                            />
                        </DepositContainer>
                    )
                    :
                    (
                        <>
                            <DepositContainer>
                                <DepositWrapper>
                                    <span>Select a deposit method</span>
                                    <DepositMethod>
                                        {/* <img src={MastercardImg} onClick={cardPayment} alt='mastercard' />
                                        <img src={VisaImg} onClick={cardPayment} alt='visa' /> */}
                                        <img src={VoucherImg} onClick={voucherPayment} alt='voucher' />
                                        <img src={EftImg} onClick={eftPayment} alt='eft' />
                                    </DepositMethod>
                                </DepositWrapper>
                            </DepositContainer>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth={true}
                            >
                                <DialogTitle id="alert-dialog-title">{'Deposit'}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <FormControl fullWidth variant="standard" className="deposit-modal">
                                            <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
                                            <Input
                                                type="number"
                                                id="standard-adornment-amount"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <b>ZAR</b>
                                                    </InputAdornment>
                                                }
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required={true}
                                            />
                                        </FormControl>
                                        <FormControlLabel control={<Switch checked={wagerBonus} onChange={(e) => setWagerBonus(e.target.checked)} />} label="Wager Bonus" />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions className="deposit-modal-footer">
                                    <Button color="error" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button onClick={handleOK} autoFocus>
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
            }
            <Modal open={isPaymentStatus}
                onClose={handleModalClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description">
                <PaymentModalContainer>
                    <h2>Payment successfull!</h2>
                    <AiOutlineCheckCircle color='#00b36b' size={50} />
                    <PaymentModalContent>
                        <span>Payment type</span>
                        <p>Credit Card</p>
                    </PaymentModalContent>
                    <PaymentModalContent>
                        <span>Mobile</span>
                        <p>{phone}</p>
                    </PaymentModalContent>
                    <PaymentModalContent>
                        <span>Email</span>
                        <p>{email}</p>
                    </PaymentModalContent>
                    <PaymentModalAmount>
                        <span>Amount paid</span>
                        <p>{payment_amount.toLocaleString("en-US", { style: "currency", currency: "ZAR" })}</p>
                    </PaymentModalAmount>
                    {/* <PaymentModalContent>
                        <span>Transaction id</span>
                        <p>{transcation_id}</p>
                    </PaymentModalContent> */}
                    <CancelBtn onClick={handleModalClose}>
                        Close
                    </CancelBtn>
                </PaymentModalContainer>
            </Modal>
        </React.Fragment>
    )
}

export default Deposit;