import React, { useEffect } from "react";
import Tabs from '../../components/Tabs';
// import ContactImg from '../../assets/image/contact.png';


// styled
import { AboutUsContainer, AboutUsWrapper, AboutUsContent, ContactUsContainer } from './styled'

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <Tabs tag={0} />
            <AboutUsContainer>
                <AboutUsWrapper>
                    <ContactUsContainer>
                        <h1>CONTACT US</h1>
                    </ContactUsContainer>
                    <AboutUsContent>
                        <span>
                            We take pride in providing excellent customer service and are standing by to assist you from 06:00 am – Midnight GMT, 7 days a week.</span>
                        <span>
                            <strong>EMAIL ADDRESS</strong></span>
                        <span>
                            You can contact our support team via email: <a href="mailto:support@vivaslots.co.za">support@vivaslots.co.za</a>.</span>
                        <span>
                            <strong>Please note:&nbsp;</strong>Logged in customers can also contact us via instant chat.</span>
                    </AboutUsContent>
                </AboutUsWrapper>
            </AboutUsContainer>
        </React.Fragment>
    )
}

export default ContactUs;

