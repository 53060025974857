import styled from "styled-components";

export const MobileSideContainer = styled.div`
    z-index: 1001;
    position: fixed;
    width: 100%;
    top: 0;
    left: ${props => props.isVisible ? 0 : '-100%'};
    right: 0;
    bottom: 0;
    transition: all .3s ease-in-out;
    `

export const TopWrapper = styled.div`
    height: 55px;
    width: 100%;
    background-color: #133966;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        color: #fff;
        margin-left: 12px;
    }
    button {
        width: 52px;
        height: 20px;
        background-color: #b3a25d;
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 3px;
        color: #fff;
        font-size: 11px;
    }
`
export const SideBody = styled.div`
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: calc(100% - 44.8px);
`
export const SideWrapper = styled.div`
    width: 80%;
    height: 100%;
    background-color: #fff;
`
export const Tabs = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #aea269;
    padding-top: 15px;
    justify-content: space-around;
`
export const TabItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3px;
    ${({isActive}) => isActive && "border-bottom: 5px solid #aea269;"}
    span {
        color: #133966;
        font-size: 14px;
        font-weight: 700;
        margin-top: 5px;
    }
    img {
        width: 51.19px;
        height: 51.19px;
    }
`
export const LinksMenuContainer  = styled.div`
    width: 100%;
`
export const  LinksItem = styled.div`
    display: flex;
    border-bottom: 1px solid #133966;
    align-items: center;
    height: 42.23px;

    img {
        width: 24.31px;
        height: 24.31px;
        margin-right: 21px;
        margin-left: 18px;
    }
    span {
        font-size: 15px;
        color: #133966;
    }
`
export const ImgContainer =  styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 95%;
    margin: auto;
    gap: 3px;
    margin-top: 25.6px;
    img {
        width: 100%;
    }
`