import styled from "styled-components";

export const PromitionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 820px){
        margin-top: 55px;
    }
`

export const PromitionsWrapper = styled.div`
    max-width: 1200px;
    background-color: #fff;
    width: calc(100% - 40px);
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 20px;
`

export const PromotionTitle = styled.h1`
    font-size: 2.5rem;
    color: #b3a25d;
    margin: 7px 0;
    line-height: 1;
`

export const PromotionContent = styled.p`
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
`

export const PromotionListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.4rem;
    gap: 10px;
    @media screen and (max-width: 980px){
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 760px){
        grid-template-columns: 1fr;
    }
`

export const PromotionList = styled.div`
    position: relative;
    box-sizing: border-box;
    background-size: 100% 100%;
    /* width: calc(33.3% - 10px); */
    width: 100%;
    border: 5px solid #0d2da0;
    background: url(${props => props.background}) round;
    /* height: 500px; */
    padding: 10px 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const PromotionListItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    color: #fff;
    margin: 10px 0 30px 0;
    h2 {
        margin: 5px 0;
        text-transform: uppercase;
    }
    span {
        font-size: 15px;
    }
`

export const PromotionMoreBtn = styled.div`
    /* margin-top: 20px; */
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    background-color: #00a259;
    width: 170px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    border-radius: 3px;
    :hover {
        background-color: #007e45;
    }
`

export const PromotionsListContent = styled.ol`
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: left;
    gap: 8px !important;
`