import styled from "styled-components";

export const GamePlayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgb(19, 57, 102);    
`

export const GamePlayTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
    margin: 5px 0;
    color: white;
    svg {
        fill: white;
        transform: scale(1.5);
    }
    @media screen and (max-width: 500px) {
        display: none;
    }
`

export const GamePlayIframeWrapper = styled.iframe`
    z-index: 1000;
`