import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiTime } from "react-icons/bi";
import { FaQuestionCircle, FaBars } from "react-icons/fa";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { MdOutput, MdOutlineClose } from 'react-icons/md';
import { RiUser6Line, RiSettings5Line } from "react-icons/ri";
import { useTypedDispatch, useTypedSelector } from "../../store/typedHooks";
import Logo from "../../assets/image/viva_slot.png";
import { routes } from "../../constants/routes";
import { loginUser } from "../../api/auth";
import { getUserBalance, getJackPots, getBonus } from "../../api/account";
import { cleanError, setLogout } from "../../store/slices/auth";
import { encryptedStorage } from "../../utils/encryptStorage";
import { Store } from "react-notifications-component";
import { osName } from 'react-device-detect'
import {
	HeaderOutContainer,
	HeaderContainer,
	HeaderTopWrapper,
	HeaderTimezoneWrapper,
	HeaderLangContainer,
	LoginUserContainer,
	LoginUserWrapper,
	HeaderLangWrapper,
	LangSelect,
	HeaderLogoWrapper,
	HeaderLogo,
	HeaderLogoContainer,
	LoginWrapper,
	LoginInputWrapper,
	Inputs,
	LoginBtnsWrapper,
	LoginBtn,
	JoinBtn,
	MobileLoginBtn,
	DepositBtn,
	InfoContainer,
	InfoWarpper,
	UserIcon,
	UserInfo,
	CustomBtn,
	DownloadContainer,
	DownloadButton
} from "./styled";
import {
	ForgotpwdModal,
	LoginModal,
	RegisterModal,
} from "../../components/Modal";

const Header = ({ setSide }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useTypedDispatch();
	const [timeZone, SetTimeZone] = useState("");
	const [isLoginModal, setLoginModal] = useState(false);
	const [isForgotModal, setIsForgotModal] = useState(false);
	const [open, setOpen] = useState(false);
	const [isResetPassModal, setResetPassModal] = useState(false);
	const [user_name, setUsername] = useState("");
	const [pass_word, setPassword] = useState("");
	const [isAccountDetail, setAccountDetail] = useState(false);
	const [isShow, setShow] = useState(false);
	const [isDownload, setDownload] = useState(false);
	const [referCode, setReferCode] = useState("");
	const { isLogin, isRegistered, error, username } = useTypedSelector(
		(state) => state.auth
	);
	const { balance, bonus, bonusAccess } = useTypedSelector(
		(state) => state.account.account
	);
	const updateData = useRef();

	const dropMenuRef = useRef(null);
	const menuButtonRef = useRef(null);

	const handleClickOutside = (e) => {
		if (dropMenuRef.current && dropMenuRef.current.contains(e.target)) {
			// setAccountDetail(true);
			return;
		} else {
			if (
				menuButtonRef.current &&
				menuButtonRef.current.contains(e.target)
			) {
				// setAccountDetail(true);
				return;
			} else {
				setAccountDetail(false);
			}
		}
	};

	useEffect(() => {
		if (isAccountDetail) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isAccountDetail]);


	const refreshTime = () => {
		const timeDisplay = document.getElementById("time");
		const dateString = new Date().toLocaleTimeString();
		const formattedString = dateString.replace(", ", " - ");
		timeDisplay.textContent = formattedString;
	};

	const updateAllData = () => {
		updateData.current = setInterval(() => {
			dispatch(getUserBalance());
			// dispatch(getJackPots());
			// dispatch(getBonus());
		}, 4000);
		dispatch(getUserBalance());
		// dispatch(getBonus());
		// dispatch(getJackPots());
	};

	const startReceivingData = useCallback(updateAllData, []);
	const stopUpdating = () => clearInterval(updateData.current);

	useEffect(() => {
		if (isLogin) {
			startReceivingData();
			// updateAllData();
			if (user_name !== "" && location.pathname.includes("gameId")) {
				setUsername("");
				setPassword("");
				navigate(routes.HOME, { replace: true });
			}
		}
		return () => {
			stopUpdating();
		};
	}, [isLogin]);

	useEffect(() => {
		if (location.pathname === "/login") {
			if (!isLogin) {
				setLoginModal(true)
			} else {
				navigate('/');
			}
		}
		if (location.pathname === "/register") {
			if (!isLogin) {
				setOpen(true);
				const refer = location.search.split('=')[1];
				setReferCode(refer);
			} else {
				navigate('/');
			}
		}
		if (location.pathname === "/") {
			setLoginModal(false);
			setOpen(false);
			setIsForgotModal(false);
		}
		if (location.pathname === "/forgot") {
			if (!isLogin) {
				setIsForgotModal(true);
			} else {
				navigate('/');
			}
		}
	}, [location]);

	useEffect(() => {
		setInterval(refreshTime, 1000);
		const time_zone = new Date()
			.toString()
			.match(/([A-Z]+[\+-][0-9]+)/)[1]
			.replace(/0/g, "");
		SetTimeZone(time_zone);
		// dispatch(setLogout());
		dispatch(cleanError());
		console.log(osName);
		if (osName === "iOS") {
			setDownload(true)
		}
		console.log(bonusAccess);
	}, []);

	useEffect(() => {
		if (error) {
			Store.addNotification({
				title: "Failed!",
				message: error,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
				},
			});
			dispatch(cleanError());
		}
	}, [error]);

	useEffect(() => {
		if (isRegistered) {
			Store.addNotification({
				title: "Register Success!",
				message:
					"We sent message to your email address. Please verify your email account.",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
			});
			dispatch(setLogout());
		}
	}, [isRegistered]);

	const handleClose = (is_login, is_register, is_forgot, is_reset) => {
		setOpen(is_register);
		setLoginModal(is_login);
		setIsForgotModal(is_forgot);
		setResetPassModal(is_reset)
	};

	const tappedLogin = () => {
		const logindata = {
			username: user_name,
			password: pass_word,
		};
		dispatch(loginUser(logindata));
	};

	const handleSignOut = async () => {
		setAccountDetail(false);
		dispatch(setLogout());
		await encryptedStorage.setItem('remember', 'forgot');
		await encryptedStorage.removeItem('token');
		await encryptedStorage.removeItem('refresh');
		navigate('/');
	}

	const handleAccountDetail = () => {
		setAccountDetail(false);
		navigate(routes.ACCOUNT, { state: 3 })
	}

	const handleBonus = () => {
		console.log("asdf");
		dispatch(getBonus());
	}

	return (
		<HeaderOutContainer isHidden={location.pathname.includes("gameId")}>
			<HeaderContainer>
				<HeaderTopWrapper>
					<HeaderTimezoneWrapper>
						<BiTime color="#000" style={{ fontSize: "17px" }} />
						<span id="time">14:51:59</span>
						<span>{timeZone}</span>
					</HeaderTimezoneWrapper>
					{/* <HeaderTapWrapper>
						<Tap active={step === 0 && true} onClick={() => setStep(0)}>
							Casino
						</Tap>
						<Tap active={step === 1 && true} onClick={() => setStep(1)}>
							Live Casino
						</Tap>
						<Tap active={step === 2 && true} onClick={() => setStep(2)}>
							Slots
						</Tap>
					</HeaderTapWrapper> */}
					<HeaderLangContainer>
						<HeaderLangWrapper>
							<LangSelect>
								<option>English</option>
								<option>Deutsch</option>
								<option>Français</option>
								<option>日本語</option>
								<option>Русский</option>
								<option>Português</option>
							</LangSelect>
							<FaQuestionCircle color="#b3a25d" style={{ fontSize: "23px" }} />
						</HeaderLangWrapper>
					</HeaderLangContainer>
				</HeaderTopWrapper>
				<HeaderLogoContainer>
					<HeaderLogoWrapper>
						<FaBars
							color="#fff"
							size="33px"
							className="res-bar-icon"
							onClick={setSide}
						/>
						<HeaderLogo
							src={Logo}
							alt=""
							draggable="false"
							onClick={() => navigate("/")}
						/>
						<LoginWrapper>
							{isLogin ? (
								<div>
									<LoginUserContainer>
										<DepositBtn onClick={handleBonus} disabled={bonusAccess ? false : true}>
											Active Bonus
										</DepositBtn>
										<LoginUserWrapper>
											{/* <strong>{"Balance: "}</strong> */}
											{bonus >= 999999 && <span>{Number(999999).toLocaleString("en-US", { style: "currency", currency: "ZAR" })}</span>}
											{bonus < 999999 && <span>{bonus.toLocaleString("en-US", { style: "currency", currency: "ZAR" })}</span>}
										</LoginUserWrapper>
										<DepositBtn onClick={() => navigate('/account', { state: 1 })}>
											Deposit
										</DepositBtn>
										<LoginUserWrapper>
											{/* <strong>{"Balance: "}</strong> */}
											{balance >= 999999 && <span>{Number(999999).toLocaleString("en-US", { style: "currency", currency: "ZAR" })}</span>}
											{balance < 999999 && <span>{balance.toLocaleString("en-US", { style: "currency", currency: "ZAR" })}</span>}
										</LoginUserWrapper>
										<LoginUserWrapper onClick={() => setAccountDetail(!isAccountDetail)} ref={menuButtonRef}>
											<svg
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												width="28"
												height="28"
												viewBox="0 0 24 24"
											>
												<path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2c-4.97 0-9 4.03-9 9 0 2.194.785 4.204 2.09 5.766C6.712 15.93 9.038 15 12.006 15c3.073 0 5.461.84 7.104 2.515C20.295 15.993 21 14.08 21 12c0-4.97-4.03-9-9-9zm0 2.4c2.025 0 3.667 1.642 3.667 3.667S14.025 12.733 12 12.733s-3.667-1.641-3.667-3.666S9.975 5.4 12 5.4z"></path>
											</svg>
											{
												isAccountDetail && <InfoContainer ref={dropMenuRef}>
													<InfoWarpper>
														<UserIcon>
															<RiUser6Line size={20} color="#fff" />
														</UserIcon>
														<UserInfo>
															<span>{username}</span>
															<p>{balance.toLocaleString("en-US", { style: "currency", currency: "ZAR" })}</p>
														</UserInfo>
													</InfoWarpper>
													<CustomBtn onClick={handleAccountDetail}>
														<RiSettings5Line />
														<span>Account Details</span>
													</CustomBtn>
													<hr />
													<CustomBtn onClick={handleSignOut}>
														<MdOutput />
														<span>Sign Out</span>
													</CustomBtn>
												</InfoContainer>
											}
											{/* <span>{username}</span> */}
										</LoginUserWrapper>

									</LoginUserContainer>
								</div>
							) : (
								<div style={{ display: "flex" }}>
									<LoginInputWrapper>
										<Inputs>
											<div>
												<input
													type="text"
													placeholder="Username/Email"
													onChange={(e) => setUsername(e.target.value)}
													value={user_name}
												/>
											</div>
											<div>
												<input
													type={isShow ? "text" : "password"}
													placeholder="Password"
													onChange={(e) => setPassword(e.target.value)}
													value={pass_word}
												/>{" "}
												{isShow ? <BsEye color="#000" onClick={() => setShow(false)} /> : <BsEyeSlash color="#000" onClick={() => setShow(true)} />}
											</div>
										</Inputs>
										<span onClick={() => setIsForgotModal(true)}>
											Forgotten Password?
										</span>
									</LoginInputWrapper>
									<LoginBtnsWrapper>
										<LoginBtn onClick={() => tappedLogin()}>LOGIN</LoginBtn>
										<MobileLoginBtn onClick={() => navigate(routes.LOGIN)}>
											LOGIN
										</MobileLoginBtn>
										<JoinBtn onClick={() => navigate(routes.REGISTER)}>
											JOIN NOW
										</JoinBtn>
									</LoginBtnsWrapper>
								</div>
							)}
						</LoginWrapper>
					</HeaderLogoWrapper>
				</HeaderLogoContainer>
			</HeaderContainer>
			<ForgotpwdModal isOpen={isForgotModal} handleClose={handleClose} />
			<LoginModal isOpen={isLoginModal} handleClose={handleClose} />
			<RegisterModal isOpen={open} handleClose={handleClose} referCode={referCode} />
			<DownloadContainer flag={isDownload}>
				<MdOutlineClose color="#000" cursor={"pointer"} size={25} style={{ marginRight: "12px" }} onClick={() => setDownload(true)} />
				<DownloadButton>
					<a href="https://vivaslots.co.za/download/vivaslots.apk" download>Download</a>
				</DownloadButton>
			</DownloadContainer>
		</HeaderOutContainer>
	);
};

export default Header;
