import styled from "styled-components";
import NoticeImg from "../../assets/image/notice.jpg"

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-image: url(${NoticeImg});
    background-size: 100% 100%;
    padding: 30px;
    padding-top: 60px;
    border-radius: 10px;
    width: 800px;
    overflow: auto;
	@media screen and (max-width: 820px) {
		width: 85% !important;
		/* max-width: unset; */
		/* height: 100vh; */
	}
`

export const ModalContext = styled.p`
    font-size: 20px;
    font-family: 'RobotoRegular';
    color: #FFD700;
    margin: 8px !important;
    text-align: start;
`

export const ModalCloseBtn = styled.div`
    border: 1px solid #FFD700;
    padding: 7px 25px;
    font-family: 'RobotoRegular';
    border-radius: 5px;
    margin-top: 15px;
    align-self: flex-end;
    color: #FFD700;
    cursor: pointer;
`