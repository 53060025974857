import styled from "styled-components";

export const ScrollContainer = styled.div`
    background-color: #aea269;
    position: fixed;
    width: 53.19px;
    height: 53.19px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 11;
    bottom: 50px;
    right: 50px;
    justify-content: center;
    @media screen and (max-width:820px){
        display: none;
    }
`