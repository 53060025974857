import React, { useEffect } from "react";
import Tabs from "../../components/Tabs";

// styled
import {
	AboutUsContainer,
	AboutUsWrapper,
	AboutUsTitle,
	AboutUsContent,
	AboutUsTermsContainer,
	AboutUsTermsTitle,
} from "./styled";

const Gambling = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Tabs tag={0} />
			<AboutUsContainer>
				<AboutUsWrapper>
					<AboutUsTitle>Responsible Gambling</AboutUsTitle>
					<AboutUsContent>
						<span>
							Before you log in to Viva Slots it is important to familiarize
							yourself with our fair gaming policies, as well as the steps we've
							taken to ensure a responsible and safe playing environment for
							you.
						</span>
						<ul>
							<li>Full Transparency for Fair Gaming</li>
							<li>No Under-Age Gambling</li>
							<li>Staying in Control</li>
							<li>Identifying the Signs of a Gambling Addiction</li>
						</ul>
					</AboutUsContent>
					<AboutUsTermsContainer>
						<AboutUsTermsTitle>
							Full transparency for fair gaming
						</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								Viva Slots has a solid reputation both within the gaming
								industry and amongst our players for providing full
								transparency. This means that you will always have access to
								your game and financial history, including each wager you've
								placed, the time and date it was placed, winnings, and game
								results. All financial transactions are also a part of your
								detailed financial history with us at Viva Slots.
							</span>
						</AboutUsContent>
						<AboutUsTermsTitle>
							License and regulation by the MGA
						</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								We operate under a license issued by the{" "}
								<a href="https://www.authorisation.mga.org.mt/verification.aspx?lang=es&amp;company=f4c23fb2-a224-4378-8ab7-a8d053d5e43b">
									{" "}
									Malta Gaming Authority
								</a>{" "}
								(MGA), including a binding contract to uphold their regulations.
								Deviating from the MGA-approved and permitted manner of business
								would result in the immediate annulment of our license. These
								regulations ensure that all our related online gaming activities
								meet four basic principles:
							</span>
							<ul>
								<li>
									License holders, their associates and key personnel meet
									corporate and personal standards of integrity, and are
									suitable to operate.
								</li>
								<li>
									The software, which determines the odds for games, meets
									internationally recognized standards of fair gaming.
								</li>
								<li>
									Casino operators' systems are not used for criminal activity.
								</li>
								<li>
									Players receive fair treatment, and winnings are paid out in
									accordance with the interactive gaming and wagering
									regulations.
								</li>
							</ul>
						</AboutUsContent>
						<AboutUsTermsTitle>
							Gaming Laboratories International
						</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								Additionally, all of our games are independently tested by GLI –
								Gaming Laboratories International. GLI is a large and
								well-respected firm that carries out independent tests on the
								fairness and accuracy of casino games. We are proud to display
								the GLI payout calculation on all of our pages.
							</span>
							<span>
								Note: all our gaming software is written by PlayTech, the
								leading software provider in the gaming industry. Therefore, the
								GLI payout calculation letters feature PlayTech's name. As a
								PlayTech client, we use their software without alteration and
								therefore display this logo and the compliance letter that it
								links to.
							</span>
							<AboutUsTermsTitle>No under-age gambling</AboutUsTermsTitle>
							<AboutUsContent>
								<span>
									Gambling under the age of 18 is illegal in all international
									jurisdictions and is strictly prohibited in our{" "}
									<a href="/terms-of-use"> Terms and Conditions</a> (see Section
									3 – Legal Requirements).
								</span>
								<span>
									<b>Parental Precaution:</b> Please note that while we have
									taken the utmost precaution to block minors from accessing our
									platform, it is impossible for us to catch every single
									instance of unsupervised under-age activity. As such, we
									encourage parents to prevent their children from using their
									account by installing ‘site-filter' programs such as NetNanny.
								</span>
							</AboutUsContent>
						</AboutUsContent>
						<AboutUsTermsTitle>Staying in control</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								To ensure that your gambling experience remains positive and
								entertaining, we advise that you adhere to the following
								guidelines:
							</span>
							<ul>
								<li>
									Decide how much you're willing to lose before you begin
									playing, and never go over that amount.
								</li>
								<li>Do not borrow money to gamble.</li>
								<li>Choose a time limit, and stick to it.</li>
								<li>Take frequent breaks.</li>
								<li>Engage in other activities for fun, besides gambling.</li>
								<li>
									Never gamble when you're worried, stressed or depressed.
								</li>
								<li>
									Only use money that you've set aside specifically for
									recreational purposes.
								</li>
							</ul>
						</AboutUsContent>
						<AboutUsTermsTitle>Gambling Aware</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								For more information about gambling responsibly, please visit
								the{" "}
								<a href="https://www.begambleaware.org/">
									Gamble Aware website
								</a>
								.
							</span>
						</AboutUsContent>
						<AboutUsTermsTitle>
							Identifying the signs of a gambling addiction
						</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								Responsible gambling means setting and sticking to self-imposed
								limits on the amount of time and money you spend at the virtual
								tables. Habitual deviation from these self-imposed limits could
								indicate an addiction. If you suspect that you have a gambling
								addiction, please answer the questions in the following
								questionnaire.
							</span>
							<span>
								1. Have you ever missed school or work because of gambling?
							</span>
							<span>
								2. Has your home life been negatively affected by your gambling?
							</span>
							<span>3. Has your reputation been affected by gambling?</span>
							<span>
								4. Have you ever tried to solve financial problems or pay off
								debts by gambling?
							</span>
							<span>
								5. When you lose, do you feel a strong urge to keep playing in
								order to win back your losses?
							</span>
							<span>
								6. When you win, do you feel a strong urge to keep playing in
								order to win more?
							</span>
							<span>
								7. Have you repeatedly gambled your money away (down to the last
								dollar)?
							</span>
							<span>
								8. Have you ever financed your gambling by borrowing money?
							</span>
							<span>
								9. Have you ever financed your gambling by selling something?
							</span>
							<span>
								10. Have you ever stopped yourself from using "gambling money"
								for normal expenditures?
							</span>
							<span>
								11. Have you ever gambled at the expense of your family's
								welfare?
							</span>
							<span>12. Have you ever gambled for longer than planned?</span>
							<span>
								13. Have you ever thought about/committed an illegal act to fund
								your gambling?
							</span>
							<span>
								14. Have you had difficulty sleeping as a result of your
								gambling?
							</span>
							<span>15. When frustrated, do you have an urge to gamble?</span>
							<span>
								16. When you want to celebrate good fortune, does gambling sound
								like a good way to do so?
							</span>
							<span>17. Have you ever felt regret after gambling?</span>
							<span>
								18. Have you ever experienced a decrease in efficiency or
								ambition as a resulting of your gambling?
							</span>
							<span>
								19. Have you ever gambled in an attempt to avoid everyday
								worries and/or troubles?
							</span>
							<span>20. Has gambling ever caused you to consider suicide?</span>
							<span>
								According to Gamblers Anonymous, answering "yes" to seven or
								more questions indicates the possibility of a gambling
								addiction, and in such cases we strongly advise that you seek
								professional assistance.
							</span>
						</AboutUsContent>
					</AboutUsTermsContainer>
				</AboutUsWrapper>
			</AboutUsContainer>
		</React.Fragment>
	);
};

export default Gambling;
