import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    WithdrawContainer,
    WithdrawWrapper,
    WithdrawMethod,
    Withdrawable,
    WithdrawableContainer,
    WithdrawAmount,
    CustomInput,
    WithdrawContent,
    BankWithdrawContainer,
    MakeWithdrawBtn,
    CryptoWithdrawContainer,
    ErrorMsg
} from './styled';
import BankTransfer from '../../assets/image/payment/banktransfer.png';
import Crypto from '../../assets/image/payment/crypto.png';
import { useTypedDispatch, useTypedSelector } from '../../store/typedHooks';
import { WithdrawBank, WithdrawCrypto } from '../../api/account';
import { setClearPayment } from '../../store/slices/account';
import { Store } from "react-notifications-component";

const Withdraw = ({ balance }) => {
    const dispatch = useTypedDispatch();
    const [isBankWithdraw, setBankWithdraw] = useState(false);
    const [isCryptoWithdraw, setCryptoWithdraw] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [bankInfo, setBankInfo] = useState({});
    const [cryptoInfo, setCryptoInfo] = useState({});
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const { withdraw_message, error, amount_unlock } = useTypedSelector((state) => state.account);
    const [cryptoAmount, setCryptoAmount] = useState('');
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (withdraw_message === 'OK') {
            Store.addNotification({
                title: "Success!",
                message: 'Your withdraw request where sent to operator, the request will be processed in order of priority',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
            dispatch(setClearPayment({ message: '' }));
        }
    }, [withdraw_message]);

    useEffect(() => {
        if (error === 'Withdrawal error!') {
            Store.addNotification({
                title: "Failed!",
                message: `You are not allowed to make withdraw due to WagerBonus, your account balance should be not less than ${amount_unlock}  to unlock withdraw.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
            dispatch(setClearPayment({ message: '' }));
        }
    }, [error])

    const clearAll = () => {
        setCryptoAmount('');
        setWithdrawAmount('');
        setBankInfo({});
        setCryptoInfo({});
        setErrorMsg('');
    }

    const handleBankTransfer = () => {
        setOpen(true);
        setBankWithdraw(true);
        setCryptoWithdraw(false);
        clearAll();
    }

    const handleCrypto = () => {
        setOpen(true);
        setCryptoWithdraw(true);
        setBankWithdraw(false);
        clearAll();
    }

    const handleBankWithdraw = () => {
        if (balance < withdrawAmount) {
            setErrorMsg("Withdrawal amount exceeded.");
        } else {
            if (withdrawAmount < 2000) {
                setErrorMsg("The withdrawal amount must be at min ZAR 2000.")
            } else {
                if (bankInfo.swift === ("" || undefined)) {
                    setErrorMsg("Please input SWIFT code.");
                    return;
                }
                if (bankInfo.iban === ("" || undefined)) {
                    setErrorMsg("Please input IBAN.");
                    return;
                }
                if (bankInfo.firstname === ("" || undefined)) {
                    setErrorMsg("Please input firstname.");
                    return;
                }
                if (bankInfo.lastname === ("" || undefined)) {
                    setErrorMsg("Please input lastname.");
                    return;
                }
                if (bankInfo.email === ("" || undefined)) {
                    setErrorMsg("Please input email.");
                    return;
                }
                if (bankInfo.phone === ("" || undefined)) {
                    setErrorMsg("Please input phone.");
                    return;
                }
                if (bankInfo.address === ("" || undefined)) {
                    setErrorMsg("Please input address.");
                    return
                }
                if (bankInfo.city === ("" || undefined)) {
                    setErrorMsg("Please input city.");
                    return;
                }
                if (bankInfo.postalCode === ("" || undefined)) {
                    setErrorMsg("Please input postal code.");
                    return;
                }
                const bankInfoData = {
                    amount: Number(withdrawAmount),
                    ...bankInfo
                }
                dispatch(WithdrawBank(bankInfoData));
            }
        }
    }

    const handleCryptoWithdraw = () => {
        if (balance < withdrawAmount) {
            setErrorMsg("Withdrawal amount exceeded.");
        } else {
            if (withdrawAmount < 200) {
                setErrorMsg("The withdrawal amount must be at min ZAR 200.")
            } else {
                if (cryptoInfo.currency) {
                    if (cryptoInfo.address) {
                        const cryptoInfoData = {
                            amount: Number(withdrawAmount),
                            amountCrypto: Number(cryptoAmount),
                            ...cryptoInfo
                        }
                        setErrorMsg('');
                        dispatch(WithdrawCrypto(cryptoInfoData));
                    } else {
                        setErrorMsg('Please input the cryptocurrency address you wish to withdraw.');
                    }
                } else {
                    setErrorMsg("Plese select the cryptocurrency you want to withdraw.")
                }
            }
        }
    }

    const handleSelect = async (e) => {
        const crypto_Type = e.target.value;
        setCryptoInfo({ ...cryptoInfo, currency: crypto_Type });
        if (withdrawAmount) {
            const response = await axios.get('https://api.coinbase.com/v2/exchange-rates?currency=ZAR');
            const zarRate = response.data.data.rates[crypto_Type];
            const crypto_amount = withdrawAmount * zarRate;
            setCryptoAmount(crypto_amount.toFixed(5))
        }
    }

    const handleWithdrawAmount = async (e) => {
        const amount = e.target.value;
        setWithdrawAmount(amount);
        if (amount == 0) {
            setCryptoAmount('');
        } else {
            if (cryptoInfo.currency) {
                const response = await axios.get('https://api.coinbase.com/v2/exchange-rates?currency=ZAR');
                const zarRate = response.data.data.rates[cryptoInfo.currency];
                const crypto_amount = amount * zarRate;
                setCryptoAmount(crypto_amount.toFixed(5))
            }
        }

    }

    return (
        <React.Fragment>
            <WithdrawContainer>
                <WithdrawWrapper>
                    <span>Withdraw from areacasino</span>
                    <Withdrawable>
                        <span>Current Withdrawable Balance:</span>
                        <p>{balance.toLocaleString("en-US", { style: "currency", currency: "ZAR" })}</p>
                    </Withdrawable>
                    <span>Withdrawal methods</span>
                    <WithdrawMethod>
                        <img src={BankTransfer} onClick={handleBankTransfer} alt='bank' className={isBankWithdraw && 'selected-img'} />
                        <img src={Crypto} onClick={handleCrypto} alt='crypto' className={isCryptoWithdraw && 'selected-img'} />
                    </WithdrawMethod>
                    {isOpen && <WithdrawableContainer>
                        <WithdrawAmount>
                            <CustomInput>
                                <span>Amount to Withdraw</span>
                                <input placeholder='Amount to Withdraw' value={withdrawAmount} onChange={(e) => handleWithdrawAmount(e)} />
                            </CustomInput>
                            {
                                isBankWithdraw && <CustomInput>
                                    <input type='hidden' readOnly />
                                </CustomInput>
                            }
                            {
                                isCryptoWithdraw && <CustomInput>
                                    <span>Amount Crypto</span>
                                    <input value={cryptoAmount} readOnly />
                                </CustomInput>
                            }
                        </WithdrawAmount>
                        {isBankWithdraw && <BankWithdrawContainer>
                            <WithdrawContent>
                                <CustomInput>
                                    <span>SWIFT code</span>
                                    <input placeholder='SWIFT code' value={bankInfo.swift} onChange={(e) => setBankInfo({ ...bankInfo, swift: e.target.value })} />
                                </CustomInput>
                                <CustomInput>
                                    <span>IBAN</span>
                                    <input placeholder='IBAN' value={bankInfo.iban} onChange={(e) => setBankInfo({ ...bankInfo, iban: e.target.value })} />
                                </CustomInput>
                            </WithdrawContent>
                            <WithdrawContent>
                                <CustomInput>
                                    <span>First Name</span>
                                    <input placeholder='First Name' value={bankInfo.firstname} onChange={(e) => setBankInfo({ ...bankInfo, firstname: e.target.value })} />
                                </CustomInput>
                                <CustomInput>
                                    <span>Last Name</span>
                                    <input placeholder='Last Name' value={bankInfo.lastname} onChange={(e) => setBankInfo({ ...bankInfo, lastname: e.target.value })} />
                                </CustomInput>
                            </WithdrawContent>
                            <WithdrawContent>
                                <CustomInput>
                                    <span>Email</span>
                                    <input placeholder='email' value={bankInfo.email} onChange={(e) => setBankInfo({ ...bankInfo, email: e.target.value })} />
                                </CustomInput>
                                <CustomInput>
                                    <span>Phone</span>
                                    <input placeholder='phone' value={bankInfo.phone} onChange={(e) => setBankInfo({ ...bankInfo, phone: e.target.value })} />
                                </CustomInput>
                            </WithdrawContent>
                            <WithdrawContent>
                                <CustomInput>
                                    <span>Address</span>
                                    <input placeholder='address' value={bankInfo.address} onChange={(e) => setBankInfo({ ...bankInfo, address: e.target.value })} />
                                </CustomInput>
                                <CustomInput>
                                    <span>City</span>
                                    <input placeholder='city' value={bankInfo.city} onChange={(e) => setBankInfo({ ...bankInfo, city: e.target.value })} />
                                </CustomInput>
                            </WithdrawContent>
                            <WithdrawContent>
                                <CustomInput>
                                    <span>Postal Code</span>
                                    <input placeholder='Postal Code' value={bankInfo.postalCode} onChange={(e) => setBankInfo({ ...bankInfo, postalCode: e.target.value })} />
                                </CustomInput>
                                <CustomInput>
                                    <input type='hidden' placeholder='phone' />
                                </CustomInput>
                            </WithdrawContent>
                            <ErrorMsg>{errorMsg}</ErrorMsg>
                            <MakeWithdrawBtn onClick={handleBankWithdraw}>
                                Make A Withdrawal
                            </MakeWithdrawBtn>
                        </BankWithdrawContainer>}
                        {isCryptoWithdraw && <CryptoWithdrawContainer>
                            <WithdrawContent>
                                <CustomInput>
                                    <span>Convert to</span>
                                    <select value={cryptoInfo.currency} onChange={(e) => handleSelect(e)}>
                                        <option value="" >Select Coin</option>
                                        <option value="BTC" >Bitcoin</option>
                                        <option value="LTC">Litecoin</option>
                                        <option value="BCH">Bitcoin cash</option>
                                        <option value="ADA">Cardano</option>
                                        <option value="DOGE">Dogecoin</option>
                                        <option value="ETH">Ethereum</option>
                                        <option value="USDT">Tether USD ERC-20 token</option>
                                        <option value="USDT">Tether USD TRC-20 token</option>
                                        <option value="XRP">Ripple</option>
                                    </select>
                                </CustomInput>
                                <CustomInput>
                                    <span>Address</span>
                                    <input placeholder='Address' name='address' key="address" value={cryptoInfo.address} onChange={(e) => setCryptoInfo({ ...cryptoInfo, address: e.target.value })} />
                                </CustomInput>
                            </WithdrawContent>
                            <ErrorMsg>{errorMsg}</ErrorMsg>
                            <MakeWithdrawBtn onClick={handleCryptoWithdraw}>
                                Make A Withdrawal
                            </MakeWithdrawBtn>
                        </CryptoWithdrawContainer>}
                    </WithdrawableContainer>}
                </WithdrawWrapper>
            </WithdrawContainer>
        </React.Fragment>
    )
}

export default Withdraw;