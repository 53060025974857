import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { RiStarSFill } from "react-icons/ri";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { AiFillTag } from "react-icons/ai";
// logos
import eighteen from "../../assets/image/bottom-logos/18.png";
import mga from "../../assets/image/bottom-logos/mga.png";
import gli from "../../assets/image/bottom-logos/gli.png";
import playtech from "../../assets/image/bottom-logos/playtech_white.png";
import partner from "../../assets/image/bottom-logos/europartners.png";
import gambleaware from "../../assets/image/bottom-logos/begambleaware.png";

// payment image
import paymentIm from "../../assets/image/footer-en.png";
import { routes } from "../../constants/routes";
import { useTypedDispatch, useTypedSelector } from "../../store/typedHooks";
import { launchGame } from "../../api/game";
import { setActiveProvider } from "../../store/slices/game";
import { onUpdateGameData } from "../../pages/Landing";
import voucherImg from '../../assets/image/payment/voucher.png';
import eftImg from '../../assets/image/payment/eft.png';

import {
	FooterContainer,
	FooterWrapper,
	LegalPages,
	BottomLogos,
	FooterStickyContainer,
	FooterNew,
	FooterOutContainer,
	FooterStickyTabContainer,
	FooterSticklyTabItemWrapper,
	FooterStickyNewListContainer,
	MobileRegalPages,
	FooterAllGames,
	PaymentLogosContainer,
	ScrollContainer,
	FooterListWrapperItemHover,
	FooterListWrapperItem,
	FooterNumberItem,
} from "./styled";

const Footer = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useTypedDispatch();
	const [selectedNew, setSelectedNew] = useState(true);
	const [newgameList, setNewGameList] = useState([]);
	const { gamesList } = useTypedSelector((state) => state.game);
	const { isLogin } = useTypedSelector((state) => state.auth);
	const bottomLogos = [
		{
			url: eighteen,
			alt: "18",
		},
		{
			url: mga,
			alt: "MGA",
		},
		{
			url: gli,
			alt: "GLI",
		},
		{
			url: playtech,
			alt: "Playtech",
		},
		{
			url: partner,
			alt: "Europartner",
		},
		{
			url: gambleaware,
			alt: "Begambleaware",
		},
	];

	const PaymentLogos = [
		{
			url: voucherImg,
			alt: 'voucher'
		},
		{
			url: eftImg,
			alt: 'eft'
		}
	]

	const toTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		const newGames_arr = gamesList.filter((item) => item.release !== "OLD");
		const new_arr_list = onUpdateGameData(newGames_arr);
		setNewGameList(new_arr_list);
	}, [gamesList]);

	useEffect(() => {
		const stickyContainer = document.getElementById("footer-sticky-container");

		const footer = document.getElementById("footer");
		// if (footer.getBoundingClientRect().top <= window.innerHeight) {
		// 	stickyContainer.style.position = "absolute";
		// 	stickyContainer.style.top = 0;
		// }
		document.addEventListener("scroll", () => {
			if (footer.getBoundingClientRect().top <= window.innerHeight) {
				stickyContainer.style.position = "absolute";
				// stickyContainer.style.top = `${
				// 	footer.offsetTop - stickyContainer.offsetHeight
				// }px`;
				stickyContainer.style.top = 0;
			} else {
				stickyContainer.style.position = "fixed";
				stickyContainer.style.top = "auto";
			}
		});
	});

	const onTaped = (tabname) => {
		const bottomeContainer = document.getElementById("footer-bottom-container");
		if (tabname === "new") {
			setSelectedNew(!selectedNew);
			if (!selectedNew) {
				bottomeContainer.style.top = "33px";
			} else {
				bottomeContainer.style.top = "183px";
			}
		} else if (tabname === "all") {
			dispatch(setActiveProvider("ALL"));
			if (location.pathname !== routes.HOME) navigate(routes.HOME);
		} else {
			console.log("favorite");
		}
	};

	const tappedPlay = async (gameItem) => {
		if (isLogin) {
			const newGame = await dispatch(launchGame(gameItem["gameName"])).unwrap();
			if (newGame) {
				navigate(`/gameId/${gameItem["gameName"]}`, {
					state: {
						displayName: gameItem["displayName"],
						gameURL: newGame["url"],
					},
				});
			}
		} else navigate(routes.LOGIN);
	};

	const tappedDemo = async (gameItem) => {
		if (isLogin) {
			navigate(`/gameId/${gameItem["gameName"]}`, {
				state: {
					displayName: gameItem["displayName"],
					gameURL: gameItem["demo_url"],
				},
			});
		} else navigate(routes.LOGIN);
	};

	return (
		<FooterOutContainer
			id="footer"
			isHidden={location.pathname.includes("gameId")}
		>
			<div>
				<FooterStickyContainer
					id="footer-sticky-container"
					isHidden={selectedNew}
				>
					<FooterStickyTabContainer>
						<FooterNew>
							<FooterSticklyTabItemWrapper>
								<AiFillTag color={selectedNew ? "fff" : "#448635"} size={20} />
								<span
									style={selectedNew ? { color: "#fff" } : { color: "#448635" }}
									onClick={() => onTaped("new")}
								>
									New
								</span>
							</FooterSticklyTabItemWrapper>
							<FooterSticklyTabItemWrapper>
								<RiStarSFill color={"#fff"} size={20} />
								<span onClick={() => onTaped("fav")}>Favorites</span>
							</FooterSticklyTabItemWrapper>
						</FooterNew>
						<FooterAllGames>
							<FooterSticklyTabItemWrapper>
								<BsFillGrid3X3GapFill color={"#fff"} size={20} />
								<span onClick={() => onTaped("all")}>All Games</span>
								<FooterNumberItem>{gamesList.length}</FooterNumberItem>
							</FooterSticklyTabItemWrapper>
						</FooterAllGames>
					</FooterStickyTabContainer>
					<FooterStickyNewListContainer isHidden={selectedNew}>
						{newgameList.map((newitem, index) => {
							return (
								<FooterListWrapperItem imgurl={newitem.url} key={index}>
									<FooterListWrapperItemHover>
										<span id="firstspan" onClick={() => tappedPlay(newitem)}>
											Play Now
										</span>
										{newitem.demo_url !== "" && (
											<span id="lastspan" onClick={() => tappedDemo(newitem)}>
												Demo
											</span>
										)}
									</FooterListWrapperItemHover>
								</FooterListWrapperItem>
							);
						})}
					</FooterStickyNewListContainer>
				</FooterStickyContainer>
				<FooterContainer id="footer-bottom-container">
					<FooterWrapper>
						<LegalPages>
							<li onClick={() => navigate(routes.ABOUTUS)}>About Us</li>
							<li onClick={() => navigate(routes.PRIVACY)}>Privacy Policy</li>
							<li onClick={() => navigate(routes.TERMS)}>
								Terms and Conditions
							</li>
							<li onClick={() => navigate(routes.CONTACTUS)}>Contact Us</li>
							<li onClick={() => navigate(routes.RESPONSIBLE)}>
								Responsible Gaming
							</li>
						</LegalPages>
						<BottomLogos>
							{bottomLogos.map((item, key) => (
								<li key={key}>
									<img src={item.url} alt={item.alt} />
								</li>
							))}
						</BottomLogos>
						<PaymentLogosContainer>
							{
								PaymentLogos.map((item, key) => (
									<li key={key}>
										<img src={item.url} alt={item.alt}></img>
									</li>
								))
							}
							{/* <img src={paymentIm} alt="Payment Gateways" /> */}
						</PaymentLogosContainer>
						{/* <TextContainer>
                        <div>Viva Slots is a brand operated by Universe Entertainment Services Malta Limited (C-60452). Universe Entertainment Services Malta Limited is licensed and regulated by the Malta Gaming Authority. Viva Slots holds through Universe Entertainment Services Malta Limited the following license: MGA/B2C/249/2013 (issued: August 1st, 2018).</div>
                        <div>Registered Address: Universe Entertainment Services Malta Limited, Level 3, Valletta Buildings, South Street, Valletta, VLT1103. Malta</div>
                        <div>Gambling can be addictive. Play responsibly.</div>
                    </TextContainer> */}
						<MobileRegalPages>
							{/* {mobileLegalPage.map((item, key) =>
                            <li key={key}>{item}</li>
                        )} */}
							<li onClick={() => navigate(routes.PRIVACY)}>Privacy Policy</li>
							<li onClick={() => navigate(routes.TERMS)}>
								Terms and Conditions
							</li>
							<li onClick={() => navigate(routes.RESPONSIBLE)}>
								Responsible Gaming
							</li>
						</MobileRegalPages>
					</FooterWrapper>
					<ScrollContainer onClick={toTop}>
						<span>Top</span>
						<MdOutlineKeyboardArrowUp color="#fff" size={25} />
					</ScrollContainer>
				</FooterContainer>
			</div>
		</FooterOutContainer>
	);
};

export default Footer;
