import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import { MobileSideContainer, TopWrapper, SideBody, SideWrapper, Tabs, TabItem, LinksMenuContainer, LinksItem, ImgContainer } from "./styled";
import { MdOutlineClose } from "react-icons/md";

// import assets
import CasinoImg from "../../assets/image/casino_icon.svg";
import LiveCasinoImg from "../../assets/image/live_casino_icon.svg";
import SlotImg from "../../assets/image/slots_icon-active.svg";

import HomeImg from "../../assets/image/home_icon.svg";
import PromotionImg from "../../assets/image/promotions_icon.svg";
import LeaderImg from "../../assets/image/crown.png";
import VipImg from "../../assets/image/vip_icon.svg";
import LangImg from "../../assets/image/language_icon.svg";
import HelpImg from "../../assets/image/help_icon.svg";
import GPASImg from "../../assets/image/gpas.svg";
import { routes } from "../../constants/routes";
import { useTypedSelector } from "../../store/typedHooks";

const tabItem = [
    {
        tagName: "CASINO",
        url: CasinoImg
    },
    {
        tagName: "SLOTS",
        url: SlotImg
    },
    {
        tagName: "LIVE CASINO",
        url: LiveCasinoImg
    }
]

const menuItems = [
    {
        name: "Home",
        url: HomeImg,
        links: '/'
    },
    {
        name: "Promotions",
        url: PromotionImg,
        links: '/promotions'
    },
    {
        name: "Leaderboard",
        url: LeaderImg,
        links: '/leaderboard'
    },
    // {
    //     name: "Vip Lounge",
    //     url: VipImg,
    //     links: '/'
    // },
    // {
    //     name: "Language",
    //     url: LangImg,
    //     links: '/'
    // },
    {
        name: "Help",
        url: HelpImg,
        links: '/help'
    }
]

const svgList = [GPASImg, GPASImg, GPASImg, GPASImg, GPASImg, GPASImg, GPASImg, GPASImg, GPASImg]

const MobileSideBar = ({ isSide, setSide }) => {
    const navigate = useNavigate();
    // const [isVisible, setVisible] = useState(false);
    const [isActive, setActive] = useState(0);
    const { isLogin } = useTypedSelector((state) => state.auth);
    return <MobileSideContainer isVisible={isSide}>
        <TopWrapper>
            <span>¡HELLO!</span>
            {!isLogin && <button onClick={() => { setSide(); navigate(routes.LOGIN) }}>LOGIN</button>}
            <MdOutlineClose color="#fff" cursor={"pointer"} size={25} style={{ marginRight: "12px" }} onClick={setSide} />
        </TopWrapper>
        <SideBody>
            <SideWrapper>
                {/* <Tabs>
                    {
                        tabItem.map((item, index) =>
                            <TabItem isActive={isActive === index && true} onClick={() => setActive(index)} key={index}>
                                <img src={item.url} alt="" />
                                <span>{item.tagName}</span>
                            </TabItem>
                        )
                    }
                </Tabs> */}
                <LinksMenuContainer>
                    {
                        menuItems.map((item, index) =>
                            <LinksItem onClick={() => { navigate(item.links); setSide() }} key={index}>
                                <img src={item.url} alt='' />
                                <span>{item.name}</span>
                            </LinksItem>
                        )
                    }

                </LinksMenuContainer>
                {/* <ImgContainer>
                    {
                        svgList.map((item, index) => <img src={item} alt='' key={index} />)
                    }
                </ImgContainer> */}
            </SideWrapper>
        </SideBody>
    </MobileSideContainer>

}

export default MobileSideBar;