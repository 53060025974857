import styled from "styled-components";

export const LeaderBoardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width:820px){
        margin-top: 55px;
    }
`

export const LeaderBoardWrapper = styled.div`
    max-width: 1200px;
    background-color: #fff;
    width: calc(100% - 40px);
    /* height: 700px; */
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    padding: 20px;
`

export const LeaderBoardTitle = styled.h1`
    color: #b3a25d;
    margin: 10px 0;
    line-height: 1;
`

export const LeaderBoardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 15px;
`

export const LeaderBoardTermsContainer = styled.div`
margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
`

export const LeaderBoardTermsTitle = styled.h2`
    margin: 0;
    color: #b3a25d;
    line-height: 1;
`

export const LeaderBoardTermsContent = styled.ol`
    display: flex;
    flex-direction: column;
    margin: 14px;
    text-align: left;
    gap: 15px !important;
`