import React, { useState, useEffect } from "react";
import { ScrollContainer } from "./styled";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

const toTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
}

const ScrollTop = () => {
    const [scrollPos, setScrollPos] = useState(0);
    useEffect(() => {
        window.addEventListener('scroll', () => {
          setScrollPos(window.scrollY)
        })
      }, [])

    return scrollPos !== 0 && <ScrollContainer onClick={toTop}>
        <MdOutlineKeyboardArrowUp color="#fff" size={100}/>
    </ScrollContainer>
}

export default ScrollTop;