import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const routes = {
  account: '/accounts/balance',
  bonus: '/accounts/bonus',
  deposit: '/accounts/deposit',
  transactions: '/accounts/transactions',
  jackPot: '/jackpots/list',
  withdraw: '/accounts/withdrawal',
  payment: '/accounts/payment',
  withdrawbank: '/accounts/withdrawalBank',
  withdrawcrypto: '/accounts/withdrawalCrypto'
};

export const chargePayment = createAsyncThunk('account/payment', async (paymentData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.payment}`, paymentData);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});


export const getUserBalance = createAsyncThunk('account/balance', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.account}`);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const getBonus = createAsyncThunk('account/bonus', async (_, { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.bonus}`,);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const makeDeposit = createAsyncThunk('account/deposit', async (_, { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.deposit}`);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const getTransactions = createAsyncThunk('account/transactions', async ({ offset, limit }, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.transactions}`, {
      params: {
        offset: offset,
        limit: limit,
      },
    });
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const getJackPots = createAsyncThunk('account/jackPotList', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.jackPot}`);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: e.message,
    });
  }
});

export const getWithDrawal = createAsyncThunk(
  'account/withdrawal',
  async (withdraw, { rejectWithValue }) => {
    try {
      const response = await api.put(`${routes.withdraw}`, withdraw);
      return response.data;
    } catch (e) {
      return rejectWithValue({
        message: e.message,
      });
    }
  },
);

export const WithdrawBank = createAsyncThunk(
  'account/withdrawbank',
  async (bankInfoData, { rejectWithValue }) => {
    try {
      const response = await api.put(`${routes.withdrawbank}`, bankInfoData);
      return response.data;
    } catch (e) {
      return rejectWithValue({
        message: e.data.message,
        amountUnlock: e.data.amountUnlock
      });
    }
  },
)

export const WithdrawCrypto = createAsyncThunk(
  'account/withdrawcrypto',
  async (cryptoInfoData, { rejectWithValue }) => {
    try {
      const response = await api.put(`${routes.withdrawcrypto}`, cryptoInfoData);
      return response.data;
    } catch (e) {
      console.log(e.data);
      return rejectWithValue({
        message: e.data.message,
        amountUnlock: e.data.amountUnlock
      });
    }
  },
)
