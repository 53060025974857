import React from "react";
import { useNavigate } from 'react-router-dom'
import { DepositHelpContainer, DepositHelpWrapper, DepositHelpTitle, DepsoitHelpContent } from './styled';
import PaymentImg from '../../../assets/image/help/payment.png';
import DepositImg from '../../../assets/image/help/deposit.png';
import SuccessImg from '../../../assets/image/help/success.png';
import { routes } from "../../../constants/routes";

const DepositHelp = () => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <DepositHelpContainer>
                <DepositHelpWrapper>
                    <DepositHelpTitle>
                        How to Deposit With A Credit Card
                    </DepositHelpTitle>
                    <DepsoitHelpContent>
                        <span>
                            At Viva Slots you can deposit instantly with your credit or debit card. It’s fast, easy and secure!
                        </span>
                        <span>We accept Visa, Mastercard and Voucher.</span>
                        <span>
                            Gift Cards and prepaid cards can also be used if they are enabled for international online purchases. Make sure to check the small print on the packaging.
                        </span>
                        <span>
                            Please keep in mind there is a 9.75% processing fee charged on all credit or debit card deposits.
                        </span>
                        <h2>How to make a deposit?</h2>
                        <span>You’ll be able to deposit and start playing in no time after following these simple steps:</span>
                        <span>1. In the payment method screen, select the Mastercard or Visa icon. You will get the following screen to enter the deposit amount.</span>
                        <img src={PaymentImg} alt="payment" />
                        <span>2. Before finalizing your deposit, please ensure the credit card information you submit on the deposit screen is accurate.</span>
                        <img src={DepositImg} alt="deposit" />
                        <span>3. Click on submit. It’s possible your bank will alert you of international purchases or purchases made over the internet while trying to deposit at Viva Slots. Always remember to confirm it’s a valid transaction to avoid delays or having your transaction declined.</span>
                        <img src={SuccessImg} alt="success" />
                        <span>And that’s all! If you’re ready to try it yourself, click on <b onClick={() => navigate(routes.ACCOUNT, {state: 1})}>"Deposit Now"</b>.</span>
                    </DepsoitHelpContent>
                </DepositHelpWrapper>
            </DepositHelpContainer>
        </React.Fragment>
    )
}

export default DepositHelp;