import React, { useState, useEffect } from "react";
import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { AiOutlineArrowUp } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs"
import {
  MainBalanceConatiner,
  MainBalanceWrapper,
  MainBlanaceContent,
  PaymentButtons,
  WithdrawBtn,
  WithdrawBtnIcon,
  DepositBtn,
  DepositBtnIcon,
  TransactionContainer,
  TransactionTtile,
  SearchGroup,
  TransactionTable,
  PaginationContainer,
} from "./styled";
import { useTypedDispatch, useTypedSelector } from "../../store/typedHooks";
import { getTransactions } from "../../api/account";

const Balance = ({ balance, setValue }) => {
  const dispatch = useTypedDispatch();
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageStyle, setPageStyle] = useState(1);
  const transactions = useTypedSelector((state) => state.account.transactions);
  const tableHeader = ['#', 'Type', 'Date', 'Amount'];
  const offset = 0;
  const limit = 5;

  useEffect(() => {
    dispatch(getTransactions({ limit, offset }))
  }, []);

  useEffect(() => {
    if (transactions.total_transactions > 0) {
      const transactionData = transactions?.items?.map((item, key) => {
        return {
          ...item,
          date: (new Date(item.date)).toLocaleString(),
          amount: (item.amount).toFixed(2)
        }
      })
      const page_number = Math.ceil(transactions.total_transactions / limit);
      setPagination(transactions.total_transactions);
      setPage(page_number);
      setTableData(transactionData);
    }
  }, [transactions]);

  const previousPage = (index) => {
    if (index >= 1) {
      if (index === 1) {
        dispatch(getTransactions({ limit, offset }));
      } else {
        dispatch(getTransactions({ limit, offset: limit * (index - 1) }));
      }
      setPageStyle(index);
    }
  }

  const nextPage = (index) => {
    if (index <= page) {
      if (index === page) {
        dispatch(getTransactions({ limit: pagination % limit, offset: limit * (index - 1) }));
      } else {
        dispatch(getTransactions({ limit, offset: limit * (index - 1) }));
      }
      setPageStyle(index)
    }
  }

  return (
    <React.Fragment>
      <MainBalanceConatiner>
        <MainBalanceWrapper>
          <MainBlanaceContent>
            <p>Main Balance</p>
            <h2>{balance}<strong>ZAR</strong></h2>
            <span>Wallets Total</span>
          </MainBlanaceContent>
          <PaymentButtons>
            <WithdrawBtn onClick={() => setValue(2)}>
              <WithdrawBtnIcon>
                <AiOutlineArrowUp size={20} />
              </WithdrawBtnIcon>
              <span>Withdraw</span>
            </WithdrawBtn>
            <DepositBtn onClick={() => setValue(1)}>
              <DepositBtnIcon>
                <IoAdd size={20} />
              </DepositBtnIcon>
              <div>Deposit</div>
            </DepositBtn>
          </PaymentButtons>
        </MainBalanceWrapper>
        <TransactionContainer>
          <TransactionTtile>
            <h1>Transaction</h1>
          </TransactionTtile>
          <TransactionTable>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeader.map((item) => (
                    <TableCell key={item} style={{ minWidth: 50 }}>{item}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 && tableData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">{(index + 1) + ((pageStyle - 1) * limit)}</TableCell>
                    <TableCell component="th" scope="row">{item.type}</TableCell>
                    <TableCell component="th" scope="row">{item.date}</TableCell>
                    <TableCell component="th" scope="row">{item.amount}</TableCell>
                    <TableCell component="th" scope="row">{item.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TransactionTable>
          <PaginationContainer>
            <BsArrowLeftCircle size={25} onClick={() => previousPage(pageStyle - 1)} color={pageStyle === 1 ? "#e2e2e2" : "#000"} />
            <span>{pageStyle}</span>
            <BsArrowRightCircle size={25} onClick={() => nextPage(pageStyle + 1)} color={pageStyle === page ? "#e2e2e2" : "#000"} />
            <span>total: {page}</span>
          </PaginationContainer>
        </TransactionContainer>
      </MainBalanceConatiner>
    </React.Fragment>
  );
};

export default Balance;
