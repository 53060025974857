import styled from "styled-components";
import ContactImg from '../../assets/image/contact.png';

export const AboutUsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width:820px){
        margin-top: 55px;
    }
`

export const AboutUsWrapper = styled.div`
    max-width: 1200px;
    width: calc(100% - 80px);
    background-color: #fff;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    padding: 10px 40px 40px 40px;
`

export const AboutUsTitle = styled.h1`
    color: #b3a25d;
    margin: 10px 0;
    font-style: italic;
    line-height: 1;
`

export const AboutUsContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 15px;
`

export const AboutUsTermsContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
`

export const AboutUsTermsTitle = styled.h2`
    margin: 15px 0;
    color: #b3a25d;
    line-height: 1;
`

export const AboutUsTermsContent = styled.ol`
    display: flex;
    flex-direction: column;
    margin: 14px;
    text-align: left;
    gap: 15px !important;
`

export const ContactUsImage = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100px;
    background-position: center;
`


export const ContactUsContainer = styled.div`
    width: 100%;
    height: 100px;
    margin: 10px auto;
    background-size: auto 100%;
    background-position: center;
    background-image: url(${ContactImg});
`