import styled from "styled-components";

export const HelpContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 820px){
        margin-top: 55px;
    }
`

export const HelpWrapper = styled.div`
    max-width: 1200px;
    background-color: #fff;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 20px;
    min-height: calc(100vh - 117px);
`

export const HelpTitle = styled.h1`
    font-size: 2.5rem;
    color: #b3a25d;
    margin: 0;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 40px;
`

export const HelpContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    hr {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
`

export const HelpList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
        font-size: 20px;
        font-weight: 500;
    }

    svg {
        font-size: 20px;
        font-weight: 500;
    }
`

export const HelpContext = styled.div`
    display: ${props => props.state === true ? 'flex' : 'none'};
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    text-align: start;
    padding: 10px 15px;
    background: #f2f2f2;
    transition: all .3s ease-in-out;
`