import styled from "styled-components";

export const AccountContainer = styled.div`
    min-height: calc(100vh - 117px);
    max-width: 1200px;
    background-color: #fff;
    width: calc(100% - 40px);
    margin: auto;
    padding: 20px;
`

export const TabsContainer = styled.div`
    width: 100%;
    position: relative;
    /* margin-top: 120px; */
    @media screen and (max-width: 820px){
        margin-top: 55px;
    }
`

export const TabsWrapper = styled.div`
    border-bottom: 1px solid #4d4d4d;
    .MuiButtonBase-root {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        svg {
            margin: 0;
        }
    }
    .MuiTab-labelIcon {
        min-height: 40px;
    }
`

export const CloeseBtn = styled.div`
    display: none;
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 999;
    @media screen and (max-width: 820px){
        display: flex;
    }
`

export const MainBalanceConatiner = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 0 30px;
`

export const MainBalanceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 0;
    align-items: end;
    border-bottom: 1px solid #4d4d4d;
    @media screen and (max-width: 650px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
`

export const MainBlanaceContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
        margin: 0;
        font-size: 25px;
        font-weight: 600;
    }
    h2 {
        margin: 5px 0;
        font-size: 50px;
        display: flex;
        align-items: center;
    }
    span {
        font-size: 20px;
    }
    strong {
        padding-left: 3px;
    }
`

export const PaymentButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`

export const WithdrawBtn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`

export const WithdrawBtnIcon = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const DepositBtn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`

export const DepositBtnIcon = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #133966;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #133966;
    color: #fff;
`

export const TransactionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    align-items: flex-start;
`

export const TransactionTtile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1 {
        margin: 0;
    }
    span {
        margin-top: 10px;
        font-size: 20px;
    }
`

export const SearchGroup = styled.div`
    display: flex;
    margin: 30px 0;
    width: 100%;
    gap: 40px;
    input {
        width: 100%;
        font-size: 20px;
        height: 40px;
    }
    .search-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #133966;
        border-radius: 50px;
        height: 40px;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
    }
    @media screen and (max-width: 820px){
        gap: 20px;
    }
    @media screen and (max-width: 600px){
        gap: 10px;
    }
    @media screen and (max-width: 520px){
        flex-direction: column;
    }
    
`

export const TransactionTable = styled.div`
    width: 100%;
    overflow-y: auto;
    .MuiTableCell-head {
        font-size: 20px;
    }
`

export const PaginationContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: end;
    gap: 15px;
    svg {
        cursor: pointer;
    }
    span {
        font-size: 25px;
    }
`

export const AccountDetailConatiner = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 0 30px;
`

export const AccountDetailWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 0;
    align-items: end;
`

export const AccountDetailContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
        margin: 0;
    }
    span {
        font-size: 20px;
    }
`

export const SignOutBtn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`

export const SignOutBtnIcon = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const UserInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const UserInfoDetail = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #4d4d4d;
    padding: 10px 0;
    align-items: center;
    text-align: start;
    color: #4d4d4d;
    .user-info {
        width: 80%;
        display: flex;
        gap: 10px;
        p {
            padding-left: 10px;
            width: 100%;
        }
    }
    .lock-icon {
        width: 20%;
        display: flex;
        justify-content: end;
        padding-right: 10px;
    }
`

export const DepositContainer = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 0 30px;
    position: relative;
    @media screen and (max-width: 750px){
        padding: 0 10px;
    }
`

export const DepositWrapper = styled.div`
    display: flex;
    padding: 50px 0;
    flex-direction: column;
    align-items: flex-start;
    span {
        font-size: 35px;
        font-weight: 600;
    }
    @media screen and (max-width: 700px){
        align-items: center;
        span {
            font-size: 30px;
        }
    }
    
`

export const DepositMethod = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    img {
        cursor: pointer;
        width: 200px;
    }
    @media screen and (max-width: 700px){
        flex-direction: column;
        align-items: center;
    }
`

export const PaymentModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    border: '2px solid #000';
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        margin: 0;
        font-weight: 600;
        color: #00b36b;
        margin-bottom: 30px;
    }
    svg {
        margin-bottom: 40px;
    }

    @media screen and (max-width: 440px){
        width: calc(100% - 40px);
    }
`

export const PaymentModalContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    span {
        color: #666666;
    }
    p {
        margin: 0;
    }
`

export const PaymentModalAmount = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    span {
        color: #666666;
        font-size: 20px;
        font-weight: 600;
    }
    p {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
    }
`

export const CancelBtn = styled.div`
    display: flex;
    width: fit-content;
    padding: 10px 25px;
    background-color: #00b36b;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
`

export const PaymentImg = styled.div`
    display: flex;
    background-color: #fff;
    img {
        cursor: pointer;
        width: 250px;
    }
`

export const WithdrawContainer = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 0 30px;
    @media screen and (max-width: 750px){
        padding: 0 10px;
    }
`

export const WithdrawWrapper = styled.div`
    display: flex;
    padding: 50px 0;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    span {
        font-size: 35px;
        font-weight: 600;
    }
    @media screen and (max-width: 700px){
        /* align-items: center; */
        width: 100%;
        span {
            font-size: 30px;
        }
    }
    
`

export const Withdrawable = styled.div`
    width: calc(100% - 20px);
    border: 1px solid #666666;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 5px;
    margin: 20px 0;
    span {
        font-size: 16px;
        color: #666666;
    }
    p {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
    }
`

export const WithdrawMethod = styled.div`
    width: calc(100% - 20px);
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    img {
        cursor: pointer;
        width: 200px;
        border: 2px solid #fff;
        border-radius: 15px;
    }
    .selected-img {
        border: 2px solid #000;
    }
    @media screen and (max-width: 700px){
        flex-direction: column;
        align-items: center;
    }
`

export const WithdrawableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
`

export const WithdrawAmount = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    @media screen and (max-width: 500px){
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`

export const CustomInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    span {
        font-size: 15px;
        font-weight: 400;
    }
    input {
        font-size: 15px;
        padding: 5px 10px;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        width: calc(100% - 22px);
    }
    select {
        font-size: 15px;
        padding: 5px 10px;
        border-radius: 5px;
        width: 100%;
    }

`

export const WithdrawContent = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 500px){
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`

export const BankWithdrawContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
`

export const MakeWithdrawBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    background-color: #333399;
    border-radius: 20px;
    width: fit-content;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
`

export const CryptoWithdrawContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 15px;
`

export const ErrorMsg = styled.span`
    color: red;
    font-size: 14px !important;
    text-align: start;
    margin-top: -15px;
`