import { createSlice } from '@reduxjs/toolkit';
import {
  getBonus,
  getJackPots,
  getTransactions,
  getUserBalance,
  makeDeposit,
  chargePayment,
  WithdrawBank,
  WithdrawCrypto
} from '../../api/account';



const initialState = {
  account: {
    message: '',
    balance: 0,
    bonus: 0,
    bonusAccess: false,
  },
  message: null,
  depositUrl: null,
  loading: false,
  error: '',
  transactions: {
    total_transactions: null,
    items: [],
  },
  jackPots: [],
  fullScreen: false,
  key: '',
  url: '',
  payment_amount: 0,
  withdraw_message: '',
  amount_unlock: 0
};

export const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setFullScreen: (state, action) => {
      state.fullScreen = action.payload;
    },
    setTransaction: (state, action) => {
      state.url = action.payload.url;
    },
    setPaymentAmount: (state, action) => {
      state.payment_amount = action.payload.payment
    },
    setClearPayment: (state, action) => {
      state.withdraw_message = action.payload.message;
      state.error = '';
      state.amount_unlock = 0;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserBalance.fulfilled, (state, action) => {
      state.account.message = action.payload.message;
      state.account.balance = action.payload.balance;
      state.account.bonus = action.payload.bonus;
      state.account.bonusAccess = action.payload.bonusAccess;
    })
    builder.addCase(getUserBalance.rejected, (state, action) => {
      state.error = action.payload.message;
    })

    builder.addCase(getBonus.fulfilled, (state, action) => {
      state.account.message = action.payload.message;
      state.account.balance = action.payload.balance;
      state.account.bonus = action.payload.bonus;
      state.account.bonusAccess = action.payload.bonusAccess;
      state.loading = false;
    })
    builder.addCase(getBonus.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getBonus.rejected, (state, action) => {
      state.error = action.payload.message;
    })

    builder.addCase(makeDeposit.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.depositUrl = action.payload.url;
      state.loading = false;
    })
    builder.addCase(makeDeposit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(makeDeposit.rejected, (state, action) => {
      state.error = action.payload.message;
    })

    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.transactions.items = action.payload.items;
      state.transactions.total_transactions = action.payload.total_transactions;
    })
    builder.addCase(getTransactions.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.error = action.payload.message;
    })

    builder.addCase(getJackPots.fulfilled, (state, action) => {
      state.jackPots = action.payload;
    })
    builder.addCase(getJackPots.rejected, (state, action) => {
      state.error = action.payload.message;
    })
    builder.addCase(chargePayment.fulfilled, (state, action) => {
      state.url = action.payload.url;
      state.key = action.payload.key;
    })
    builder.addCase(chargePayment.rejected, (state, action) => {
      state.error = action.payload.message;
    })
    builder.addCase(WithdrawBank.fulfilled, (state, action) => {
      state.withdraw_message = action.payload.message
    })
    builder.addCase(WithdrawBank.rejected, (state, action) => {
      state.error = action.payload.message;
      state.amount_unlock = action.payload.amountUnlock;
    })
    builder.addCase(WithdrawCrypto.fulfilled, (state, action) => {
      state.withdraw_message = action.payload.message
    })
    builder.addCase(WithdrawCrypto.rejected, (state, action) => {
      state.error = action.payload.message;
      state.amount_unlock = action.payload.amountUnlock;
    })
  },
});

// Action creators are generated for each case reducer function
export const { setFullScreen, setTransaction, setPaymentAmount, setClearPayment } = account.actions;

export default account.reducer;
