import React from 'react';
import store, { persister } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routings from './routings';

import './App.css';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

function App() {

  window.top.closeGame = () => {
    const element = document.getElementById('gameIFrame');
    element.parentNode.removeChild(element);      
    window.location.href = process.env.REACT_APP_REDIRECT_DEV_URL;
  };

  return (
    <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <div className="App">         
          <Routings />       
      </div>
    </PersistGate>
  </Provider>
  );
}

export default App;
