import styled from "styled-components";

export const ModalContainer = styled.div`
	background: "#fff";
	position: relative;
	/* width: 400px; */
	width: 400px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	@media screen and (max-width: 820px) {
		width: 100% !important;
		max-width: unset;
		height: 100vh;
	}
`;

export const ModalHeaderImg = styled.div`
	width: calc(100% - 40px);
	background-color: #133966;
	padding: 20px;
	img {
		visibility: unset !important;
	}
`;

export const ModalHeader = styled.div`
	background: rgb(19, 57, 102);
	color: #fff;
	padding: 1em 0.8em 1em;
	font-size: 25px;
	line-height: 1.2;
	font-family: "RobotoBold";
`;

export const ModalInfo = styled.div`
	border-bottom: 1px solid #6c6c6c;
	color: #6c6c6c;
	text-transform: initial;
	font-size: 16px;
	font-weight: 600;
	padding: 10px;
`;

export const ModalWrapper = styled.div`
	background-color: #fff;
	padding: 20px;
	@media screen and (max-width: 820px) {
		height: 100%;
		padding: 0px;
	}
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px 10px;

	div {
		background-color: #fff;
		display: flex;
		height: 30px;
		padding: 0 5px;
		align-items: center;
		position: relative;
		border: 1px solid #000000;
	}
	input {
		border: none;
		outline: none;
		width: 95%;
	}

	button {
		background-color: #aea269;
		font-size: 20px;
		font-weight: 600;
		padding: 10px;
		border: none;
		color: #fff;
		border-radius: 2px;
		cursor: pointer;
	}

	span {
		text-align: start;
		font-size: 12px;
		color: red;
		margin-top: -15px;
	}
`;

export const ModalMobileHeader = styled.div`
	background-color: #133966;
	display: none;
	padding: 5px;
	@media screen and (max-width: 820px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const ModalMobileBackBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 18px;
	gap: 5px;
`;

export const ModalMobileLoginBtn = styled.button`
	background-color: #aea269;
	font-size: 16px;
	padding: 5px;
	border: none;
	color: #fff;
`;
export const ModalFooterImg = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: calc(100% - 40px);
	padding: 10px 20px;
	gap: 20px;
	button {
		background-color: #00a259;
		font-size: 20px;
		font-weight: 600;
		padding: 10px;
		border: none;
		color: #fff;
		border-radius: 5px;
		width: 100%;
	}
	img {
		visibility: unset !important;
	}
`;

export const ModalCloeBtn = styled.div`
	position: absolute;
	right: 5px;
	top: 10px;
	font-size: 20px;
	@media screen and (max-width: 820px) {
		display: none;
	}
`;
