import React, {useEffect} from "react";
import Tabs from '../../components/Tabs';

// styled
import { LeaderBoardContainer, LeaderBoardWrapper, LeaderBoardTitle, LeaderBoardContent, LeaderBoardTermsContainer, LeaderBoardTermsTitle, LeaderBoardTermsContent } from './styled'


const LeaderBoard = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <Tabs tag={3} />
            <LeaderBoardContainer>
                <LeaderBoardWrapper>
                    <LeaderBoardTitle>Daily Leaderboards!</LeaderBoardTitle>
                    <LeaderBoardContent>
                        <span>Opt-in to our <strong>Daily Leaderboards</strong> and your could win cash prizes or free spins on a daily basis!</span>
                        <span>These leaderboards are based on the size of your biggest win so a 1 <b>ZAR</b> win from a 1c bet would earn the same position as a 100 <b>ZAR</b> win from a 1 <b>ZAR</b> bet (or currency equivalent). So you don't need to break the bank to participate!</span>
                        <span>Gain points to rise to the top of the leaderboard and snag yourself some cool prizes!</span>
                    </LeaderBoardContent>
                    <LeaderBoardTermsContainer>
                        <LeaderBoardTermsTitle>
                            Daily Leaderboards Terms and Conditions
                        </LeaderBoardTermsTitle>
                        <LeaderBoardTermsContent>
                            <li>The Daily Leaderboards Promotion("Promotion"), under which you will be able to win a cash prize or free spins, will run Daily from January 1th 2023, 00:00 GMT+1 until 23:59 GMT+1 every day, until December 32st 2023 ("Promition Period").</li>
                            <li>This Promotion is open to depositing players who have registered to www.vivaslots.co.za, and have accumulated at least 1 point (or curreny equivalents) on the qualifying games</li>
                            <li>By participating in this Promotion, you are hereby deemed to have accepted and agree to be bound by these terms and conditions as well as to the standard terms and conditions located at www.vivaslots.co.za/terms-of-use.</li>
                            <li>During the Promotion Period, on a daily basis, qualified players will have the chance to win either free spins or total cash prizes.</li>
                            <li>Players can win a prize based on their leaderboard ranking. Leaderboard ranking is calculated as the highest single spin win amount adjusted to bet value. For example, 1 <b>ZAR</b> win from a 1c bet would earn the same position as a 100 <b>ZAR</b> win from a 1 <b>ZAR</b> bet (or currency equivalents)</li>
                            <li>Entry to Prize Draw: Opt-in and earn a point during the Promotional Period on any of the Daily Leaderboards, on the following qualifying slot games (the "Qualified Games"):</li>
                        </LeaderBoardTermsContent>
                    </LeaderBoardTermsContainer>
                </LeaderBoardWrapper>
            </LeaderBoardContainer>
        </React.Fragment>
    )
}

export default LeaderBoard;