import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import { ModalContainer, ModalContext, ModalCloseBtn } from "./styled";

const IntroModal = () => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        const status = localStorage.getItem('intro');
        if (status === "true") {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [])

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('intro', true);
    }

    return (
        <Modal
            open={open}
            // onClose={() => setOpen(false)}
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <ModalContainer>
                <ModalContext>Hi and welcome to Viva Slots</ModalContext>
                <ModalContext>
                    We would like to take this opportunity to introduce you to Viva Slots.
                    Recently launched in South Africa, Viva Slots is an exciting new gaming
                    site with an extensive range of thrilling new games, which we would love
                    you to try out.
                </ModalContext>
                <ModalContext>
                    Brand new to South Africa, Viva Slots, is offering you all their games
                    free of charge, to play for fun.  Join Viva Slots on a merry-go-round of
                    endless fun and entertainment as you test our new
                    and exciting games.
                </ModalContext>
                <ModalContext>
                    Simply log in to Vivaslots.co.za and register today.  There is no charge and no fees.
                    Just hours of fun and play.
                </ModalContext>
                <ModalContext>
                    Viva Slots – we aim to entertain!
                </ModalContext>
                <ModalCloseBtn onClick={handleClose}>Close</ModalCloseBtn>
            </ModalContainer>
        </Modal >
    )
}

export default IntroModal;