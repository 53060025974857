import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutput } from 'react-icons/md';
import { BiLock } from 'react-icons/bi'
import { useTypedDispatch } from '../../store/typedHooks';
import { setLogout } from '../../store/slices/auth';
import { encryptedStorage } from '../../utils/encryptStorage';
import { AccountDetailConatiner, AccountDetailWrapper, AccountDetailContent, SignOutBtn, SignOutBtnIcon, UserInfoContainer, UserInfoDetail } from './styled';

const AccountDetail = ({auth}) => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const onLogOut = async () => {
        dispatch(setLogout());
        await encryptedStorage.setItem('remember', 'forgot');
        await encryptedStorage.removeItem('token');
        await encryptedStorage.removeItem('refresh');
        navigate('/');
    }

    return (
        <React.Fragment>
            <AccountDetailConatiner>
                <AccountDetailWrapper>
                    <AccountDetailContent>
                        <h2>Account Details</h2>
                        <span>{auth.username}</span>
                    </AccountDetailContent>
                    <SignOutBtn onClick={onLogOut}>
                        <SignOutBtnIcon>
                            <MdOutput />
                        </SignOutBtnIcon>
                        <span>Sign Out</span>
                    </SignOutBtn>
                </AccountDetailWrapper>
                <UserInfoContainer>
                    <UserInfoDetail>
                        <div className='user-info'>
                            <p>Email</p>
                            <p>{auth.email}</p>
                        </div>
                        <div className='lock-icon'><BiLock /></div>
                    </UserInfoDetail>
                    <UserInfoDetail>
                        <div className='user-info'>
                            <p>Mobile Number</p>
                            <p>{auth.phone}</p>
                        </div>
                        <div className='lock-icon'><BiLock /></div>
                    </UserInfoDetail>
                </UserInfoContainer>
            </AccountDetailConatiner>
        </React.Fragment>
    )
}

export default AccountDetail;