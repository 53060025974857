import React, { useEffect, useState, useLayoutEffect, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import Tabs from "../../components/Tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageList, ImageListItem } from "@mui/material";
import EURImg from "../../assets/image/EN-zar.png";
import PlayTechImg from "../../assets/image/statics/providers/playtech-d.png";
import PlayTechActiveImg from "../../assets/image/statics/providers/playtech.png";
import EgtImg from "../../assets/image/statics/providers/egt_new-d.png";
import EgtActiveImg from "../../assets/image/statics/providers/egt_new.png";
import AnisImg from "../../assets/image/statics/providers/ainsworth-d.png";
import AnisActiveImg from "../../assets/image/statics/providers/ainsworth.png";
import ArisImg from "../../assets/image/statics/providers/aristocrat-d.png";
import ArisActiveImg from "../../assets/image/statics/providers/aristocrat.png";
import NetentImg from "../../assets/image/statics/providers/netent-d.png";
import NetentActiveImg from "../../assets/image/statics/providers/netent.png";
import YggImg from "../../assets/image/statics/providers/Yggdrasil-d.png";
import YggActiveImg from "../../assets/image/statics/providers/Yggdrasil.png";
import AmaticImg from "../../assets/image/statics/providers/amatic-d.png";
import AmaticActiveImg from "../../assets/image/statics/providers/amatic.png";
import NovomaticImg from "../../assets/image/statics/providers/Novomatic-d.png";
import NovomaticActiveImg from "../../assets/image/statics/providers/Novomatic.png";
import NewActiveImg from "../../assets/image/statics/providers/new.png";
import NewImg from "../../assets/image/statics/providers/new.png";
import ZeplinActiveImg from "../../assets/image/statics/providers/zeplin.png";
import ZeplinImg from "../../assets/image/statics/providers/zeplin-d.png";
import PragmaticActiveImg from "../../assets/image/statics/providers/pragmatic_play.png";
import PragmaticImg from "../../assets/image/statics/providers/pragmatic_play-d.png";
import RiverActiveImg from "../../assets/image/statics/providers/river.png";
import RiverImg from "../../assets/image/statics/providers/river-d.png";

import SliderBg1 from "../../assets/image/slider/1.jpg";
import SliderBg2 from "../../assets/image/slider/2.jpg";
import SliderBg3 from "../../assets/image/slider/3.jpg";
import SliderBg4 from "../../assets/image/slider/4.jpg";
import SliderBg5 from "../../assets/image/slider/5.jpg";
import { BiSearchAlt2 } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import {
	EURWrapper,
	EURItem,
	RegisterBtn,
	GamesContainer,
	GamesWrapper,
	GameHeaderWrapper,
	HeaderItem,
	HeaderItemImg,
	ResSearch,
	SliderImg,
	SlideContainer,
	ResCustomSearch,
	ResCarouselContainer,
	GameListWrapper,
	GameListWrapperItem,
	GameListWrapperItemHover,
	ResContainer,
} from "./styled";

import "react-slideshow-image/dist/styles.css";

import { FreeMode } from "swiper";
import { loginUser } from "../../api/auth";
import { getGames, launchGame } from "../../api/game";
import { useTypedDispatch, useTypedSelector } from "../../store/typedHooks";
import { setActiveProvider } from "../../store/slices/game";
import { routes } from "../../constants/routes";
import IntroModal from "../../components/IntroModal";

const headerIconList = [
	NewImg,
	ZeplinImg,
	EgtImg,
	AmaticImg,
	NovomaticImg,
	AnisImg,
	YggImg,
	NetentImg,
	ArisImg,
	PragmaticImg,
	RiverImg
];
const headerIconActiveList = [
	NewActiveImg,
	ZeplinActiveImg,
	EgtActiveImg,
	AmaticActiveImg,
	NovomaticActiveImg,
	AnisActiveImg,
	YggActiveImg,
	NetentActiveImg,
	ArisActiveImg,
	PragmaticActiveImg,
	RiverActiveImg
];

const providers_Arr = [
	"new",
	"zeppelin",
	"egt",
	"amatic",
	"novomatic",
	"ainsworth",
	"yggdrasil",
	"netent",
	"aristocrat",
	"pragmatic",
	"river"
];

const banner_List = [
	{
		bannerUrl: SliderBg1,
	},
	{
		bannerUrl: SliderBg2,
	},
	{
		bannerUrl: SliderBg3,
	},
	{
		bannerUrl: SliderBg4,
	},
	{
		bannerUrl: SliderBg5,
	}
];

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

export const onUpdateGameData = (selected_game_arr) => {
	let new_arr_list = [];
	selected_game_arr.map((item, index) => {
		const game_dict = {
			name: item["displayName"],
			url: item["iconUrl"],
			demo_url: item["demoUrl"] ? item["demoUrl"] : "",
			gameName: item["gameName"],
			active: item["active"],
			displayName: item["displayName"],
			rows: index % 18 === 3 ? 2 : 1,
			cols:
				index % 18 === 2 ||
					index % 18 === 3 ||
					index % 18 === 4 ||
					index % 18 === 11
					? 2
					: 1,
		};
		new_arr_list.push(game_dict);
	});
	return new_arr_list;
};

const Landing = ({ setOpen }) => {
	const navigate = useNavigate();

	const dispatch = useTypedDispatch();
	const divRef = createRef();

	const [categoryCols, setCategoryCols] = useState(6);
	const [imgheight, setImgHeight] = useState(160);
	const [isOpenSearch, setOpenSearch] = useState(false);
	const [gamesearchvalue, setGameSearchValue] = useState("");

	const { isLogin, access_token } = useTypedSelector((state) => state.auth);
	const { gamesList, activeProvider } =
		useTypedSelector((state) => state.game);
	const [showGameList, setShowGameList] = useState([]);
	const [showSelectedGames, setShowSelectedGames] = useState([]);
	const [iconProviders, setIconProviders] = useState([]);
	const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);

	const [width] = useWindowSize();

	const getData = () => {
		dispatch(getGames());
	};

	useEffect(() => {
		setGameSearchValue("");
	}, []);

	useEffect(() => {
		getData();
	}, [access_token]);

	useEffect(() => {
		if (gamesList.length > 0) {
			const newGames_arr = gamesList.filter((item) => item.release !== "OLD");
			const newGames = newGames_arr
			// newGames_arr.length > 20 ? newGames_arr.slice(0, 20) : newGames_arr;
			const sortGames_arr = newGames
			// .slice()
			// .sort((a, b) => parseInt(b["sortOrder"]) - parseInt(a["sortOrder"]));
			let sortGames = [];
			const newgame_dict = {
				key: "new",
				list: sortGames_arr,
			};
			if (newGames.length > 0) {
				sortGames.push(newgame_dict);
			}

			let icon_providers = [];
			providers_Arr.map((category_name, index) => {
				let filter_arr = [];
				if (category_name.toUpperCase() === "EGT") {
					filter_arr = gamesList.filter(
						(item) =>
							item["provider"].toUpperCase() === category_name.toUpperCase() ||
							item["provider"].toUpperCase() === "QUICKSPIN"
					);
				} else if (category_name.toUpperCase() === "ZEPPELIN") {
					filter_arr = gamesList.filter((item) =>
						item["provider"].toUpperCase().includes("ZEPPE")
					);
				} else if (category_name.toUpperCase() === "PRAGMATIC") {
					filter_arr = gamesList.filter((item) =>
						item["provider"].toUpperCase().includes("PRAGMATICPLAY")
					);
				} else {
					filter_arr = gamesList.filter(
						(item) =>
							item["provider"].toUpperCase() === category_name.toUpperCase()
					);
				}
				const filter_max_arr = filter_arr;
				const sortGames_filter_arr = filter_max_arr
					.slice()
					.sort((a, b) => parseInt(b["sortOrder"]) - parseInt(a["sortOrder"]));
				const other_provider = {
					url: headerIconList[index % headerIconList.length],
					name: category_name.toUpperCase(),
					activeurl: headerIconActiveList[index % headerIconActiveList.length],
				};
				icon_providers.push(other_provider);
				const filter_dict = {
					key: category_name.toUpperCase(),
					list: sortGames_filter_arr,
				};
				if (filter_arr.length > 0) {
					sortGames.push(filter_dict);
				}
			});
			setIconProviders(icon_providers);
			setShowGameList(sortGames);
		}
	}, [gamesList]);

	useEffect(() => {
		if (width > 1000) {
			setCategoryCols(6);
			setImgHeight(160);
		} else if (width <= 1000 && width > 821) {
			const img_width = (width - 100) / 6;
			setImgHeight(img_width);
			setCategoryCols(6);
		} else if (width < 821 && width > 600) {
			const img_width = (width - 100) / 4;
			setImgHeight(img_width);
			setCategoryCols(4);
		} else {
			const img_width = (width - 40) / 3;
			setCategoryCols(3);
			setImgHeight(img_width);
		}
	}, [width]);

	const selectedProviderGameList = (selected_provider) => {
		window.scrollTo(0, 0);
		dispatch(setActiveProvider(selected_provider.toUpperCase()));
	};

	useEffect(() => {
		const list = showGameList.filter(
			(item) => item.key.toUpperCase() === activeProvider.toUpperCase()
		);
		if (activeProvider.toUpperCase() === "ALL") {
			const new_arr_list = onUpdateGameData(gamesList);
			setShowSelectedGames(new_arr_list);
			setSelectedProviderIndex(-1);
			window.history.replaceState(null, null, "?cat=ALL");
		} else {
			if (list.length > 0) {
				const provider_index = providers_Arr.findIndex(
					(obj) => obj.toUpperCase() === activeProvider.toUpperCase()
				);
				setSelectedProviderIndex(provider_index);
				const selected_game_arr = list[0].list;
				const new_arr_list = onUpdateGameData(selected_game_arr);
				setShowSelectedGames(new_arr_list);
			} else {
				setShowSelectedGames([]);
			}
			window.history.replaceState(
				null,
				null,
				"?cat=" + activeProvider.toUpperCase()
			);
		}
	}, [activeProvider, showGameList]);

	const tappedPlay = async (gameItem) => {
		if (isLogin) {
			const newGame = await dispatch(launchGame(gameItem["gameName"])).unwrap();
			if (newGame) {
				navigate(`/gameId/${gameItem["gameName"]}`, {
					state: {
						displayName: gameItem["displayName"],
						gameURL: newGame["url"],
					},
				});
			}
		} else navigate(routes.LOGIN);
	};

	const tappedDemo = async (gameItem) => {
		if (isLogin) {
			navigate(`/gameId/${gameItem["gameName"]}`, {
				state: {
					displayName: gameItem["displayName"],
					gameURL: `${gameItem["demo_url"]}&closeUrl=${process.env.REACT_APP_REDIRECT_DEV_URL}`,
				},
			});
		} else navigate(routes.LOGIN);
	};

	const onFilterSearchGame = (searchtxt) => {
		setGameSearchValue(searchtxt);
		if (searchtxt.length > 0) {
			const list = gamesList.filter((item) =>
				item["displayName"].toUpperCase().includes(searchtxt.toUpperCase())
			);
			const selected_game_arr = list;
			const new_arr_list = onUpdateGameData(selected_game_arr);
			setShowSelectedGames(new_arr_list);
		} else {
			if (selectedProviderIndex === -1) {
				const new_arr_list = onUpdateGameData(gamesList);
				setShowSelectedGames(new_arr_list);
			} else {
				const selected_provider = iconProviders[selectedProviderIndex];
				selectedProviderGameList(selected_provider["name"]);
			}

		}
	};

	useEffect(() => {
		if (divRef) {
			const stickyContainer = document.getElementById(
				"footer-sticky-container"
			);
			const footer = document.getElementById("footer");
			if (footer.getBoundingClientRect().top <= window.innerHeight) {
				stickyContainer.style.position = "absolute";
				stickyContainer.style.top = 0;
			} else {
				stickyContainer.style.position = "fixed";
				stickyContainer.style.top = "auto";
			}
		}
	}, [divRef]);

	return (
		<React.Fragment>
			<Tabs tag={1} />
			<SlideContainer>
				<Slide transitionDuration={500} arrows={false}>
					{banner_List.map((slideImage, index) => (
						<SliderImg
							className="slider-bg"
							key={index}
							url={slideImage.bannerUrl}
						>
							<EURWrapper>
								<EURItem>
									<img src={EURImg} alt="" draggable="false" />
									{
										!isLogin && <RegisterBtn onClick={() => navigate(routes.REGISTER)}>
											REGISTER
										</RegisterBtn>
									}
									<span onClick={() => navigate(routes.TERMS)}>
										Terms and Conditions
									</span>
								</EURItem>
							</EURWrapper>
						</SliderImg>
					))}
				</Slide>
			</SlideContainer>
			<GamesContainer>
				<GamesWrapper>
					<GameHeaderWrapper>
						<ResSearch
							isSearch={isOpenSearch}
							onClick={() => setOpenSearch(!isOpenSearch)}
						>
							<BiSearchAlt2 className="res-search-icon" />
							<span>SEARCH</span>
						</ResSearch>
						{isOpenSearch && (
							<>
								<ResCustomSearch
									type="text"
									onChange={(e) => onFilterSearchGame(e.target.value)}
									value={gamesearchvalue}
								/>
								<CgClose
									className="res-search-icon pos-close"
									onClick={() => setOpenSearch(false)}
								/>
							</>
						)}
						<ResContainer isSearch={isOpenSearch}>
							<Swiper
								slidesPerView={6}
								spaceBetween={10}
								freeMode={true}
								modules={[FreeMode]}
								breakpoints={{
									700: {
										slidesPerView: 9,
										spaceBetween: 10,
									},
									900: {
										slidesPerView: 11,
										spaceBetween: 10,
									}
								}}
								className="res-header-item-swiper"
							>
								{iconProviders.map((item, index) => (
									<SwiperSlide key={index}>
										<HeaderItem
											onClick={() => selectedProviderGameList(item.name)}
											key={item.name + index}
											active={selectedProviderIndex === index}
										>
											<HeaderItemImg
												imgurl={
													selectedProviderIndex === index
														? item.activeurl
														: item.url
												}
												alt=""
											/>
											<span>{item.name}</span>
										</HeaderItem>
									</SwiperSlide>
								))}
							</Swiper>
						</ResContainer>
						<ResCarouselContainer isSearch={isOpenSearch}>
							<Swiper
								slidesPerView={6}
								spaceBetween={10}
								freeMode={true}
								modules={[FreeMode]}
								breakpoints={{
									300: {
										slidesPerView: 3,
										spaceBetween: 10,
									},
									380: {
										slidesPerView: 4,
										spaceBetween: 10,
									},
									480: {
										slidesPerView: 6,
										spaceBetween: 10,
									},
								}}
								className="res-header-item-swiper"
							>
								{iconProviders.map((item, index) => (
									<SwiperSlide key={index}>
										<HeaderItem
											onClick={() => selectedProviderGameList(item.name)}
											active={selectedProviderIndex === index}
										>
											<HeaderItemImg
												imgurl={
													selectedProviderIndex === index
														? item.activeurl
														: item.url
												}
												alt=""
											/>
											<span>{item.name}</span>
										</HeaderItem>
									</SwiperSlide>
								))}
							</Swiper>
						</ResCarouselContainer>
					</GameHeaderWrapper>
					<GameListWrapper ref={divRef}>
						<ImageList
							className="my-img-list"
							variant="quilted"
							cols={categoryCols}
							rowHeight={imgheight}
							gap={categoryCols > 4 ? 4 : 1}
						>
							{showSelectedGames.map((item, index) => (
								<ImageListItem
									key={index}
									cols={categoryCols === 6 ? item.cols || 1 : 1}
									rows={categoryCols === 6 ? item.rows || 1 : 1}
									style={{
										padding: "5px",
										borderRadius: "10px",
										background: "#fff",
									}}
								>
									<GameListWrapperItem imgurl={item.url}>
										<GameListWrapperItemHover>
											<span id="firstspan" onClick={() => tappedPlay(item)}>
												Play Now
											</span>
											{item.demo_url !== "" && (
												<span id="lastspan" onClick={() => tappedDemo(item)}>
													Demo
												</span>
											)}
										</GameListWrapperItemHover>
									</GameListWrapperItem>
								</ImageListItem>
							))}
						</ImageList>
					</GameListWrapper>
				</GamesWrapper>
			</GamesContainer>
			<IntroModal />
		</React.Fragment>
	);
};

export default Landing;
