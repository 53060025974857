import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabsContainer, TabsWrapper, AccountContainer, CloeseBtn } from './styled';
import { AiOutlineClose } from 'react-icons/ai';
import { BiWallet } from 'react-icons/bi';
import { MdOutlineMonetizationOn } from 'react-icons/md';
import { HiOutlineDatabase } from 'react-icons/hi';
import { GrUserSettings } from 'react-icons/gr';
import Balance from './balance';
import Deposit from './deposit';
import Withdraw from './withdraw';
import AccountDetail from './accountDetail';
import { useTypedSelector, useTypedDispatch } from '../../store/typedHooks';
import { routes } from '../../constants/routes';
import { setTransaction } from '../../store/slices/account';
import CTabs from "../../components/Tabs";

const Account = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const [value, setValue] = useState(0);
    const { isLogin } = useTypedSelector((state) => state.auth);
    const { balance } = useTypedSelector((state) => state.account.account);
    const { key, payment_amount } = useTypedSelector((state) => state.account);
    const auth = useTypedSelector((state) => state.auth);
    const [isPaymentStatus, setPaymentStatus] = useState(false);
    const [isPaid, setPaid] = useState(false);

    useEffect(() => {
        if (isLogin) {
            dispatch(setTransaction({ url: '' }))
            if (location.search.includes('success=true')) {
                setPaymentStatus(true);
                setValue(1);
                navigate(routes.ACCOUNT, { state: 1 });
            } else {
                if (location.state) {
                    setValue(location.state);
                }
            }
        } else {
            navigate(routes.LOGIN)
        }
    }, [])

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        navigate(routes.ACCOUNT, { state: newValue });
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <CTabs tag={4} />
            <AccountContainer>
                <TabsContainer>
                    <TabsWrapper>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" allowScrollButtonsMobile variant="scrollable"
                            scrollButtons>
                            <Tab icon={<BiWallet size={20} />} label="Balance" {...a11yProps(0)} />
                            <Tab icon={<MdOutlineMonetizationOn size={20} />} label="Deposit" {...a11yProps(1)} />
                            <Tab icon={<HiOutlineDatabase size={20} />} label="Withdraw" {...a11yProps(2)} />
                            <Tab icon={<GrUserSettings size={20} />} label="Account Detail" {...a11yProps(3)} />
                        </Tabs>
                    </TabsWrapper>
                    <CloeseBtn onClick={() => navigate(routes.HOME)}>
                        {isPaid === false && <AiOutlineClose color='#000' size={35} />}
                    </CloeseBtn>
                </TabsContainer>
                <div>
                    {value === 0 && <Balance balance={balance} setValue={setValue} />}
                    {value === 1 && <Deposit payment_amount={payment_amount} isPaymentStatus={isPaymentStatus} setPaymentStatus={setPaymentStatus} transcation_id={key} isPaid={isPaid} setPaid={setPaid} />}
                    {value === 2 && <Withdraw balance={balance} />}
                    {value === 3 && <AccountDetail auth={auth} />}
                </div>
            </AccountContainer>

        </React.Fragment>
    )
}

export default Account;