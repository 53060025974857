import React from "react";
import { useNavigate } from 'react-router-dom'
import { HiOutlineDotsVertical } from "react-icons/hi";
import {
    TabsContainer,
    TabsWrapper,
    TabsItems,
    TabsItem,
} from './styled'
import { routes } from "../../constants/routes";

const Tabs = ({ tag }) => {
    const navigate = useNavigate();

    return (
        <TabsContainer>
            <TabsWrapper>
                <TabsItems>
                    <TabsItem active={tag === 1 && true} onClick={() => navigate(routes.HOME)}><span>Home</span> <HiOutlineDotsVertical /></TabsItem>
                    <TabsItem active={tag === 2 && true} onClick={() => navigate(routes.PROMOTIONS)}><span>Promotions</span> <HiOutlineDotsVertical /></TabsItem>
                    <TabsItem active={tag === 3 && true} onClick={() => navigate(routes.LEADERBOARD)}><span>Leaderboard</span> <HiOutlineDotsVertical /></TabsItem>
                    {/* <TabsItem active={tag === 4 && true} onClick={() => navigate(routes.PROMOTIONS)}><span>Download</span> <HiOutlineDotsVertical /></TabsItem> */}
                    <TabsItem active={tag === 4 && true} onClick={() => navigate(routes.ACCOUNT, { state: 1 })}><span>Payments Methods</span> <HiOutlineDotsVertical /></TabsItem>
                    <TabsItem active={tag === 5 && true} onClick={() => navigate(routes.HELP)}><span>Help</span> <HiOutlineDotsVertical /></TabsItem>
                </TabsItems>
                {/* <VIPImg src={VIPLogo} alt='' draggable={false} /> */}
            </TabsWrapper>
        </TabsContainer>
    )
}

export default Tabs