import React, { useEffect } from "react";
import Tabs from "../../components/Tabs";

// styled
import {
	AboutUsContainer,
	AboutUsWrapper,
	AboutUsTitle,
	AboutUsContent,
	AboutUsTermsContainer,
	AboutUsTermsTitle,
} from "./styled";

const AboutUs = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Tabs tag={0} />
			<AboutUsContainer>
				<AboutUsWrapper>
					<AboutUsTitle>About Us</AboutUsTitle>
					<AboutUsContent>
						<span>
							Viva Slots is the leading destination for all online casino
							players who are looking for a touch of class and elegance in their
							gaming environment. If you could travel to Europe to play in a top
							casino, the experience would look much like this.
						</span>
						<span>
							Our casino provides you with over 400 online casino games – some
							traditional with modern twists, and some completely new,
							innovative, and exotic.
						</span>
						<span>
							With irresistible weekly bonus promotions and attractive loyalty
							and VIP programs, we're sure you'll be sticking around for a while
							to come.
						</span>
					</AboutUsContent>
					<AboutUsTermsContainer>
						<AboutUsTermsTitle>Software</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								All of our software is developed and maintained by the
								industry's foremost online gaming software company, Playtech.
								Because of this, we are able to offer you the newest
								cutting-edge games, of the highest quality and ultimate security
								in a sophisticated, yet engaging atmosphere.
							</span>
							<span>
								In order to ensure accuracy and fairness at Viva Slots, all of
								our games have been verified and approved by GLI – Gaming
								Laboratories International.
							</span>
						</AboutUsContent>
						<AboutUsTermsTitle>Financial Transactions</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								All financial transactions at Viva Slots are done via our
								subsidiary Universe Entertainment Services Malta Limited
							</span>
						</AboutUsContent>
						<AboutUsTermsTitle>Customer Service</AboutUsTermsTitle>
						<AboutUsContent>
							<span>
								At Viva Slots our players are important to us and always come
								first, which is why we have put a lot of effort into providing
								you with the most professional and efficient Customer Support
								team around. Our Support team is available to you from 06:00 am
								– Midnight GMT every day of the week via phone, email, live chat
								or fax. We are always happy to help and assist with any
								questions or feedback you may have.
							</span>
							<span>
								You can contact our support team via email: <a href="mailto:support@vivaslots.co.za">support@vivaslots.co.za</a>.
							</span>
						</AboutUsContent>
					</AboutUsTermsContainer>
				</AboutUsWrapper>
			</AboutUsContainer>
		</React.Fragment>
	);
};

export default AboutUs;
